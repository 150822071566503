@import 'styles/scss-variable.scss';

.select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .select-label {
    margin-bottom: 5px;
    font-weight: 600;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .select {
    width: 100%;
  }

  &--border .dropdown-selection__control {
    @include themed() {
      border: 1px solid t('border-color') !important;
    }
  }
}

.loading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .select-label {
    margin-bottom: 5px;
    font-weight: 600;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .loading-align {
    justify-content: flex-start;
    height: auto;
  }
}
