@import 'styles/scss-variable.scss';

.dropdown-component {
  .trigger-container-mainmenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    width: 100%;
    padding: 9px 12px;
    cursor: pointer;
    border-radius: $border-radius;
    @include themed() {
      background-color: t('color-light-gray');
    }

    .trigger {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include themed() {
        color: t('color-font-text');
      }
      font-weight: $headline-semi-bold;
      padding-right: 4px;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 15px;
      }
    }

    .arrow-down {
      width: 12px;
      height: auto;
      object-fit: contain;
      @include themed() {
        filter: t('image-filter');
      }
    }

    &:hover {
      @include themed() {
        background-color: t('color-gray');
      }
    }
  }

  .menu {
    width: 100%;
    border: unset;
    border-radius: $border-radius;
    padding: 0px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 5px;
    @include themed() {
      background-color: t('dropdown-bg');
    }
  }

  .menu-item-container {
    margin: 0px;
    padding: 0px;
    border: 0;
    outline: 0;
    border-bottom: none;
    cursor: pointer;
    @include themed() {
      color: t('color-font-title');
    }

    > div {
      border: 0;
      outline: 0;
      padding: 15px;

      &:hover {
        @include themed() {
          color: t('color-font-title');
          background-color: t('color-light-gray');
        }
      }
    }

    &:last-child > div {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &:first-child > div {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }
}
