@import 'styles/scss-variable.scss';

.orga-page {
  .image-placeholder {
    min-width: 130px;
    min-height: 130px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $headline-semi-bold;
    font-size: 70px;
    margin-bottom: 20px;
    @include themed() {
      background-color: t('color-gray');
      color: t('color-font-text');
    }
  }

  img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin-bottom: 20px;
    @include themed() {
      background: t('color-gray');
      border: 1px solid t('border-color');
    }
  }
}
