@import 'styles/scss-variable.scss';

.verify-domain-table .list-table {
  padding: 0px;

  td {
    vertical-align: top;
  }
}

.domain-table-action-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.verify-domain-form {
  max-width: $max-xml;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.email-prefix {
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .field-container {
    input {
      text-align: right;
    }
  }
}

.email-sender-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
