@import 'styles/scss-variable.scss';

.email-container {
  display: flex;
  justify-content: space-between;

  .settings-group {
    width: calc(30% - 20px);
  }

  .email-composer {
    width: calc(70% - 20px);

    .widget-preview {
      position: initial;

      &-browser {
        border-radius: $input-border-radius;
        @include themed() {
          border: 1px solid t('border-color');
        }

        &-body {
          position: relative;
          background: #f6f7fc !important;
          background-image: none !important;
          border-radius: 0px;
          border-bottom-left-radius: $input-border-radius;
          border-bottom-right-radius: $input-border-radius;

          .ProseMirror {
            padding: 30px;
            max-height: initial !important;
          }

          &-header {
            width: 100%;
            padding: 8px;
            padding-right: 14px;
            background-color: #fff;
            display: flex;
            align-items: center;
            @include themed() {
              border-bottom: 1px solid t('border-color');
              background-color: t('color-bg');
            }

            .field-container {
              width: auto;
              flex-grow: 1;
            }

            input.textinput-gray {
              border: none !important;
            }
          }
        }
      }

      .editor-container {
        width: 100%;
        background-color: #fff;
        padding: 20px;
        border-radius: $input-border-radius;
      }

      .email-editor {
        padding-top: 0px;
      }

      .floating-toolbar {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        padding: 12px;
        border-radius: 12px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

        @include themed() {
          border: 1px solid t('border-color');
          background-color: t('color-bg');
        }
      }
    }
  }
}
