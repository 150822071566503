@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.shared-powered-by {
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 40px;
  text-decoration: none !important;

  .bolt {
    height: 13px;
    width: auto;
  }

  span {
    font-size: 13px;
    font-weight: 400;
    margin-left: 4px;
    margin-right: 4px;
    text-decoration: none !important;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .logo {
    height: 14px;
    margin-top: 2px;
    width: auto;
  }
}
