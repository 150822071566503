@import 'styles/scss-variable.scss';

.extended-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .menu-header {
    position: absolute;
    right: 15px;
    @include themed() {
      color: t('color-font-text');
    }

    img {
      opacity: 0.7;
      width: 16px;
      height: 16px;
      @include themed() {
        filter: t('image-filter');
      }
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  .extended-menu-items {
    margin-top: 80px;
    position: absolute;
    right: 12px;
    z-index: 10;
    border-radius: $border-radius;
    overflow: hidden;
    @include themed() {
      border: 1px solid t('border-color-dark');
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: t('dropdown-bg');
    }

    .extended-menu-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      min-width: calc(#{$max-m} / 2);
      padding: 12px;
      @include themed() {
        color: t('color-font-title');
      }

      &:hover {
        @include themed() {
          background: t('color-light-gray');
        }
      }
    }
  }
}
