@import 'styles/scss-variable.scss';

.project-message-templates {
  .template-editor {
    margin-top: 10px;
    box-sizing: border-box;
    letter-spacing: -0.02em;
    padding: 12px 12px;
    flex: 1;
    border: 0 solid;
    font-size: $small-text-font-size;
    border-radius: $input-border-radius;
    @include themed() {
      border: 1px solid t('border-color');
      color: t('color-font-title');
      background-color: transparent;
    }

    .email-editor {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &:focus,
    &:focus-within {
      @include themed() {
        border: 1px solid t('color-primary-black');
      }
    }
  }

  .form-buttons {
    display: flex;
    justify-content: flex-start;
  
    button, .primary-button {
      margin-right: 10px;
    }
  }
}
