@import '../../../styles/scss-variable.scss';

.widget-sidenav-container {
  .widget-configurator-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;

    .widget-content-page {
      width: 50%;
    }

    .widget-preview-wrapper {
      width: 50%;
      height: 100%;

      .widget-preview {
        height: 100%;
      }
    }

    .widget-avatar-upload {
      .image-upload-component {
        width: 80px !important;
        height: 80px !important;
        border-radius: 100% !important;

        .image {
          padding: 0px;
          border-radius: 100%;

          img {
            object-fit: cover;
            border-radius: 100%;
          }
        }
      }
    }

    .widget-logo-upload {
      .image-upload-component {
        width: 95px !important;
        height: 95px !important;
        border-radius: 100% !important;

        .image {
          padding: 8px;
          border-radius: 100%;
        }
      }
    }

    .widget-general-settings {
      .image-upload-component {
        width: 220px;
        height: 100px;
      }
    }
  }
}

.linespacer {
  @include themed() {
    padding-top: 40px !important;
    margin-top: 0px !important;
  }
}

.linespacerwithborder {
  @include themed() {
    border-top: 1px solid t('border-color');
    padding-top: 30px;
    margin-top: 30px;
  }
}

.no-permissions-container {
  border-radius: $border-radius;
  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include themed() {
    background-color: t('color-light-gray');
  }

  .no-permissions-image {
    height: auto;
    object-fit: contain;
    max-width: 580px;
    width: auto;
    padding-top: 0px;
    padding-bottom: 20px;
  }
}
