@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-request-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 150;
  @include themed() {
    background-color: t('modal-background-color') !important;
  }

  .close-modal {
    position: fixed;
    z-index: 200;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 20px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  &-inner {
    background-color: #fff;
    max-width: 750px;
    width: 100%;
    height: calc(var(--vh,1vh)*100);
    margin-left: auto;
    overflow: auto;
    animation-name: slideInRight;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 30px rgba(0, 0, 0, 0.2);

    @include for-phone-only {
      animation-name: animateInModal;
    }
  }

  .shared-actions {
    margin-bottom: 10px;
    margin-left: 15px;

    @include for-phone-only {
      margin-top: 20px;
      margin-left: 20px;
    }

    .link-button {
      min-height: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 13px;
      text-transform: capitalize;
      padding: 0px;
      background-color: transparent;
      @include themed() {
        color: t('color-font-text');
      }

      .icon {
        margin-right: 6px;
        margin-left: 4px;
      }

      &:hover {
        @include themed() {
          color: t('color-font-title');
          background-color: transparent !important;
        }
      }
    }
  }

  @include for-phone-only {
    flex-direction: column;
  }

  @include for-tablet-only {
    flex-direction: column;
  }

  .feature-request-details-sidebar {
    width: 300px;
    min-width: 300px;
    margin-right: 20px;

    @include for-phone-only {
      margin: 20px;
      width: auto;
      min-width: auto;
    }

    @include for-tablet-only {
      margin: 20px;
      margin-top: 10px;
      width: auto;
    }
  }

  .feature-request-details-sidebar-status {
    padding: 6px 8px;
    border-radius: $border-radius;
    font-size: 14px;
    display: inline-block;
    color: #fff;
  }

  .feature-request-details-sidebar-title {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .feature-request-details-sidebar-heads {
    display: flex;
    padding-left: 5px;

    img {
      margin-left: -5px;
    }

    .additional-users {
      margin-left: 5px;
      display: flex;
      align-items: center;
      font-size: 14px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .feature-request-details-votes {
    padding-left: 15px;
    border-radius: $border-radius;

    @include for-phone-only {
      padding: 0px;
    }
  }
}
