@import 'styles/scss-variable.scss';

.simple-sidenav-element {
  display: flex;
  align-items: center;
  border-radius: $input-border-radius;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 12px;
  min-height: 34.5px;
  margin: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include themed() {
    color: t('color-font-title');
  }

  &-tag {
    left: 0px;
    top: 0px;
    position: absolute;
    height: 18px;
    line-height: 18px;
    margin-left: 6px;
    font-size: 10px;
    padding: 0px 5px;
    border-radius: 5px;
    font-weight: 700;
    @include themed() {
      background-color: t('color-primary');
      color: t('button-color');
    }

    &-outer {
      position: relative;
      height: 100%;
    }
  }

  i {
    font-size: 12px;
    margin-right: 5px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 12px;
    opacity: 1;
    @include themed() {
      fill: t('color-font-title');
    }

    path,
    g {
      @include themed() {
        fill: t('color-font-nav');
      }
    }
  }

  .notifications-badge {
    margin-left: auto;
    font-size: 13px;

    @include themed() {
      color: t('color-primary');
    }
  }
}

.simple-sidenav-element:hover {
  @include themed() {
    color: t('color-font-title');
    background-color: t('color-gray');
  }

  svg {
    opacity: 1;
    @include themed() {
      color: t('color-font-title');
    }

    path,
    g {
      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}

.simple-sidenav-element.active {
  font-weight: 600;
  @include themed() {
    color: t('color-font-title');
    background-color: t('color-bg');
  }

  svg {
    opacity: 1;
    @include themed() {
      color: t('color-font-title');
    }

    path,
    g {
      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}
