@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-request-card-upvotes {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  @include themed() {
    background-color: t('color-bg');
    border: 1px solid t('border-color');
    color: t('color-gray');
  }
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;

  @include for-mobile-tablet-only {
    min-width: 48px;
    min-height: 48px;
    width: 48px;
    height: 48px;
  }

  i {
    font-size: 20px;
  }

  .number {
    font-size: 16px;
    font-weight: 500;
    margin-top: -2px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  &-selected {
    @include themed() {
      border: 1px solid t('color-primary');
    }

    i {
      @include themed() {
        color: t('color-primary');
      }
    }
  }

  &-selected .number {
    @include themed() {
      color: t('color-primary');
    }
  }

  &:hover {
    @include themed() {
      color: t('color-font-text');
      border: 1px solid t('color-font-text');
    }

    i {
      @include themed() {
        color: t('color-font-text');
      }

      animation-name: slideUpABit;
      animation-duration: 0.3s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
    }

    .number {
      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}
