@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.shared-bugs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @include themed() {
    background-color: t('color-light-gray');
  }

  .shared-bugs {
    height: calc(var(--vh, 1vh) * 100);
    max-width: $max-xxxl;
    width: 80vw;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.15);
    @include themed() {
      background-color: t('color-bg');
    }

    @include for-mobile-tablet-only {
      border-radius: 0px;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
    }

    .bug-report-not-existing {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .feedback-header {
      justify-content: flex-start;

      .ticket-headline-input:hover {
        background-color: transparent !important;
      }
    }

    &--userside {
      
    }
  }
}
