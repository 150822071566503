@import 'styles/scss-variable.scss';

.dashboard-button {
  cursor: pointer;

  &--label {
    display: flex;
    padding: 18px 25px;
    @include themed() {
      border-bottom: 1px solid t('border-color');
    }
  }

  &--header {
    min-width: calc(#{$ml-nav} - 25px) !important;
    padding-left: 0px;
    margin-right: 20px;
  }

  .actionbutton {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include themed() {
      background-color: t('color-primary');
    }

    img {
      width: 60%;
      height: 70%;
      object-fit: contain;
    }
  }

  .title {
    font-weight: $headline-semi-bold;
    font-size: $text-font-size;
  }

  &:hover {
    .title {
      @include themed() {
        color: t('color-primary');
      }
    }
  }
}
