@import 'styles/scss-variable.scss';

.bug-prio-item {
  font-size: $small-text-font-size;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .status-icon {
    width: 17px;
    height: 17px;
    border-radius: 100%;
    margin: 2px;
    margin-right: 10px;
    @include themed() {
      filter: t('image-filter-rev');
    }
  }
}