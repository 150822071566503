@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.sidenav-sub-menu {
  &-title {
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-radius: $input-border-radius;
    padding: 10px 12px;
    min-height: 34.5px;
    margin: 0px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include themed() {
      color: t('color-font-title');
    }

    i {
      font-size: 12px;

      @include themed() {
        color: t('color-font-title');
      }
    }

    .faicon {
      font-size: 12px;
      margin-right: 5px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .arrowdown {
      padding-left: 6px;
      margin-left: auto;
    }

    &--active,
    &:hover {
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-gray');
      }
    }

    &--opened {
      font-weight: 600;
    }

    .notifications-badge {
      margin-left: auto;
      font-size: 14px;

      @include themed() {
        color: t('color-primary');
      }
    }
  }

  &-body {
    margin-bottom: 12px;
    margin-left: 20px;
    padding: 0px;
    border-radius: $input-border-radius;

    .simple-sidenav-element:last-child {
      margin-bottom: 0px;
    }
  }

  .simple-sidenav-element {
    font-size: 13.5px;
    padding: 6px 10px;
    padding-right: 0px;
    font-weight: 500 !important;
  }
}
