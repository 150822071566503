@import 'styles/scss-variable.scss';

.feedback-widget-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.feedback-widget-preview-chatbubble {
  max-width: 375px;
  width: 100%;
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 21px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  &-avatar {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    margin-bottom: 10px;
    background-color: var(--secondary-text-color);
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 100%;
    }

    svg {
      width: 20px;
      height: 20px;
      object-fit: contain;
      border-radius: 100%;
    }
  }

  &-message {
    margin-left: 12px;
    max-width: calc(85% - 32px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .message {
      background-color: var(--background-color-darker);
      box-sizing: border-box;
      border-radius: var(--border-radius-40);
      padding: 17px 20px;
      color: var(--primary-text-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      max-width: 100%;

      color: var(--primary-text-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
    }

    .message-date {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: var(--secondary-text-color);
    }
  }
}

.feedback-widget-preview {
  max-width: 375px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
  background-color: var(--background-color);

  &-title {
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    line-height: 22px;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 25px;
    color: #fff;
    @include themed() {
      background-color: t('color-primary');
    }
  }

  &-button {
    display: none;
    border-radius: 21px;
    box-sizing: border-box;
    padding: 12px 26px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    background-color: #dee1ea !important;
    color: #9b9fae !important;
    opacity: 1 !important;
    cursor: not-allowed !important;
    margin-top: 20px;
  }

  &-content {
    padding: 30px;

    .form-item {
      &-description {
        font-size: 15px;
        line-height: 21px;
        color: var(--secondary-text-color);
        font-weight: normal;
        margin-top: -5px;
        margin-bottom: 20px;
        display: block;
      }

      &-label {
        line-height: 23px;
        text-align: left;
        font-weight: 500;
        margin-top: 0px;
        color: var(--primary-text-color);
        font-size: 17px;
        margin-bottom: 15px;
        display: block;

        &--required {
          color: #ed4337;
          margin-left: 2px;
        }
      }
    }

    .textarea-form-item {
      display: flex;

      &-textarea {
        width: 100%;
        font-size: 14px;
        appearance: none;
        padding: 11px 12px;
        line-height: 19px;
        outline: 0;
        border: 1px solid var(--border-color-33);
        background-color: var(--background-color);
        box-sizing: border-box;
        color: var(--primary-text-color);
        font-weight: 400;
        border-radius: var(--border-radius-40);

        &:focus {
          outline: none !important;
          border: 1px solid var(--primary-color-AA) !important;
          box-shadow: 0 0 0px 3px var(--primary-color-20);
        }

        &::placeholder {
          color: var(--primary-text-color);
          opacity: 0.4;
        }

        &--invalid,
        &--invalid:focus {
          border: 1px solid #ed4337;
        }

        @media screen and (max-device-width: 600px) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    .feedback-form-item-label {
      font-size: 17px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 15px;
      margin-top: 4px;
      font-weight: 600;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .form-item-label-required {
      font-size: 16px;
      line-height: 19px;
      text-align: left;
      margin-bottom: 12px;
      margin-top: 0;
      font-weight: 600;
      color: #ed4337;
      margin-left: 3px;
    }

    .text-form-item {
      display: flex;
      border-radius: var(--border-radius-40);

      &:focus-within {
        outline: none !important;
        box-shadow: 0 0 0px 3px var(--primary-color-20);
      }

      &-input {
        width: 100%;
        font-size: 14px;
        appearance: none;
        padding: 11px 12px;
        outline: 0;
        line-height: 24px;
        border: 1px solid var(--border-color);
        background-color: var(--background-color);
        box-sizing: border-box;
        color: var(--primary-text-color);
        font-weight: 400;
        border-radius: var(--border-radius-40);

        &:focus {
          outline: none !important;
          border: 1px solid var(--primary-color-AA) !important;
        }

        &::placeholder {
          color: var(--primary-text-color);
          opacity: 0.4;
        }

        &--invalid,
        &--invalid:focus {
          border: 1px solid #ed4337;
        }

        @media screen and (max-device-width: 600px) {
          font-size: 16px;
        }
      }

      &--button {
        .text-form-item-input {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          border-right: none !important;
        }

        button {
          background-color: var(--primary-color-22);
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          border-radius: 0px var(--border-radius-40) var(--border-radius-40) 0px;
          box-sizing: border-box;
          padding: 10px 20px;
          text-align: center;
          width: auto;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 40px;

          svg {
            width: 10px;
            height: auto;
            fill: var(--primary-color);
          }

          &:hover {
            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
            color: var(--primary-contrast-color);

            svg {
              fill: var(--primary-contrast-color);
            }
          }

          &.button--disabled {
            background-color: var(--background-color-darker) !important;
            color: var(--background-color-dark) !important;
            opacity: 1 !important;
            border: 1px solid var(--background-color-dark);
            cursor: not-allowed !important;

            svg {
              fill: var(--background-color-dark);
            }
          }
        }
      }
    }

    .no-options-added {
      padding: 12px 18px;
      border-radius: var(--border-radius-15);
      background-color: var(--background-color);
      color: var(--primary-color);
      border: 1px solid var(--primary-color-55);
      flex-shrink: 0;
    }

    .multiple-choice-form-item {
      display: flex;
      flex-direction: column;
      margin-top: 0px;
      padding-left: 2px;

      &-choice {
        min-height: 44px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        border: 1px solid var(--background-color-dark);
        color: var(--secondary-text-color);
        border-radius: var(--border-radius-25);
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        &-shortcut {
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          border-radius: var(--border-radius-15);
          margin-right: 10px;
          margin-left: 8px;
          background-color: var(--background-color);
          color: var(--primary-color);
          border: 1px solid var(--primary-color-55);
          flex-shrink: 0;
        }

        &-check {
          display: none;
          flex-shrink: 0;
          margin-right: 10px;
          justify-content: center;
          align-items: center;

          svg {
            width: 16px;
            height: auto;
            fill: var(--primary-color);
          }
        }

        &-value {
          font-size: 16px;
          text-align: left;
          font-weight: 400;
          color: var(--primary-text-color);
          flex-grow: 1;
        }

        &--selected,
        &:hover {
          background-color: var(--primary-color-11);
          border: 1px solid var(--primary-color);

          .multiple-choice-form-item-choice-shortcut {
            background-color: var(--primary-color);
            color: var(--primary-contrast-color);
            border: 1px solid var(--primary-color);
          }

          .multiple-choice-form-item-choice-value {
            color: var(--primary-color);
          }
        }

        &--selected {
          .multiple-choice-form-item-choice-check {
            display: flex;
          }
        }
      }
    }

    .rating-form-item {
      &-items {
        display: flex;
        justify-content: center;
      }

      &-option {
        cursor: pointer;
        padding: 0;
        border: 1px solid var(--primary-color-55);
        background-color: var(--primary-color-07);
        color: var(--primary-color);
        border-radius: var(--border-radius-25);
        text-align: center;
        margin: 3px;
        width: 54px;
        min-width: 54px;
        max-width: 54px;
        line-height: 52px;
        min-height: 54px;
        max-height: 54px;
        font-size: 28px;
        font-weight: 500;

        &:hover,
        &--active {
          background-color: var(--primary-color-22);
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
        }
      }
    }

    .file-upload-preview {
      width: 100%;
      text-align: center;
      padding: 30px 20px;
      cursor: no-drop;
      border: 1px dashed var(--primary-color-44);
      background-color: var(--primary-color-07);
      color: var(--primary-color);
      border-radius: var(--border-radius-40);

      p {
        text-align: center;
      }
    }

    .nps-form-item {
      &-items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 0px;
        margin-bottom: 10px;

        @media screen and (max-device-width: 600px) {
          justify-content: center;
        }
      }

      &-item {
        cursor: pointer;
        padding: 0;
        border: 1px solid var(--primary-color-44);
        background-color: var(--primary-color-07);
        color: var(--primary-color);
        border-radius: var(--border-radius-25);
        text-align: center;
        margin: 3px;
        width: 46px;
        min-width: 46px;
        max-width: 46px;
        line-height: 44px;
        min-height: 46px;
        max-height: 46px;
        font-size: 17px;
        font-weight: 500;

        @media screen and (max-device-width: 600px) {
          margin: 4px;
          width: calc(16.6% - 8px);
          min-width: calc(16.6% - 8px);
          max-width: calc(16.6% - 8px);
          line-height: 42px;
          min-height: 46px;
          max-height: 46px;
        }

        &:hover,
        &--active {
          background-color: var(--primary-color-11);
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
        }
      }

      &-emoji {
        .nps-form-item-item {
          margin: 4px;
          width: calc(20% - 8px);
          min-width: calc(20% - 8px);
          max-width: calc(20% - 8px);
          line-height: 50px;
          min-height: 52px;
          max-height: 52px;

          span {
            font-size: 20px;
          }
        }
      }

      &-labels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
          color: var(--secondary-text-color);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }

    .one-to-five-form-item {
      &-items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }

      &-item {
        cursor: pointer;
        padding: 0;
        border: 1px solid var(--primary-color-55);
        background-color: var(--primary-color-07);
        color: var(--primary-color);
        border-radius: var(--border-radius-25);
        text-align: center;
        margin: 3px;
        width: 46px;
        min-width: 46px;
        max-width: 46px;
        line-height: 44px;
        min-height: 46px;
        max-height: 46px;
        font-size: 17px;
        font-weight: 500;

        &:hover,
        &--active {
          background-color: var(--primary-color-22);
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
        }
      }

      &-labels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
          color: var(--secondary-text-color);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}
