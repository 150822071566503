.message-container {
  display: flex;
  justify-content: space-between;

  .options-group {
    width: calc(60% - 20px);
  }

  .message-preview {
    width: calc(40% - 20px);
  }
}
