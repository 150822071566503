@import 'styles/scss-variable.scss';

.feature-request-card {
  display: block;
  cursor: pointer;
  outline: none;
  padding: 12px;
  margin-bottom: 0px;
  border: 1px solid transparent;
  position: relative;
  padding-right: 20px;
  border-radius: 8px;

  &:hover {
    @include themed() {
      background-color: t('color-ultra-light-gray') !important;
    }
  }

  &-dot {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;

    &-inner {
      width: 3px;
      min-width: 3px;
      border-radius: 2px;
      height: 100%;
      @include themed() {
        background-color: t('color-primary');
      }
    }
  }

  &-inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  &-body {
    padding-left: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-title {
      margin-top: 4px;
      font-size: 16px;
      word-wrap: break-word;
      @include themed() {
        color: t('color-font-title');
      }
    }

    &-description {
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include themed() {
        color: t('color-font-text');
      }
    }

    &-date {
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 450;
      opacity: 0.5;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include themed() {
        color: t('color-font-text');
      }
    }

    &-status {
      font-size: 12px;
      font-weight: 400;
      width: auto;
      padding: 4px 8px;
      margin-right: 10px;
      border-radius: 6px;
      border-width: 1px;
      border-style: solid;
    }

    &-tag {
      font-size: 12px;
      font-weight: 500;
      margin-right: 10px;
      width: auto;
      @include themed() {
        color: t('color-primary');
      }
    }

    &-statustags {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
}
