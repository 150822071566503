@import 'styles/scss-variable.scss';

.info-box {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 12px;
  border-radius: $input-border-radius;
  border: 0px solid #ffd200;
  background-color: #ffd20044;

  &-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2px;
    line-height: 21px;

    i {
      font-size: 20px;
      margin-right: 8px;
      line-height: 21px;
      color: #433200;
    }

    span {
      font-size: 15px;
      font-weight: bold;
      color: #433200;
    }
  }

  &-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #433200;

    a {
      font-weight: 600;
      color: #433200;
      font-size: 14px;
    }

    b {
      font-weight: 600;
      color: #433200;
      font-size: 14px;
    }

    .primary-button,
    .secondary-button,
    .link-button {
      font-size: $medium-text-font-size;
      height: 34px !important;
      min-height: 34px !important;
      line-height: 34px !important;
      padding: 0px 10px !important;
      box-shadow: none !important;
    }
  }

  &--simple {
    background-color: transparent !important;
    padding: 0px !important;

    .info-box-icon {
      margin-right: 2px;

      i {
        font-size: 16px;

        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .info-box-content {
      font-size: 14px;

      @include themed() {
        color: t('color-font-text');
      }

      a {
        font-size: 14px;
      }
    }
  }
}
