@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.sidenav-container {
  display: flex;
  width: 100%;
  position: relative;

  @include themed() {
    background-color: t('color-light-gray');
  }

  &--small {
    .sub-sidenav {
      max-width: $mlsm-nav;
      min-width: $mlsm-nav;
    }
  }

  .page-container {
    width: calc(100vw - $mlsm-nav - $mnl-nav);

    @include for-mobile-tablet-only {
      width: 100vw;
    }
  }

  &--sidenav-hidden {
    .sub-sidenav {
      display: none;
    }

    .page-container {
      width: calc(100vw - $mnl-nav);
      border-radius: 0px !important;
      margin-left: 0px !important;
    }
  }
}
