@import 'styles/scss-variable.scss';

.bot-action-add-options {
  position: absolute;
  top: 100%;
  left: 0px;
  min-width: 100%;
  z-index: 99;
  border-radius: $border-radius;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  padding: 8px;
  @include themed() {
    background-color: t('color-card-bg');
    border: 1px solid t('border-color');
  }

  &-item {
    cursor: pointer;
    display: flex;
    margin-bottom: 2px;
    border-radius: $input-border-radius;
    padding: 6px;

    &:hover {
      @include themed() {
        background-color: t('color-light-gray');
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }

    &-icon {
      width: 30px;
      height: 30px;
      margin: 0px;
      margin-right: 10px;
      border-radius: 8px;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 14px;
      }
    }

    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}
