@import 'styles/scss-variable.scss';

.type-tag {
  padding: 0px;

  i {
    font-size: 14px;
    @include themed() {
      color: t('color-primary');
    }
  }

  &--guest {
    i {
      font-size: 18px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}

.contact-details-container {
  align-items: flex-start;

  .contact-details-header-avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 15px;

    .user-avatar {
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      border-radius: 64px;
      font-size: 24px;

      .user-avatar-user-online {
        position: absolute;
        bottom: 2px;
        left: 48px;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        @include themed() {
          border: 1px solid t('color-bg');
          background-color: t('color-green');
        }
      }
    }

    .data {
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        text-align: left;
        text-transform: capitalize;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .subtitle {
        text-align: left;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }
}
