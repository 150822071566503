@import 'styles/scss-variable.scss';

.log-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  line-height: 35px;
  background: transparent;
  border-radius: $border-radius;
  padding: 0px 12px;
  font-style: normal;
  font-weight: $headline-semi-bold;
  font-size: 14px;
  text-align: center;
  opacity: 0.7;
  cursor: pointer;
  @include themed() {
    color: t('color-font-text');
    border: 1px solid t('color-light-gray');
  }

  .icon {
    margin-bottom: 0px !important;
    width: 15px !important;
    margin-right: 6px;
  }
  
  &-icon-rev {
    .icon {
      @include themed() {
        filter: t('image-filter-rev');
      }
    }
  }

  &:focus {
    outline: 0;
  }

  &--active {
    @include themed() {
      border: 1px solid t('border-color');
      color: t('color-font-title');
      opacity: 1;
    }
  }

  &:hover {
    @include themed() {
      color: t('color-primary-black');
      border: 1px solid t('color-light-gray');
      background-color: t('color-light-gray');
    }
  }
}
