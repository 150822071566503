@import 'styles/scss-variable.scss';

.header-align {
  width: 100%;
  text-align: start;
  margin-bottom: 15px;
}

.action-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
