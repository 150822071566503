@import 'styles/scss-variable.scss';

.create-organisation {
  .page-content--inner {
    justify-content: center;
    align-items: center;
    display: flex;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 500px;
    }
  }
}
