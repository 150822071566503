@import 'styles/scss-variable.scss';

.code-snippet {
  @include themed() {
    background-color: t('code-field-background');
    color: t('color-font-text');
  }
  height: auto;
  border-radius: $border-radius;
  padding: 2px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-direction: column;
  margin: 10px 0px;
  position: relative;

  span {
    margin: 0;
    background: transparent !important;
    width: 100%;
    text-align: left;
    left: 0;
    font-family: $code-font-family !important;
  }

  .copy-icon {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 20px;
    margin-left: auto;
    height: 20px;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
