@import 'styles/scss-variable.scss';

.color-preview-container {
  position: relative;

  .color-preview {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    cursor: pointer;
    @include themed() {
      border: 1px solid t('button-color');
    }
  }

  .popover {
    position: absolute;
    z-index: 2;
    bottom: 42px;

    .cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  .block-picker {
    margin-left: -10px;
    margin-top: 12px;

    > div:first-of-type {
      left: 12% !important;
    }

    @include themed() {
      box-shadow: 0px 0px 9.41177px rgba(0, 0, 0, 0.05),
        0px 0.784314px 2.35294px rgba(0, 0, 0, 0.1) !important;
    }

    &:nth-child(2) {
      @include themed() {
        background-color: t('color-bg') !important;
      }
    }
  }
}
