@import 'styles/scss-variable.scss';

.feedback-action-form-item-editor {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: $border-radius;
  padding: 25px;
  @include themed() {
    background-color: t('color-bg');
  }

  .icon-dropdown-container {
    width: 100%;
    margin-bottom: 30px;

    .icon-dropdown-selection .icon-dropdown-selection__control {
      border-radius: $input-border-radius !important;
      @include themed() {
        border: 1px solid  t('border-color') !important;
      }
    }

    .icon-dropdown-selection {
      margin-left: 0px;
    }
  }

  .feedback-content-column {
    width: 100%;
    display: block;
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .trash-icon {
      min-width: 36px;
      min-height: 50px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themed() {
        color: t('color-font-text');
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
        }
      }
    }
  }

  .select-dropdown-item {
    width: auto;
    min-width: 300px;
  }
}

.feedback-content-row-choices {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .delete-choice-item {
    margin-left: 12px;
    cursor: pointer;

    &:hover {
      @include themed() {
        color: t('color-primary');
      }
    }
  }
}

.feedback-action-form-item-editor-row {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  svg {
    width: 22px;
    height: 30px;
    @include themed() {
      fill: t('color-font-title');
    }
  }
}

.sortable-helper {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.add-choice-item-container {
  margin-right: 5px;
}

.advanced-settings-container {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  &-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    @include themed() {
      color: t('color-primary');
    }

    svg {
      width: 22px;
      height: 14px;
    }

    .opened {
      transform: rotate(180deg);
    }
  }

  &-content {
    width: 100%;
  }
}
