@import 'styles/scss-variable.scss';
@import '../../../../styles/utils.scss';

.verify-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.08);
  min-height: 0px;
  padding: 60px;
  border-radius: $border-radius;
  @include themed() {
    background-color: t('color-bg');
  }

  .logo {
    width: 55px;
    height: auto;
    margin-bottom: 40px;
  }

  @include for-mobile-tablet-only {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 30px;
    border-radius: 0px;
  }

  .card {
    padding: 40px;
  }

  .verify-code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .code-input > div > input {
      @include for-mobile-tablet-only {
        font-size: $text-font-size;
      }
    }

    .information {
      text-align: center;
      margin-bottom: 15px;
      @include themed() {
        color: t('color-font-text');
      }

      @include for-phone-only {
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }

    .styles_react-code-input-container__tpiKG {
      width: 100% !important;
      display: flex;
      justify-content: space-between;

      .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .styles_react-code-input__CRulA > input {
      height: 52px;
      max-width: 52px;
      box-sizing: border-box;
      border-radius: $border-radius;
      letter-spacing: -0.02em;
      padding: 12px 0px !important;
      width: 100%;
      font-size: 18px;
      font-family: $text-font-family;
      background-color: transparent;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
      @include themed() {
        color: t('color-font-text');
        border: 1px solid t('border-color');
        background-color: t('color-bg');
      }

      &:focus {
        @include themed() {
          border: 1px solid t('color-primary-black');
        }
      }

      @include for-phone-only {
        height: 40px !important;
        width: 40px !important;
      }
    }

    &.error {
      .styles_react-code-input__CRulA > input {
        @include themed() {
          border: 1px solid t('color-red') !important;
        }
      }
    }

    .footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      border-bottom: 0px;

      @include for-phone-only {
        margin-top: 40px;
        justify-content: space-around;
      }

      .btn-save {
        max-width: $button-max-width;
        width: 100%;

        @include for-phone-only {
          max-width: 120px;
        }
      }
    }
  }
}
