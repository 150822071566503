@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.form-data-container {
  width: 100%;
  max-width: min(80%, 600px);
  margin-left: 0px;
  margin-bottom: 8px;
  padding: 13px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  position: relative;
  @include themed() {
    background-color: t('color-light-gray') !important;
  }

  &-footer {
    margin-left: 0px;
    margin-top: -5px;
    margin-bottom: 10px;

    time {
      font-size: 13px;
      line-height: 13px;
      padding-left: 0px;
      line-break: normal;
      padding-right: 0px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  &-edit {
    position: absolute;
    top: 10px;
    right: 5px;

    button {
      margin-top: -5px;
      min-height: 20px !important;
      background-color: transparent !important;

      &:hover {
        @include themed() {
          background-color: t('color-gray') !important;
        }
      }
    }
  }

  .form-data-upvote-container {
    padding-right: 13px;
    padding-bottom: 13px;
  }

  .form-data-container-forms {
    width: 100%;
    padding: 7px;

    .primary-button {
      margin-bottom: 13px;
    }
  }

  &-items {
    display: inline-block;
    min-width: 70%;

    .detail-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: auto;
      padding-bottom: 13px;

      .desc-item-header {
        font-family: $text-font-family;
        display: block;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        padding-right: 30px;
        @include themed() {
          color: t('color-font-text');
        }

        &:first-letter {
          text-transform: uppercase;
        }
      }

      .desc-item {
        padding-right: 0px;
        padding-top: 3px;
        font-size: 15px;
        line-height: 22px;
        word-break: break-word;
        @include themed() {
          color: t('color-font-title');
        }

        a,
        p {
          font-size: $small-text-font-size;
          line-height: $small-text-line-height;
          @include themed() {
            color: t('color-font-title');
          }
        }

        a {
          text-decoration: underline;
        }
      }

      .desc-item a:hover {
        text-decoration: underline;
      }
    }
  }
}
