@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.session-data-container {
  width: 100%;
  height: calc(100% - 49.5px);
  border-radius: $border-radius;
  overflow: auto;
  @include themed() {
    background-color: t('color-bg');
  }

  .meta-data-container {
    padding: 15px;
    margin: 20px 0px;
    @include themed() {
      color: t('color-font-title');
    }

    .meta-container {
      display: flex;
      flex-wrap: wrap;

      .detail-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
        padding-bottom: 10px;
        margin-right: 20px;
        margin-left: 20px;
        width: calc(50% - 40px);

        @include for-mobile-tablet-only {
          width: calc(100% - 40px);
        }

        .desc-item-icon {
          width: 44px;
          height: 44px;
          min-width: 44px;
          min-height: 44px;
          border-radius: $input-border-radius;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include themed() {
            border: 1px solid t('border-color');
          }

          i {
            font-size: 20px;
            @include themed() {
              color: t('color-font-title');
            }
          }
        }

        .desc-item-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .desc-item-header {
            font-size: $small-text-font-size;
            font-weight: normal;
            @include themed() {
              color: t('color-font-text');
            }
          }

          .desc-item {
            line-break: anywhere;
            word-break: break-all;
            padding-right: 20px;
            padding-top: 2px;
            font-size: $small-text-font-size;
            @include themed() {
              color: t('color-font-title');
            }

            a,
            span {
              font-size: 15px;
            }
          }

          .desc-item a:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .no-session-data {
    padding: 20px;
  }

  &--bg {
    background-position: top;
    background-size: 100%, auto, contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;

    &--light {
      background-image: url('../../../assets/sessiondata.png');
    }

    &--dark {
      background-image: url('../../../assets/sessiondataDark.png');
    }
  }

  &--no-contents {
    padding: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
