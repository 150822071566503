@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.side-by-side {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  width: 100%;

  @include for-mobile-tablet-only {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.gleap-widget-not-installed-card {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.03);
  border-radius: $border-radius;
  width: 100%;
  @include themed() {
    border: 1px solid t('border-color');
  }
}

.side-by-side-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    text-align: left;
  }
}

.options-group {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.03);
  border-radius: $border-radius;
  margin-bottom: 30px;
  padding: 25px;
  width: 100%;
  @include themed() {
    border: 1px solid t('border-color');
  }

  .options-text-footer {
    display: flex;
    flex-direction: row;
  }

  &-header {
    margin-top: -25px;
    margin-left: -25px;
    margin-right: -25px;
    padding: 12px;
    margin-bottom: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: $text-font-size;
    font-weight: $headline-semi-bold;
    text-align: start;
    @include themed() {
      background-color: t('color-ultra-light-gray');
      color: t('color-font-title');
    }

    span {
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .checkbox-settings-container:first-of-type {
    border-top: none !important;
  }
  .checkbox-settings-container:last-of-type {
    border-bottom: none !important;
  }
}

.corner-radius {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 15px;

  .replay-container-slider {
    margin-left: 20px;
    width: 100%;

    .replay-container-slider-timer {
      display: flex;
      justify-content: space-between;

      & div {
        font-size: $small-text-font-size;
        @include themed() {
          color: t('color-font-text');
        }
      }

      & div:nth-child(1) {
        width: 20%;

        @include for-mobile-tablet-only {
          width: 100%;
        }
      }

      & div:nth-child(2) {
        text-align: center;
        width: 60%;
      }

      & div:nth-child(3) {
        text-align: right;
        width: 20%;
      }
    }
  }

  .rc-slider-mark {
    left: 7px;
    right: 17px;
    width: auto;
  }

  .rc-slider-mark-text {
    font-size: 12px;
  }

  .rc-slider-dot-active {
    border-color: #59617d;
  }

  .rc-slider-handle {
    @include themed() {
      border: 1px solid t('color-font-text');
    }

    &:active {
      @include themed() {
        box-shadow: 0px 0px 7.41177px rgba(0, 0, 0, 0.08) !important;
      }
    }
  }

  .rc-slider-track {
    @include themed() {
      background-color: t('color-font-text');
    }
  }
}

.widget-logo-upload-main {
  .image {
    padding: 20px;
    border-radius: 4px;
  }

  .half-form {
    max-width: 50%;
  }

  .image-upload-component {
    height: 120px !important;
  }
}

.widget-upgrade-container {
  border-radius: $border-radius;
  margin-bottom: 30px;
  padding: 16px;
  @include themed() {
    border: 1px solid t('border-color');
  }
}

.widget-logo-upload {
  margin-bottom: 0px;

  .image-upload-component {
    border-radius: 100%;
  }
}

.positiontypeselection {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-top: 10px;
  flex-wrap: wrap;

  > div {
    width: calc(33.3333% - 8px);
    cursor: pointer;
    margin-bottom: 10px;

    &:nth-of-type(2),
    &:nth-of-type(5) {
      margin-left: 8px;
      margin-right: 8px;
    }

    > div {
      padding-bottom: 2px;
      font-weight: 500;
      font-size: 16px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  img {
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      @include themed() {
        border: 1px solid t('color-primary');
      }
    }
  }

  > div.active {
    > div {
      @include themed() {
        color: t('color-font-title');
      }
    }
    
    img {
      @include themed() {
        border: 1px solid t('color-primary');
      }
    }
  }
}

.code-editor {
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: -0.02em;
  padding: 12px 12px;
  width: 100%;
  border: 0 solid;
  font-size: $small-text-font-size;
  border-radius: $input-border-radius;
  @include themed() {
    border: 1px solid t('border-color');
    color: t('color-font-title');
  }

  textarea {
    font-family: 'Fira code', 'Fira Mono', monospace;
    font-size: 14px;
    outline: none;
    line-height: 18px;
  }

  .comment {
    width: auto !important;
    display: inline !important;
    flex-direction: inherit !important;
    margin-bottom: inherit !important;
  }

  &,
  & span {
    font-family: 'Fira code', 'Fira Mono', monospace;
    font-size: 14px;
    line-height: 18px;
  }
}

.customize-feedback-button-props {
  display: flex;

  .widget-logo-upload {
    width: auto;
    margin-right: 40px;
  }
}
