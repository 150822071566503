@import 'styles/scss-variable.scss';

.login-form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .login-form {
    max-width: 360px;

    .social-container-button  {
      display: flex;
      width: 100%;
    }

    .textinput-gray {
      border-width: 2px !important;
      height: 46px !important;
      line-height: 46px !important;
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .href {
        text-align: center;
        width: 100%;
        margin-top: 20px;
      }
    }

    .btn-container:first-child {
      flex-grow: 1;
    }
  }

  .social-container-button {
    width: 100%;
    margin: auto;
    margin-bottom: 35px;
    padding-bottom: 35px;
    max-width: 360px;
    @include themed() {
      border-bottom: 1px solid t('border-color');
    }
  }
}
