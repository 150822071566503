@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.sub-sidenav {
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: $mlsm-nav;
  padding: 12.5px 15px;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  transition: 0.45s all ease;
  @include themed() {
    background-color: t('color-light-gray');
  }

  @include for-mobile-tablet-only {
    display: none;
  }

  > .spring-spinner {
    margin: 26px;
  }

  &--buttons {
    position: absolute;
    display: flex;
    right: 10px;
    top: 17px;
    
    .board-search-filter {
      margin-right: 5px;
    }

    .icon-button.primary, .board-search-filter-button {
      padding: 0px 6px;
      height: 32px;
      min-height: 32px;
      min-width: 34px !important;
    }

    .board-search-filter--icononly .board-search-filter-button {
      min-height: 32px;
    }
  }

  &--action-back {
    display: flex;
    align-items: center;

    .title {
      padding-left: 0px !important;
      padding-bottom: 7px !important;
    }

    .action-back-icon {
      margin-right: 0px;
      object-fit: contain;
      min-width: 30px;
      height: 30px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $input-border-radius;
      cursor: pointer;

      i {
        font-size: 18px;
      }

      @include themed() {
        color: t('color-font-title');
        border: 1px solid t('color-light-gray');
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
          background-color: t('color-primary-light');
          border: 1px solid transparent;
        }
      }
    }
  }

  .title {
    text-align: left;
    padding-top: 7px;
    padding-bottom: 20px;
    padding-left: 12px;
  }

  .link-button {
    @include themed() {
      background-color: t('color-gray');
    }

    &:hover {
      @include themed() {
        background-color: t('color-primary-light');
        color: t('color-primary');

        i {
          color: t('color-primary');
        }
      }
    }
  }
}
