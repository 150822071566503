@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.ProseMirror {
  border: none;
  outline: none;
  max-height: 250px;
  overflow-y: auto;

  * {
    @include themed() {
      color: t('color-font-title');
    }
  }

  > :first-child {
    margin-top: 0px !important;
  }

  .linktype-button {
    display: inline-block;
    height: 42px;
    line-height: 42px;
    width: auto;
    padding: 0px 20px;
    border-radius: $input-border-radius;
    border: unset;
    font-style: normal;
    font-weight: $headline-semi-bold;
    font-size: $text-font-size;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    text-decoration: none !important;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: var(--primary-color) !important;
    color: var(--contrast-color) !important;
  }

  .linktype-button span {
    color: var(--contrast-color) !important;
    background-color: var(--primary-color) !important;
    text-decoration: none !important;
  }

  @include themed() {
    font-size: $small-text-font-size;
    line-height: $small-text-line-height;
  }

  > p {
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.4;
    @include themed() {
      color: t('color-font-title');
    }
  }

  ul {
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    line-height: 1;

    li {
      margin: 0px;
      padding: 0px;
      @include themed() {
        color: t('color-font-title');
      }
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 3px solid transparent;
  }

  img.ProseMirror-selectednode {
    @include themed() {
      border: 3px solid t('color-primary');
    }
  }

  strong {
    font-weight: bold !important;

    * {
      font-weight: bold !important;
    }
  }

  span {
    font-size: 16px;
    line-height: 1.4;
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.4;

    span {
      font-size: 22px;
      line-height: 1.4;
    }
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.4;

    span {
      font-size: 18px;
      line-height: 1.4;
    }
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
    font-size: $small-text-font-size;
    line-height: $small-text-line-height;
  }

  iframe {
    width: 100%;
    border: 0px !important;
    height: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .iframe-wrapper {
    position: relative;
    padding-bottom: 62.5%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  pre {
    background: #0d0d0d !important;
    color: #fff !important;
    border-radius: 0.5rem;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    font-size: $small-text-font-size;
    line-height: $small-text-line-height;
    margin-top: 20px;
    margin-bottom: 20px;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
      color: #fff !important;
    }

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }

  .mention {
    font-size: $small-text-font-size;

    @include themed() {
      color: t('color-primary');
    }
  }

  &:active {
    border: none;
    outline: none;
  }

  &-focus {
    border: none;
    outline: none;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.bubble-menu-container {
  @include themed() {
    background-color: t('color-bg');
    border: 1px solid t('border-color');
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 8px;
  }
}

.bubble-menu {
  display: flex;
  position: relative;
  align-items: center;

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    font-size: $text-font-size;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    @include themed() {
      color: t('color-font-title');
    }

    i {
      font-size: 14px;

      @include themed() {
        font-size: $text-font-size;
      }
    }

    &:hover,
    &.is-active {
      @include themed() {
        color: t('color-primary');
      }
    }

    &-ai {
      &:hover,
      &.is-active {
        @include themed() {
          color: #ffd200 !important;
        }
      }
    }

    &.is-active > svg > path:first-of-type {
      @include themed() {
        fill: t('color-primary');
      }
    }

    &-icon {
      height: $text-font-size;
      width: auto;

      path {
        @include themed() {
          color: t('color-font-title');
        }
      }

      &:hover {
        path {
          @include themed() {
            fill: t('color-primary');
          }
        }
      }
    }

    &-aiicon {
      height: 14px;
      width: auto;
      object-fit: contain;

      rect {
        @include themed() {
          fill: t('color-font-title');
        }
      }

      path {
        @include themed() {
          fill: t('color-bg');
        }
      }

      &:hover {
        rect {
          fill: #ffd200;
        }

        path {
          fill: #000;
        }
      }
    }
  }

  .spacing {
    opacity: 1;
  }

  &-link-url-input {
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    width: 100%;
    height: 100%;

    @include themed() {
      background-color: t('color-bg');
    }

    > i {
      cursor: pointer;
    }

    .link-input {
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      min-width: 250px;
      font-size: $text-font-size;

      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0d0d0d10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 1;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
