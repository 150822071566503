@import 'styles/scss-variable.scss';

.sidebar-foldable {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 25px;
  @include themed() {
    background: t('color-bg');
    border-top: 1px solid t('border-color');
  }

  &:last-of-type {
    @include themed() {
      border-bottom: 1px solid t('border-color');
    }
  }

  .title-headline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .tag {
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    padding: 3px 8px;
    border-radius: 5px;
    margin-left: 5px;
    @include themed() {
      background: t('color-bg');
      border: 1px solid t('border-color');
      color: t('color-font-text');
    }
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    i {
      font-size: 12px;
      margin-left: 10px;

      @include themed() {
        color: t('color-font-title');
      }
    }

    span {
      font-size: 14px;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
      }
    }
  }

  &--enabled {
    .sidebar-foldable-title {
      cursor: pointer;
      
      &:hover {
        i {
          @include themed() {
            color: t('color-primary');
          }
        }
  
        span {
          @include themed() {
            color: t('color-primary');
          }
        }
      }
    }
  }

  &-content {
    margin-top: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    @include themed() {
      color: t('color-font-text');
    }

    button {
      width: 100%;
    }
  }
}
