@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.screenrecording {
  display: flex;
  flex-direction: column;

  .tab-buttons {
    display: flex;
  }

  video {
    display: block;
    width: 100%;
    height: auto;
    border-radius: $border-radius;
    max-width: 450px;
    margin-bottom: 5px;

    @include for-mobile-tablet-only {
      max-width: 240px;
    }
  }

  .video-playback-info {
    display: block;
    font-size: 14px;
    padding: 5px;
    padding-bottom: 10px;
    @include themed() {
      color: t('color-font-text');
    }
  }
}
