@import 'styles/scss-variable.scss';

.textarea-container {
  position: relative;
  width: 100%;

  .textarea-gray {
    height: 40px;
    background-color: transparent;
    box-sizing: border-box;
    letter-spacing: -0.02em;
    padding: 12px;
    padding-left: 10px;
    width: 100%;
    border: unset;
    font-size: $small-text-font-size;
    border-radius: $input-border-radius;
    height: 100px;
    resize: none;
    @include themed() {
      border: 1px solid t('border-color');
      color: t('color-font-title');
    }
  }

  .textarea-gray:focus {
    outline-width: 0;

    @include themed() {
      border: 1px solid t('color-primary-black');
    }
  }

  .update-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: $small-text-font-size;
    @include themed() {
      color: t('color-primary');
    }
    cursor: pointer;
  }
}
