@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.form-widget-buttons {
  display: flex;
  justify-content: space-between;

  &--right {
    justify-content: flex-end;
  }
}

.hint {
  font-size: $small-text-font-size;
  padding-top: 5px;
  @include themed() {
    color: t('color-font-text');
  }

  a {
    font-size: $small-text-font-size;
  }
}

.action-item-header {
  display: flex;
  align-items: center;
  font-size: $text-font-size;
  font-weight: 600;
  min-height: 34px;
  @include themed() {
    color: t('color-font-title');
  }

  span {
    padding-left: 5px;
  }

  .icon {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    border-radius: 100%;
    margin-right: 10px;
    @include themed() {
      background-color: t('color-light-gray');
    }

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.menu-item-form-item-drag-icon {
  width: auto;
  height: 15px;
  margin-right: 15px;
  @include themed() {
    fill: t('color-font-title');
  }
}
