@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.bot-message-editor {
  overflow: hidden;
  @include themed() {
    border: 0px solid t('border-color');
  }

  .toolbar-wrapper {
    padding: 18px !important;
    justify-content: flex-start;
  }

  .editor-wrapper {
    padding: 25px;
    max-height: calc(100vh - 120px);
    overflow-x: auto;
    @include themed() {
      background-color: t('color-bg');
    }

    .ProseMirror {
      min-height: calc(100vh - 53px - 50px - 67px);
    }
  }
}
