@import 'styles/scss-variable.scss';

.tooltip {
  position: absolute;
  @include themed() {
    background-color: t('color-gray');
    color: t('color-font-text');
  }
  padding: 3px 10px;
  visibility: hidden;
  border-radius: $border-radius;
  left: calc(#{$max-m} + 20px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
