@import 'styles/scss-variable.scss';

.mau-limit-progress-bar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    position: relative;

    @include themed() {
      border: 1px solid t('color-dark-gray');
    }

  &-inner {
    margin: 2px;
    padding: 8px 20px;
    min-height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    padding-right: 12px;

    @include themed() {
      background-color: t('color-primary-black');
      color: t('button-color');
    }
  }

  .mau-limit-included {
    position: absolute;
    top: 2px;
    bottom: 2px;
    width: 2px;
    @include themed() {
      background-color: t('color-dark-gray');
    }
    
    &-label {
        position: absolute;
        left: 8px;
        bottom: -20px;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
        @include themed() {
          color: t('color-font-text');
        }
    }
  }
}
