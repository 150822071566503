@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.outbound-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: calc(100% - 30px);
  margin-left: auto;
  padding: 30px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.02);
  @include themed() {
    border-bottom: 1px solid t('border-color');
    border-right: 1px solid t('border-color');
  }
}

.outbound-message-item {
  display: flex;
  align-items: flex-end;
  max-width: 294px;
}

.outbound-message-item .user-avatar {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 8px;
  margin-bottom: 12px;
}

.outbound-message-item-container {
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #fff;
  margin-bottom: 12px;
  font-size: 15px;
  line-height: 21px;
  color: #333;
}

.outbound-message-item-sender {
  font-size: 13px;
  color: #333;
}

.outbound-message-item-content {
  font-size: 14px;
  line-height: 20px;
  color: #878787;
}

.outbound-email-composer {
  .widget-preview {
    position: initial;

    &-browser {
      border-radius: $input-border-radius;
      @include themed() {
        border: 1px solid t('border-color');
      }

      &-body {
        background: #f6f7fc !important;
        background-image: none !important;
        border-radius: 0px;
        border-bottom-left-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;

        .ProseMirror {
          padding: 30px;
          max-height: initial !important;
        }

        &-header {
          width: 100%;
          padding: 14px;
          display: flex;
          align-items: center;
          @include themed() {
            border-bottom: 1px solid t('border-color');
            color: t('color-font-title');
            background-color: t('color-bg');
          }

          span {
            padding-right: 5px;
            @include themed() {
              color: t('color-font-text');
            }
          }
        }
      }
    }

    .editor-container {
      width: 100%;
      background-color: #fff;
      padding: 20px;
      border-radius: $input-border-radius;
    }
  }
}
