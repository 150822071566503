@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.widget-button-preview {
  position: relative;
  height: 100%;

  .widget-activation-container {
    position: absolute;
    bottom: 25px;
    left: 25px;
    display: flex;

    .widget-activation {
      margin-right: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 5px;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 13px;
      @include themed() {
        background-color: t('code-field-background');
        color: t('button-color');
      }

      img {
        height: 20px;
        width: auto;
        margin-right: 5px;
      }
    }
  }

  .widget-bugbattle--feedback-new-button {
    width: auto;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    max-width: 100%;

    &--left {
      justify-content: flex-start;
    }

    &-button {
      cursor: pointer;
      width: 48px;
      height: 48px;
      border-radius: 48px;
      transition: box-shadow 0.3s ease-in-out;
      box-shadow: 0 1px 3px rgb(0 0 0 / 15%), 0 0 20px rgb(0 0 0 / 10%);
      position: relative;

      svg,
      img {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 9px;
        left: 9px;
        object-fit: contain;
      }
    }
  }

  .widget-bugbattle--feedback-button {
    &-text {
      cursor: pointer;
      top: 50%;
      right: 0px;
      position: absolute;
      padding: 9px 20px;
      text-align: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-size: 16px;
      color: #fff;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
      animation-duration: 0.2s;
      animation-fill-mode: both;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      transform: rotate(-90deg) translate(50%, -50%);
      transform-origin: 100% 50%;

      &--left {
        transform: rotate(90deg) translate(-50%, -100%);
        transform-origin: 0 0;
        right: auto;
        left: 1px;
      }

      &--bottom {
        transform: none;
        transform-origin: 0 0;
        right: 20px;
        left: auto;
        bottom: 0px;
        top: auto;
      }
    }
  }

  .widget-button-preview-browser {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include themed() {
      border-left: 1px solid t('border-color');
      background-color: t('color-light-gray');
    }

    &-head {
      display: flex;
      padding: 10px;

      div {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin-right: 5px;
      }

      &-close {
        background-color: #fe5f57;
      }

      &-minimize {
        background-color: #febc2e;
      }

      &-maximize {
        background-color: #2bc83f;
      }
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      background-image: radial-gradient(
        rgba(33, 112, 231, 0.2) 1px,
        transparent 1px
      );
      background-size: 30px 30px;
      @include themed() {
        border-top: 1px solid t('border-color');
        background-color: t('color-bg');
      }

      .feedback-box {
        width: 100%;
        max-width: 390px;
        background-color: #fff;
        box-shadow: 0 4px 12px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 10%);
        border-radius: 15px;
        position: relative;
        min-height: 620px;

        &-header {
          box-sizing: border-box;
          flex-direction: column;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding: 39px;
          box-sizing: border-box;
          flex-direction: column;
          padding-bottom: 162px;
          position: relative;
          overflow: hidden;

          &--inner {
            content: ' ';
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 100%;
            height: 100%;
            max-height: 380px;
          }

          &--shadow {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 100%;
            height: 100%;
            max-height: 380px;
            background: linear-gradient(
              130deg,
              var(--header-dark-color) 0%,
              var(--header-color) 100%
            );
            z-index: 1;

            &::before {
              content: ' ';
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              height: 100%;
              height: 100%;
              max-height: 380px;
              background: linear-gradient(
                120deg,
                rgba(0, 0, 0, 0.3) 0%,
                rgba(0, 0, 0, 0.1) 45%,
                transparent 60%
              );
            }
          }

          svg,
          img {
            height: 34px;
            width: auto;
            max-width: 200px;
            object-fit: contain;
            text-align: left;
            margin-bottom: 70px;
            z-index: 5;
          }

          &-title span,
          &-title .hand {
            position: relative;
            z-index: 5;
            color: #fff;
            text-align: left;
            font-size: 26px;
            line-height: 30px;
            font-weight: 800;
            margin-right: 6px;
            opacity: 0.7;
          }

          &-title .hand {
            opacity: 1 !important;
          }

          &-subtitle {
            z-index: 5;
            color: #fff;
            text-align: left;
            font-size: 26px;
            line-height: 30px;
            font-weight: 800;
            margin-top: 2px;
            margin-bottom: 4px;
            opacity: 1;
          }
        }

        &-body {
          position: relative;
          margin: 35px 0;
          margin: 12px 15px;
          margin-top: -140px;
          flex-grow: 1;
          z-index: 10;

          &-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 13px 20px;
            cursor: pointer;
            border: 1px solid transparent;
            box-sizing: border-box;
            transition: box-shadow 0.2s ease-in-out;
            box-sizing: border-box;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
              rgb(0, 0, 0, 0.06) 0px 2px 8px;
            margin-bottom: 12px;

            &-type {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 20px;
              cursor: pointer;

              &-icon {
                width: 100%;
                height: 100%;
                max-width: 20px;
                max-height: 20px;
                object-fit: contain;
                box-sizing: border-box;
              }

              &-text {
                flex-grow: 1;
              }

              &-title {
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 3px;
                color: #000;
              }

              &-description {
                color: #59617d;
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
              }
            }
          }

          .widget-menu-previous-feedback {
            margin-top: 10px;
            border-top: 1px solid #eee;
            padding: 10px 20px;

            span {
              font-size: 14px;
              line-height: 32px;
              font-weight: normal;
            }
          }
        }

        &-footer {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0;
          margin-bottom: 0;
          cursor: pointer;
          margin: 0;
          margin-top: 20px;
          margin-bottom: 20px;

          span {
            font-weight: 500;
            font-size: 13px;
            user-select: none;
            margin-right: 4px;
            margin-left: 4px;
          }

          svg.bolt {
            height: 13px;
            width: auto;
          }

          svg.logo {
            height: 14px;
            width: auto;
            margin-top: 4px;
          }
        }

        .tab-bar {
          width: 100%;
          height: 76px;
          box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 5%);
          display: flex;
          justify-content: space-around;
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          z-index: 99;

          .tab-bar-item {
            display: flex;
            flex-direction: column;
            width: 25%;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          .tab-bar-item svg {
            opacity: 0.7;
            height: 18px;
            width: auto;
            margin-bottom: 8px;
          }

          .tab-bar-item span {
            opacity: 0.7;
            font-size: 13px;
            line-height: 16px;
            font-weight: 550;
            color: var(--primary-text-color);
          }

          .tab-bar-item.active {
            svg {
              opacity: 1;
            }
            span {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
