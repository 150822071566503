@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.creatable-select-container {
  @include themed() {
    background: t('color-bg');
  }
}

.tag-select-tag {
  white-space: nowrap;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 13px;
  font-weight: 600;
}

.tag-select-add {
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  @include themed() {
    color: t('color-font-title');
    background-color: t('color-light-gray');

    i {
      color: t('color-font-title');
    }
  }

  &:hover {
    @include themed() {
      background-color: t('color-light-gray');
      color: t('color-font-title');
  
      i {
        color: t('color-font-title');
      }
    }
  }
}

.tag-select {
  width: 100%;
  min-height: auto !important;

  .tag-dropdown-selection {
    &__control {
      margin: 0px;
      padding: 0px;
      width: 100%;
      position: relative;
      border: none !important;
      @include themed() {
        background: t('color-bg');
      }

      @include for-mobile-tablet-only {
        font-size: $text-font-size;
      }
    }

    &__value-container {
      display: flex !important;
    }

    &__control--is-disabled {
      .tag-dropdown-selection__multi-value__label {
        padding-right: 8px;
        @include themed() {
          color: t('color-font-text');
        }
      }

      .tag-dropdown-selection__multi-value__remove {
        display: none;
      }
    }

    &__control--is-focused {
      @include themed() {
        box-shadow: none !important;
      }

      .tag-select-add {
        display: none;
      }
    }

    &__value-container {
      padding: 0px !important;
    }

    &__multi-value {
      max-width: 45%;
      border-radius: 8px;
      @include themed() {
        background: t('color-bg');
        border: 1px solid t('border-color');
      }

      &__label {
        padding: 4px;
        padding-left: 8px;
        padding-right: 0px;
        font-size: $medium-text-font-size;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    &__multi-value__remove {
      cursor: pointer;

      &:hover {
        background-color: transparent !important;
      }
    }

    &__indicator {
      display: none;
    }

    &__clear-indicator {
      display: none;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      cursor: pointer;
    }

    &__menu {
      @include themed() {
        background-color: t('color-bg');
        border: 1px solid t('border-color');
        color: t('color-font-title');
        z-index: 999;
      }
      box-shadow: rgba(12, 12, 12, 0.1) 0px 3px 6px,
        rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
      border-radius: $border-radius !important;
      font-size: $medium-text-font-size !important;
      padding: 0px;
      margin: 0px;
    }

    &__menu-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px;
      margin: 0px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;

      .option:first-of-type {
        border-top-left-radius: $border-radius !important;
        border-top-right-radius: $border-radius !important;
        padding-top: 14px;
      }

      .option:last-of-type {
        border-bottom-left-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
        padding-bottom: 14px;
      }

      .option {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 0px;
        cursor: pointer;
        background-color: transparent;
        font-size: $medium-text-font-size;
        @include themed() {
          color: t('color-font-title');
        }

        &:hover {
          @include themed() {
            color: t('color-font-title');
            background-color: t('color-light-gray');
          }
        }
      }
    }
  }
}
