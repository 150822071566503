@import 'styles/scss-variable.scss';

.invitation-card {
  padding: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: calc(100% - 16px) !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .invitation-info {
    width: 100%;
  }

  .organisation-name {
    font-weight: $headline-semi-bold;
    font-size: $text-font-size;
    max-width: calc(100% - 45px);
    @include themed() {
      color: t('color-font-title');
    }
  }

  .subtitle {
    text-align: left;
  }

  .button-group {
    display: flex;
    margin-top: 15px;

    .primary-button {
      margin-left: 10px;
    }
  }
}
