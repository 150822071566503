@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.rating-action-editor {
  padding: 0px;

  .icon-dropdown-selection {
    margin-left: 0px !important;
  }

  .icon-dropdown-container .icon-dropdown-selection__control {
    @include themed() {
      border: 1px solid t('border-color') !important;
    }
  }
}
