@import 'styles/scss-variable.scss';

.avatar-image {
  overflow: hidden;
  border-radius: 20px;
  height: 120px;
  width: 120px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  outline: none;
  @include themed() {
    background-color: t('color-bg');
    border: 1px solid t('border-color') !important;
  }

  .image {
    display: flex;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-picker {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    img {
      padding: 5px;
      width: 30px !important;
      height: 30px !important;
      position: absolute;
      right: 5px !important;
      bottom: 5px !important;
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themed() {
        background-color: t('color-primary');
      }
    }
  }

  .initials {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $huge-font-size;
    font-weight: $headline-semi-bold;
  }
}

.avatar-file-picker {
  border: none;
  border-width: 0;
  box-shadow: none;
}
