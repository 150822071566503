@import 'styles/scss-variable.scss';

.bug-prio-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: $small-text-font-size;
  max-width: 100%;

  .bug-prio-item-dot {
    min-width: 13px;
    min-height: 13px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    margin: 2px;
    margin-right: 12px;
  }

  span {
    font-size: $small-text-font-size;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include themed() {
      color: t('color-font-title');
    }
  }
}