@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.upsell-onboarding-modal {
  display: flex;
  width: auto;
  justify-content: center;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.08);
  min-height: 0px;
  padding: 50px 80px;
  border-radius: $border-radius;
  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 30px;
    border-radius: 0px;
  }

  .plan-info,
  .checkout-loading-container {
    width: min(470px, 80vw);
    min-height: 300px;
  }

  .skip-button {
    display: block;
    width: auto;
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .onboarding-trial-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .upsell-onboarding-modal-image {
    img {
      width: 340px;
      height: 100%;
      object-fit: contain;
    }
  }

  .upsell-onboarding-modal-centered {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    width: 100%;
    min-width: min(80vw, 470px);
    max-width: 470px;

    .title,
    .text {
      text-align: left;
      font-weight: 450;
      line-height: 1.4;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }

    .text {
      b {
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .upsell-item-group {
      margin-bottom: 10px;
      padding: 30px 0px;
    }

    .upsell-item {
      display: flex;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      .icon {
        min-width: 32px;
        padding-top: 3px;

        i {
          font-size: 20px;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }
  }
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}
