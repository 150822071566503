@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.shared-board {
  padding: 0px;
  min-height: 100%;
  height: calc((var(--vh,1vh)*100) - 86px);
  display: flex;
  width: 100%;
  max-width: 100vw;
  padding: 0px;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0);

  @include for-mobile-tablet-only {
    padding-left: 0px;
    padding-right: 0px;
    height: calc((var(--vh,1vh)*100) - 97px);
  }

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-button:end:increment,
  &::-webkit-scrollbar-button:start:decrement {
    background: #0000;
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    @include themed() {
      background-color: t('color-light-gray');
    }
  }
  &::-webkit-scrollbar-track-piece:horizontal:start {
    border-radius: 4px 0 0 4px;
    margin-left: 15px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:end {
    border-radius: 0 4px 4px 0;
    margin-right: 15px;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    display: block;
    padding-bottom: 10px;
    @include themed() {
      background-color: t('color-dark-gray');
    }
  }

  * {
    outline: none;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .shared-column {
    height: 100%;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 20px;
    padding-bottom: 10px;
    width: 400px;
    max-width: 400px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    @include for-mobile-tablet-only {
      width: calc(100vw - 30px) !important;

      @include themed() {
        padding: 10px !important;
      }
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 60px;
      width: 100%;
      z-index: 10;
      display: block;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .shared-column-cards {
      -webkit-transform: translateZ(0);
      -webkit-overflow-scrolling: touch;
      overflow: auto;
      padding-top: 10px;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }
      &::-webkit-scrollbar-button:end:increment,
      &::-webkit-scrollbar-button:start:decrement {
        background: #0000;
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        @include themed() {
          background-color: t('color-gray');
        }
      }
      &::-webkit-scrollbar-track-piece:vertical:start {
        border-radius: 4px 4px 0 0;
      }
      &::-webkit-scrollbar-track-piece:vertical:end {
        border-radius: 0 0 4px 4px;
      }
      &::-webkit-scrollbar-track-piece:horizontal:start {
        border-radius: 4px 0 0 4px;
      }
      &::-webkit-scrollbar-track-piece:horizontal:end {
        border-radius: 0 4px 4px 0;
      }
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb:vertical {
        border-radius: 4px;
        display: block;
        height: 48px;
        @include themed() {
          background-color: t('color-dark-gray');
        }
      }

      .feature-request-card {
        margin: 10px;
        @include themed() {
          border: 1px solid t('border-color') !important;
        }

        .feature-request-card-upvotes {
          min-width: 48px;
          min-height: 48px;
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
