@import 'styles/scss-variable.scss';

.image-upload-wrapper {
  width: 100%;

  .image-upload-component {
    height: 250px;
    width: 100%;
    position: relative;
    border-radius: $border-radius;
    outline: none;
    @include themed() {
      border-color: t('color-font-text');
    }
    border-width: 2px;
    border-style: dashed;
    padding: 10px;

    .image-upload-remove {
      position: absolute;
      top: 1px;
      right: 1px;
      cursor: pointer;
      padding: 0px;
      border-radius: 100%;
      @include themed() {
        background-color: t('color-bg');
      }

      i {
        font-size: 21px;
        @include themed() {
          color: t('color-font-title');
        }
      }

      &:hover {
        i {
          @include themed() {
            color: t('color-primary');
          }
        }
      }
    }

    &.manually-add-screenshot {
      height: 120px;
    }

    &--gragging {
      @include themed() {
        border-color: rgba($color: t('color-primary'), $alpha: 0.5);
        background-color: rgba($color: t('color-primary'), $alpha: 0.04);
      }
    }

    .image {
      @include themed() {
        background-color: t('color-bg');
      }
      cursor: pointer;
      display: flex;
      position: absolute;
      overflow: hidden;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: $border-radius;
      }
    }

    .loading-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .file-picker {
      position: relative;
      width: 100%;
      height: 100%;
      border: none;
      border-width: 0;
      box-shadow: none;
      outline: none;
      cursor: pointer;

      .draginfo-label {
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        margin-top: -22px;
        @include themed() {
          color: t('color-font-text');
        }
        text-align: center;
        font-weight: $headline-semi-bold;
        font-size: $text-font-size;

        .accepts-label {
          font-size: 8px;
          line-height: 12px;
          font-weight: normal;
        }
      }
    }

    .initials {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $huge-font-size;
      font-weight: $headline-semi-bold;
    }
  }

  .image-file-picker {
    border: none;
    border-width: 0;
    box-shadow: none;
  }
}
