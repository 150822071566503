@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.loading-failed {
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    margin-bottom: 20px;
  }
}

.onboarding-welcome-container {
  height: calc(var(--vh, 1vh) * 100);
  width: calc(100vw - $mnl-nav);
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  padding: 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  overflow-y: auto;

  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    padding: $container-padding;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    overflow: unset;
    width: 100vw;
    padding-top: calc($mobile-nav-height + 20px);
  }

  .dashboard-headline {
    font-size: 18px;
    font-weight: $headline-semi-bold;
    text-align: start;
    line-height: 32px;
    flex-grow: 1;
    @include themed() {
      color: t('color-font-title');
    }

    @include for-mobile-tablet-only {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  .dashboard--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 30px;

    .title {
      text-align: left;
      font-size: 30px;
    }

    .subtitle {
      margin-top: 10px;
      text-align: left;
      font-size: 18px;
    }

    .primary-button {
      width: auto;
    }
  }

  .dashboard-content {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @include for-mobile-tablet-only {
      flex-direction: column;
    }

    .organisations-container {
      max-width: 1000px;
      min-height: 100%;
      width: 100%;
      padding: $scroll-padding-big $scroll-padding;
      margin: auto;

      @include for-mobile-tablet-only {
        max-width: 100%;
        width: 100%;
        padding: 20px;
        height: auto;
        overflow-y: initial;
      }

      .organisation-header {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 8px 0px;
        margin-bottom: 2px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        text-align: start;
        display: flex;
        justify-content: space-between;
        @include themed() {
          color: t('color-font-title');
        }

        &-headline {
          font-size: 22px;
          font-weight: $headline-semi-bold;
          line-height: 38px;
          padding-left: 0px;
          @include themed() {
            color: t('color-font-title');
          }
        }

        &-free-trial {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .trial-tag {
            margin-left: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 12px;
            padding-right: 4px;
            padding-left: 16px;
            min-width: 20px;
            border-radius: 46px;
            @include themed() {
              color: t('color-primary');
              background-color: t('color-primary-ultralight');
            }

            .trial-tag-content {
              font-size: 14px;
              font-weight: 600;
            }

            .link-button {
              min-height: 26px;
              margin-left: 10px;
              height: 26px;
              line-height: 26px;
              border-radius: 26px;
              font-size: 14px;
              @include themed() {
                color: t('button-color');
                background-color: t('color-primary');
              }

              &:hover {
                @include themed() {
                  background-color: t('color-primary-highlight');
                }
              }
            }
          }
        }

        &-actions {
          @include for-mobile-tablet-only {
            display: none;
          }
        }

        .link-button {
          box-shadow: none !important;
          border: none !important;
          min-height: 32px;
          height: 32px;
          line-height: 32px;
          border-radius: 8px;
          padding: 0px 10px;
          margin-left: 5px;
        }
      }

      .project-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;
        width: 100%;
      }

      .project-cards {
        display: grid;
        column-gap: 22px;
        row-gap: 22px;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include for-mobile-tablet-only {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      .project-search-filter {
        padding: 0px 10px;
        margin-bottom: 10px;
      }

      .create-project-button {
        min-height: 86px;
        padding: 12px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: $input-border-radius;
        box-shadow: none;
        font-size: 16px;
        @include themed() {
          background-color: t('color-card-bg');
          border: 1px dashed t('border-color');
          color: t('color-font-title');
        }

        &:hover {
          @include themed() {
            background-color: t('color-primary-light');
            border: 1px solid t('color-primary-light');
            color: t('color-primary');
          }
        }
      }

      .organisation-group {
        width: 100%;
        border-radius: $border-radius;
        margin-bottom: 30px;
        padding: 0px;
        width: 100%;
      }
    }

    .my-issues-container {
      max-width: 450px;
      width: 450px;
      padding: 20px;
      overflow-y: auto;
      @include themed() {
        border-left: 1px solid t('border-color');
      }

      @include for-mobile-tablet-only {
        max-width: 100%;
        width: 100%;
        padding: 20px;
        overflow-y: initial;
        border-left: 0px !important;
      }

      .my-tasks-header {
        display: flex;
      }

      .my-tasks-list {
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        .no-tasks-container {
          border-radius: $border-radius;
          padding-bottom: 35px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          .title {
            text-align: left;
            font-size: 16px;
          }
        }
      }

      .invitations-list {
        .card {
          margin: 8px 0px;
        }
      }
    }
  }
}

.no-projects-container {
  margin: auto;
  border-radius: $border-radius;
  padding-bottom: $scroll-padding;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include themed() {
    background-color: t('color-light-gray');
  }

  .no-projects-image {
    height: auto;
    width: 100%;
    max-width: 580px;
    object-fit: contain;
    padding-top: 0px;
    padding-bottom: 30px;
  }
}
