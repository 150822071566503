@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.shared-column-header {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;

  &-title {
    font-weight: 600;
    font-size: 14px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @include themed() {
      color: t('color-font-title');
    }
  }

  &-count {
    margin-left: 8px;
    padding: 2px 8px;
    border-radius: 21px;
    font-weight: 500;
    font-size: 13px;
  }
}
