@import 'styles/scss-variable.scss';

.board-filter {
  position: relative;
  margin-right: 10px;

  &-active-indicator {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 12px;
    height: 12px;
    z-index: 12;
    border-radius: 100%;
    @include themed() {
      background-color: t('color-yellow');
    }
  }

  .dropdown-selection, .user-filter-selection {
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    max-width: 100% !important;
  }
  
  &-selection {
    position: absolute;
    top: 41px;
    right: 0px;
    min-width: 260px;
    z-index: 9999;
    padding: 8px;
    border-radius: $border-radius;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    @include themed() {
      background-color: t('color-card-bg');
      border: 1px solid t('border-color');
    }
  }

  &-button {
    display: flex;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    height: auto;
    min-height: auto;
    border-radius: $input-border-radius;
    padding: 7px 14px;
    font-style: normal;
    text-align: center;

    cursor: pointer;
    @include themed() {
      color: t('color-font-title');
      background-color: t('color-light-gray');

      span {
        color: t('color-font-title');
        font-weight: $headline-semi-bold;
        font-size: $medium-text-font-size;
        padding-left: 6px;
        white-space: nowrap;
      }

      i {
        color: t('color-font-title');
        font-size: $medium-text-font-size;
      }
    }

    &:hover {
      @include themed() {
        background-color: t('color-primary-light');
        color: t('color-primary');

        i, span {
          color: t('color-primary');
        }
      }
    }
  }
}