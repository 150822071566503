@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.react-loading-skeleton {
  @include themed() {
    background-color: t('color-gray');
  }
}

.react-loading-skeleton::after {
  @include themed() {
    background: linear-gradient(
      90deg,
      t('color-gray'),
      t('color-ultra-light-gray'),
      t('color-gray')
    ) !important;
  }
}
