@import 'styles/scss-variable.scss';

.social-button {
  width: 100% !important;
  margin: 0px !important;
  height: 45px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  border-radius: $input-border-radius !important;
  overflow: hidden;
  outline: none;
  padding: 0px !important;
  text-decoration: none !important;
  font-weight: $headline-semi-bold;
  @include themed() {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    background-color: t('color-bg') !important;
    color: t('color-font-text') !important;
  }

  svg {
    height: 22px;
  }

  &:hover {
    @include themed() {
      background-color: t('color-light-gray') !important;
    }
  }
}

.social-href {
  width: 100%;
  margin-top: 15px !important;
  border-radius: $border-radius !important;
  @include themed() {
    background: t('color-primary') !important;
  }
}

.shadow {
  @include themed() {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

a {
  text-decoration: none;
}
