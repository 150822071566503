@import 'styles/scss-variable.scss';

.condition-picker {
  display: flex;

  .condition-picker-conditions {
    display: flex;
    flex-wrap: wrap;

    &-operator {
      margin-right: 5px;
      border-radius: $input-border-radius;
      font-size: $log-text-font-size;
      font-weight: $headline-semi-bold;
      border: 0px solid transparent !important;
      min-height: 38px;
      line-height: 38px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .dropdown-selection {
    margin-bottom: 10px !important;
  }

  .dropdown-selection__control {
    min-width: auto !important;
    min-height: 38px !important;
    background-color: transparent !important;

    &:hover {
      @include themed() {
        background-color: t('color-gray');
      }
    }

    .dropdown-selection__value-container {
      padding-top: 0px !important;
      padding: 0px !important;
    }

    .dropdown-selection__indicators {
      display: none;
    }
  }

  .option-list-item {
    i {
      font-size: 14px;
      margin-left: 5px;
      margin-right: 10px !important;
    }

    font-size: 14px;
    font-weight: 600;
    width: 280px;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-selection
    .dropdown-selection__control
    .dropdown-selection__placeholder {
    @include themed() {
      color: t('color-primary') !important;
    }

    &:hover {
      @include themed() {
        color: t('color-primary-highlight') !important;
      }
    }
  }

  .dropdown-selection__placeholder {
    padding-left: 0px;
  }

  .dropdown-selection__menu {
    min-width: 280px;
  }
}
