@import 'styles/scss-variable.scss';

.draggable-input-container {
  width: 100%;

  .draggable-input-item {
    width: 100%;
    border-radius: $border-radius;
    padding: 4px;
    background-color: transparent;
    font-size: $small-text-font-size;
    border: none;
    @include themed() {
      color: t('color-font-title');
    }
  }
}
