@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.left-align-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .title {
    @include for-mobile-tablet-only {
      max-width: 50vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.capitalized-title {
  .page-headline {
    .title {
      text-transform: capitalize;
    }
  }
}

.page-headline {
  height: 67px;
  padding: 0px 19px;
  padding-left: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  @include themed() {
    background-color: t('color-bg');
    border-bottom: 1px solid t('border-color');
  }

  @include for-mobile-tablet-only {
    max-width: 100vw;
  }

  &--transparent {
    background-color: transparent !important;
    border-bottom: none !important;
  }

  .title {
    text-align: left;
    font-weight: 700;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .fa-spin {
    font-size: 20px;
  }

  &-tag {
    height: 20px;
    margin-left: 6px;
    font-size: 10px;
    padding: 4px;
    border-radius: 5px;
    font-weight: 600;
    @include themed() {
      background-color: t('color-primary');
      color: t('button-color');
    }
  }

  .action-back-icon {
    margin-right: 10px;
    object-fit: contain;
    min-width: 44px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $input-border-radius;
    cursor: pointer;

    i {
      font-size: 18px;
    }

    @include themed() {
      color: t('color-font-title');
      border: 1px solid t('color-light-gray');
    }

    &:hover {
      @include themed() {
        color: t('color-primary');
        background-color: t('color-primary-light');
        border: 1px solid transparent;
      }
    }
  }
}

.headline-input {
  position: relative;

  .headline-input-field {
    font-size: 18px !important;
    font-weight: 700;

    input {
      font-size: 20px !important;
      font-weight: 700;
      padding-left: 10px !important;
      border-radius: 5px !important;
      padding-top: 10px !important;
      min-width: 180px;
      max-width: 450px;
      @include themed() {
        border: none !important;
        background-color: t('color-light-gray') !important;
        border-bottom: 2px solid t('color-light-gray') !important;
      }

      &:focus {
        @include themed() {
          border: none !important;
          border-bottom: 2px solid t('color-primary-black') !important;
        }
      }
    }
  }
}
