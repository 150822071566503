@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.container {
  width: 100%;
  max-width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  padding-top: 67px;
  @include themed() {
    background-color: t('color-bg');
  }

  &--fixed {
    height: calc(var(--vh, 1vh) * 100 - 67px);
    margin-top: 67px;
    padding-top: $scroll-padding;
    padding-bottom: $scroll-padding;
  }

  &--full {
    margin-left: $mnl-nav;
    width: 100%;
    max-width: calc(100vw - $mnl-nav);

    .headline-fixed {
      left: 0px;
    }
  }

  &--subnav {
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: flex-start;
    align-items: flex-start;

    .headline-fixed {
      left: $mnl-nav;
    }

    &--big {
      .headline-fixed {
        left: $mnl-nav;
      }

      .dashboard-button {
        margin-left: -12.5px;
        margin-right: -12.5px;
        margin-top: -12.5px;
        margin-bottom: 20px;
      }
    }

    &--bigsmall {
      .dashboard-button {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    .sub-sidenav {
      min-width: $ml-nav;
      padding: 0px;
      padding-top: 79.5px !important;
      height: calc(var(--vh, 1vh) * 100);
      overflow-y: auto;
      @include themed() {
        background-color: t('color-light-gray');
        border-right: 1px solid t('border-color');
      }

      > .spring-spinner {
        margin: 26px;
      }

      .subnav-title {
        text-align: left;
        padding-top: 5px;
        padding-bottom: 25px;
        padding-left: 12px;
      }

      &--big {
        min-width: $ml-nav !important;
        max-width: $ml-nav !important;
      }
    }
  }
}
