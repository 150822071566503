@import 'styles/scss-variable.scss';

.user-table {
  width: 100%;
  border-spacing: 0;
  box-shadow: 0px 0px 9.41177px rgba(0, 0, 0, 0.05),
    0px 0.784314px 2.35294px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  margin-bottom: 40px;
  @include themed() {
    background-color: t('color-bg');
  }

  .thead {
    text-align: left;
    border-collapse: none !important;
    border-spacing: 0px !important;
    text-align: left;
  }

  .row {
    th {
      font-weight: 600;
      padding: 10px;
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-light-gray');
      }

      &:first-of-type {
        border-top-left-radius: $border-radius;
      }

      &:last-of-type {
        border-top-right-radius: $border-radius;
      }
    }

    .button {
      border: 0;
      border-radius: none;
      padding: 0.5em;
      margin-bottom: 1px;
      background-color: transparent;
      opacity: 0.6;
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .button:focus {
      border: none;
      outline: none;
    }
  }

  .tr {
    padding: 10px;

    .dropdown {
      box-shadow: none;
      padding: 10px;
      margin-top: 0px;
      border-radius: $border-radius;
      @include themed() {
        border: 1px solid t('color-light-gray');
      }
    }

    td {
      padding: 15px 10px;
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
        border-bottom: 1px solid t('color-light-gray');
      }
    }

    .trash {
      padding: 10px;
      cursor: pointer;

      svg {
        width: 16px;
        height: auto;
        @include themed() {
          fill: t('color-font-text');
        }
      }

      &:hover {
        svg {
          @include themed() {
            fill: t('color-primary');
          }
        }
      }
    }

    .invoice-number {
      @include themed() {
        color: t('color-primary');
      }
    }

    .dropdown-cell {
      padding: 6px;
    }

    &:last-of-type {
      td {
        border-bottom: none;

        &:first-of-type {
          border-bottom-left-radius: $border-radius;
        }

        &:last-of-type {
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }
}

.organization-role {
  opacity: 0.5;
}

.hide-column {
  display: none;
}
