@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.static-feature-request-container {
  width: 100%;
  padding: 30px;
  margin-bottom: 20px;
  position: relative;
  border-radius: $border-radius;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

  .static-feature-request {
    &-edit {
      margin-top: 10px;
    }

    &-head {
      display: flex;
      flex-direction: row;
      outline: none;
      align-items: center;
      padding: 0px;
      border-radius: $border-radius;
      max-width: 100%;
      margin-bottom: 5px;
      margin-left: 0px;

      .feature-request-card-subscribes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius;
        min-width: 56px;
        min-height: 56px;
        width: 48px;
        height: 56px;
      }
    }

    &-header {
      padding-left: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 60px;

      &-title {
        padding-top: 0px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        word-wrap: break-word;
        @include themed() {
          color: t('color-font-title');
        }

        .textinput-gray {
          @include themed() {
            background-color: t('color-bg') !important;
          }
        }
      }

      &-date {
        margin-top: 3px;
        font-size: 14px;
        font-weight: 400;
        opacity: 1;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }

  .static-feature-request-body {
    margin-top: 20px;

    * {
      font-size: 14px;
      line-height: 1.35;
    }

    &-text {
      @include themed() {
        color: t('color-font-title');
      }
    }

    &-content {
      .ProseMirror {
        max-height: none !important;
      }
    }
  }
}
