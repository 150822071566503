@import 'styles/scss-variable.scss';

.appsumo-register-form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .appsumo-register-form {
    .prefilled-form-item {
      margin-bottom: 20px;
      height: 44px;
      background-color: transparent;
      box-sizing: border-box;
      letter-spacing: -0.02em;
      padding: 12px 12px;
      width: 100%;
      border: 0 solid;
      font-size: $small-text-font-size;
      border-radius: $input-border-radius;
      cursor: not-allowed;
      @include themed() {
        background-color: t('color-gray');
        border: 1px solid t('border-color');
        color: t('color-font-title');
      }
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .href {
        text-align: center;
        font-size: $small-text-font-size;
        width: 100%;
      }

      .primary-button {
        margin: auto;
      }
    }

    .btn-container:first-child {
      flex-grow: 1;
    }
  }

  .social-container-button {
    width: 100%;
    margin-top: 30px;
    padding-top: 30px;
    @include themed() {
      border-top: 1px solid t('border-color');
    }
  }
}
