@import 'styles/scss-variable.scss';

.language-select {
  .language-selection {
    &__control {
      padding: 2px 8px;
      border-radius: $input-border-radius !important;
      font-size: 13px;
      line-height: 19px;
      font-weight: 600;
      cursor: pointer;
      border: none !important;
      box-shadow: none !important;
      min-height: 0px;

      @include themed() {
        background-color: t('color-light-gray');
        color: t('color-font-title');
      }

      &:hover {
        @include themed() {
          background-color: t('color-primary-light');
          color: t('color-primary');

          i {
            color: t('color-primary');
          }

          .language-selection__single-value {
            color: t('color-primary');
          }

          .language-selection__indicator > svg {
            fill: t('color-primary');
          }
        }
      }
    }

    &__value-container {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    &__indicators {
      .language-selection__indicator-separator {
        display: none;
      }

      .language-selection__indicator {
        padding: 0px;
      }

      .language-selection__indicator > svg {
        @include themed() {
          fill: t('color-font-title');
        }
      }
    }

    &__placeholder {
      font-size: 13px;
      font-weight: 600;

      @include themed() {
        color: t('color-bg');
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
        }
      }
    }

    &__option,
    &__option--is-selected {
      cursor: pointer;

      @include themed() {
        background-color: t('color-bg');
        color: t('color-font-title');
      }
    }

    &__option--is-focused {
      @include themed() {
        background-color: t('color-primary-light');
        color: t('color-primary');
      }
    }

    &__single-value {
      font-size: 13px;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
      }
    }

    &__input-container {
      margin: 0px;
      padding: 0px;
    }
  }
}
