@import 'styles/scss-variable.scss';

.counter {
  @include themed() {
    color: t('color-font-text');
  }
  font-family: $text-font-family;
  font-size: $text-font-size;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
