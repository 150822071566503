@import 'styles/scss-variable.scss';

.sortable-table-header {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 15px;
  padding-top: 0px;
  text-align: start;
  @include themed() {
    color: t('color-font-text');
    border-bottom: 1px solid t('border-color');
  }

  > div {
    font-size: 15px;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.r-66 {
  width: 66%;
}

.r-66-first {
  width: calc(66% - 50px);
}

.r-33 {
  width: 33%;
}

.r-25 {
  width: 25%;
}

.article-ratings-title {
  width: 330px;
}

.article-ratings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 330px;
  padding: 10px;
  border-radius: 8px;

  &-score {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;

    @include themed() {
      color: t('color-font-title');
    }

    &--good {
      @include themed() {
        color: t('color-green');
      }
    }

    &--bad {
      @include themed() {
        color: t('color-red');
      }
    }
  }

  &--inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;
    padding-right: 10px;

    @include themed() {
      border-right: 1px solid t('color-dark-gray');
    }
  }

  @include themed() {
    background-color: t('color-light-gray');
  }

  &-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    line-height: 16px;

    @include themed() {
      color: t('color-font-title');
    }

    span {
      font-size: 20px;
      line-height: 20px;
      padding-right: 4px;
    }
  }
}

.collection-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  padding-right: 15px;
  margin-bottom: 0px;
  min-height: 60px;

  @include themed() {
    border-bottom: 1px solid t('border-color');
  }

  &-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .cell-link {
      flex-grow: 1;
    }

    &-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 5px;
      margin-left: 5px;
      flex-shrink: 0;
    }

    &-date {
      font-size: 15px;
      font-weight: 500;
      @include themed() {
        color: t('color-font-text');
      }
    }

    &-author {
      display: flex;
      align-items: center;

      .user-avatar {
        width: 26px;
        min-width: 26px;
        height: 26px;
      }

      &-name {
        margin-left: 8px;
        font-size: 15px;
        font-weight: 500;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }
}
