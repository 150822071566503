@import 'styles/scss-variable.scss';

.align-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.no-integrations-image {
  max-width: 500px;
  max-height: 100%;
  width: auto;
  height: auto;
  padding-top: 20px;
  padding-bottom: 40px;
  object-fit: contain;
}

.integrations-welcome-image-container {
  margin: auto;
}

.integrations-welcome-image {
  width: 100%;
  height: auto;
  padding: 0px;
  min-height: calc(var(--vh, 1vh) * 100 - 187px);
  margin: auto;

  .dark,
  .githubicon {
    @include themed() {
      fill: t('color-font-title');
    }
  }
}

.integrations-wrap-grid-container {
  display: flex;
  flex-wrap: wrap;
}

.theme--dark {
  .integrations-welcome-image {
    path,
    polygon {
      fill: #fff;
    }
  }
}
