@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.send-input-editor-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: 20px;
  margin-top: 0px;
  padding: 15px;
  border-radius: $border-radius;
  box-shadow: 0 4px 8px 0 rgba(15, 22, 69, 0.1);
  font-size: $small-text-font-size;
  position: relative;
  
  @include themed() {
    color: t('color-font-title');
    background-color: t('color-bg');
    border: 1px solid t('border-color');
    font-size: $text-font-size;
  }

  @include for-mobile-tablet-only {
    width: calc(100% - 30px);
    margin: 15px;
    margin-top: 0px;
  }

  .currently-typing {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 4px;
    padding-right: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    @include themed() {
      border: 1px solid t('border-color');
    }

    .user-avatar {
      min-width: 20px;
      width: 20px;
      height: 20px;
      min-height: 20px;
      margin-right: 5px;
    }

    .currently-typing-text {
      font-size: 13px;
      @include themed() {
        color: t('color-font-text');
      }

      span {
        text-transform: capitalize;
        font-size: 13px;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }
  }

  &--sending {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include themed() {
      background-color: rgba($color: t('color-bg'), $alpha: 0.9);
    }

    .spring-spinner-small {
      margin-top: 4px;
      margin-left: 4px;
    }
  }

  &--note {
    @include themed() {
      background-color: t('note-bg');
    }
  }

  .editor-container {
    min-height: 50px;
    margin-top: 5px;

    p,
    p a,
    a {
      font-size: $small-text-font-size;

      @include for-mobile-tablet-only {
        font-size: $text-font-size !important;
      }
    }

    .ProseMirror pre {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .ProseMirror h2 {
      margin-bottom: 6px;
    }

    .ProseMirror h1 {
      margin-bottom: 6px;
    }

    .ProseMirror > p {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  &:focus-within {
    box-shadow: 0px 8px 38px rgba(0, 0, 0, 0.16);
  }

  .tab-buttons {
    display: flex;

    button:first-child {
      margin-left: 0px;
    }
  }

  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .send-button {
      border: none;
      font-size: $small-text-font-size;
      font-weight: $headline-semi-bold;
      height: 34px;
      line-height: 34px;
      padding: 0px 12px;
    }

    .attachment-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .attachment-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;

        > i {
          font-size: 18px;
          @include themed() {
            color: t('color-font-title');
          }
        }

        &:hover {
          > i {
            font-size: 18px;
            @include themed() {
              color: t('color-primary');
            }
          }
        }
      }
    }
  }
}
