@import 'styles/scss-variable.scss';

.seperator {
  margin-bottom: 12.5px;
  padding-bottom: 7.5px;
  margin-left: 12.5px;
  margin-right: 12.5px;
  @include themed() {
    border-bottom: 1px solid t('border-color-dark');
  }
}

.add-new-board-button {
  display: flex;
  align-items: center;
  border-radius: $input-border-radius;
  font-weight: 500;
  font-size: 13px;
  padding: 10px 10px;
  margin: 0px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-color: transparent !important;
  @include themed() {
    color: t('color-font-title');
  }

  i {
    @include themed() {
      color: t('color-font-title');
    }
  }

  &:hover {
    @include themed() {
      color: t('color-primary');
    }

    i {
      @include themed() {
        color: t('color-primary');
      }
    }
  }
}
