@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.helpcenter-localize-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.helpcenter-logo-upload {
  height: 150px !important;
  width: 280px !important;
}

.helpcenter-favicon-upload {
  height: 120px !important;
  width: 120px !important;
}

.footer-link-container {
  .footer-link-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include themed() {
      color: t('color-font-title');
    }

    .delete-item {
      cursor: pointer;
    }
  }
}
