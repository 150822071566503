@import 'styles/scss-variable.scss';

.condition-tag {
  position: relative;
  margin-right: 5px;

  &--inner {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    outline: none;
    padding: 0px 15px;
    cursor: pointer;
    border-radius: $input-border-radius;
    font-size: $text-font-size;
    border: 0px solid transparent !important;
    min-height: 38px;
    line-height: 38px;
    font-size: $medium-text-font-size;
    position: relative;
    @include themed() {
      background-color: t('color-light-gray');
      color: t('color-font-title');
      box-shadow: none !important;
      border: 0px solid t('color-light-gray') !important;
    }

    .condition-name {
      font-weight: 600;
      margin-right: 5px;

      i {
        margin-right: 10px;
      }
    }

    .condition-remove {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      min-height: 38px;
      line-height: 38px;
      padding: 0px 16px;
      padding-left: 40px;
      border-radius: 0px $input-border-radius $input-border-radius 0px;
      @include themed() {
        color: t('color-light');
        background: linear-gradient(
          90deg,
          transparent 0%,
          t('color-primary-light') 55%
        );
      }
    }

    &:hover {
      @include themed() {
        color: t('color-primary');
        background-color: t('color-primary-light');
      }

      .condition-remove {
        opacity: 1;
      }
    }
  }

  &-overlay {
    display: none;
    position: absolute;
    left: 0px;
    width: 280px;
    height: auto;
    padding: 20px;
    z-index: 1;
    border-radius: $input-border-radius;
    top: 45px;
    z-index: 999;
    box-shadow: rgba(12, 12, 12, 0.05) 0px 0px 0px 1px,
      rgba(12, 12, 12, 0.1) 0px 3px 6px, rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
    @include themed() {
      background-color: t('color-bg');
    }

    &--open {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    span {
      margin-left: 10px;
      font-size: $medium-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .bb-feedback-multiplechoice-checkmark:after {
    top: 4px !important;
    left: 4px !important;
  }
}

.type-editor-container {
  &-title {
    font-size: $small-text-font-size;
    font-weight: $headline-semi-bold;
    margin-bottom: 7px;
    text-align: start;
    @include themed() {
      color: t('color-font-title');
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    button {
      margin-top: 5px;
    }
  }
}

.condition-tag--invalid {
  .condition-tag--inner {
    @include themed() {
      background-color: t('color-red-light');
      color: t('color-red');
    }
    .condition-remove {
      @include themed() {
        background: linear-gradient(
          90deg,
          transparent 0%,
          t('color-red-light') 55%
        );
      }
    }
  }
}

.count-editor-container {
  &-title {
    font-size: $small-text-font-size;
    font-weight: $headline-semi-bold;
    margin-bottom: 7px;
    text-align: start;
    @include themed() {
      color: t('color-font-text');
    }
  }

  &-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    .text {
      @include themed() {
        color: t('color-font-title');
      }
    }

    .bb-feedback-multiplechoice-container {
      margin-top: 15px;
      margin-bottom: 0px;
      padding-left: 28px;
      line-height: 18px;
      min-height: 18px;

      &:first-of-type {
        margin-top: 5px;
      }

      .bb-feedback-multiplechoice-checkmark {
        margin-left: 0px;
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        box-sizing: border-box;

        &:after {
          top: 3px;
          left: 3px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }

    .react-date-picker {
      padding-left: 28px;
      margin-top: 5px;

      .react-date-picker__wrapper {
        @include themed() {
          border: 1px solid t('border-color');
        }

        .react-date-picker__calendar-button i {
          margin-right: 0px;
        }
      }
    }

    .field-container {
      padding-left: 28px;
      margin-top: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .textinput-gray {
        height: auto;
        padding: 10px;
        max-width: 120px;
      }

      span {
        font-size: $small-text-font-size;
        white-space: nowrap;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }
}
