@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';
@import '~video-react/styles/scss/video-react';

.replays-header {
  font-size: $text-font-size;
  font-weight: 600;
  line-height: 44px;
  padding: 0px 20px;
  @include themed() {
    color: t('color-font-title');
  }
}

.web-replay-container {
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex !important;
  flex-direction: row !important;

  .web-replays {
    width: 73%;
    height: 100%;
    @include themed() {
      background-color: t('color-light-gray');
    }

    @include for-mobile-tablet-only {
      width: 100%;
    }

    .web-replays-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 72px);
      background-color: #111;

      .iframe-container {
        width: calc(100% - 40px);
        position: relative;
        margin: 20px;
        border-radius: 5px;
        overflow: hidden;
        background-color: #111;

        iframe {
          background-color: #fff;
          transform-origin: 0 0;
          border: none;
          border-radius: 5px;
          pointer-events: none;
          overflow: hidden;
          @include themed() {
            box-shadow: 0px 0px 9.41177px rgba(0, 0, 0, 0.05),
              0px 0.784314px 2.35294px rgba(0, 0, 0, 0.1);
          }
        }

        .iframeoverlay {
          cursor: pointer;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          &--paused {
            background-color: rgba($color: #000000, $alpha: 0.2);
          }

          .play-icon {
            width: 70px;
            height: 70px;
            border-radius: 70px;
            background-color: #fff;
          }
        }
      }
    }

    .replay-container-bar {
      margin-top: 0px;
      padding: 20px;
      border-radius: 0px;
    }
  }
}

.replay-console-logs {
  width: 27%;
  height: 100%;

  @include for-mobile-tablet-only {
    display: none;
  }

  .log-tools {
    padding-top: 4px !important;
    padding-bottom: 5px !important;
    max-width: 100%;
    overflow-x: auto;
    overflow-x: auto;
  }

  .log-details {
    top: 100px !important;
    right: 50% !important;
    transform: translate(50%, 0%) !important;
  }

  .activity-log-container {
    height: calc(100% - 44px) !important;
  }

  .log-list {
    margin-top: 10px !important;
    max-height: calc(100% - 54px) !important;
  }
}

.replay-container {
  padding: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  background-color: #fff;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  border-radius: 7px;
  overflow-y: auto;

  @include themed() {
    background-color: t('color-bg');
  }

  &--bg {
    height: 100%;
    background-position: top;
    background-size: 700px, auto, contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    &--light {
      background-image: url('../../../assets/ReplaysBG.png');
    }

    &--dark {
      background-image: url('../../../assets/ReplaysDark.png');
    }
  }

  &--no-contents {
    padding: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;

    .no-replays {
      max-width: 100%;
      width: 500px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
      border-radius: $border-radius;
    }
  }

  .text {
    @include themed() {
      color: t('color-font-text');
    }
  }

  .replay-container-inner {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    width: 73% !important;
    height: 100%;

    @include for-mobile-tablet-only {
      width: 100% !important;
    }

    .replay-upper-frame-container {
      background-color: #111;
      height: calc(100% - 72px);
      display: flex;
      justify-content: center;
      align-items: center;

      .replay-frame-container {
        width: 100%;
        height: 100%;
        min-height: min(72vh, 800px);
        max-height: min(72vh, 800px);
        text-align: center;

        .reply-frame-screenname {
          text-align: center;
          font-size: $small-text-font-size;
          margin-top: 2px;
        }

        .replay-frame {
          display: inline-block;
          width: auto;
          height: auto;
          min-height: 100%;
          max-width: 100%;
          max-height: 100%;
          margin: 0px;
          padding: 0px;
          object-fit: cover;
          border-radius: 10px;
          @include themed() {
            box-shadow: 0px 0px 9.41177px rgba(0, 0, 0, 0.05),
              0px 0.784314px 2.35294px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

.replay-container-bar {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  @include themed() {
    background-color: t('color-light-gray');
  }

  .replay-container-slider {
    margin-left: 20px;
    width: 100%;

    .replay-container-slider-timer {
      display: flex;
      justify-content: space-between;

      & div {
        font-size: $small-text-font-size;
        @include themed() {
          color: t('color-font-text');
        }
      }

      & div:nth-child(1) {
        width: 20%;

        @include for-mobile-tablet-only {
          width: 100%;
        }
      }

      & div:nth-child(2) {
        text-align: center;
        width: 60%;
      }

      & div:nth-child(3) {
        text-align: right;
        width: 20%;
      }
    }
  }

  .rc-slider-handle {
    @include themed() {
      border: 1px solid t('color-font-text');
    }

    &:active {
      @include themed() {
        box-shadow: 0px 0px 7.41177px rgba(0, 0, 0, 0.08) !important;
      }
    }
  }

  .rc-slider-track {
    @include themed() {
      background-color: t('color-font-text');
    }
  }

  .replay-bar {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: $border-radius;
    margin-left: 20px;

    @include themed() {
      background-color: t('color-gray');
    }

    .replay-bar-bubble {
      width: 26px;
      height: 26px;
      border-radius: 26px;
      position: absolute;
      top: -8px;
      left: 50%;

      @include themed() {
        background-color: t('color-bg');
        box-shadow: 0px 0px 7.41177px rgba(0, 0, 0, 0.08);
        border: 1px solid t('color-light-gray');
      }
    }
  }

  .play-icon {
    cursor: pointer;
    opacity: 0.7;
    width: 32px;
    @include themed() {
      filter: t('image-filter');
    }

    &:hover {
      opacity: 1;
    }
  }
}
