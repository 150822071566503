@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.subscriber-list-container {
  width: 100%;
  height: calc(100% - 49.5px);
  border-radius: $border-radius;
  overflow: auto;
  padding: $scroll-padding;
  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    padding: 20px;
  }

  .subscriber-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 600;
      @include themed() {
        color: t('color-font-title');
      }
    }

    &-action-items {
      display: flex;
    }
  }

  .list-table {
    thead {
      display: none;
    }
  }
}
