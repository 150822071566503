@import 'styles/scss-variable.scss';

.invite-people {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;

  .textinput {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .pin-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .button {
    width: 50%;
  }

  .Dropdown-control {
    width: 80px;
    background-color: unset;
    margin-left: 5px;
    @include themed() {
      background: t('color-bg');
      color: t('color-font-text');
      border: 1px solid t('border-color');
    }
    border: unset;
    border-radius: $border-radius;
    letter-spacing: -0.02em;
    height: 40px;
    font-size: $small-text-font-size;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px;
    cursor: pointer;

    &:hover {
      @include themed() {
        background: t('color-gray');
        color: t('color-font-text');
        border: 1px solid t('border-color');
      }
    }
  }

  .Dropdown-menu {
    background-color: unset;
    @include themed() {
      background: t('color-bg');
      border: 1px solid t('border-color');
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    width: 80px;
    border: unset;
    margin-left: 5px;
    overflow: hidden;
    border-radius: $border-radius;
  }

  .Dropdown-option {
    @include themed() {
      color: t('color-font-text');
    }
    border-radius: $border-radius !important;
    width: 100px;
    font-size: $small-text-font-size;
  }

  .Dropdown-option.is-selected {
    background-color: unset;
    @include themed() {
      background: t('color-light-gray');
      color: t('color-font-text');
    }
  }

  .Dropdown-option:hover {
    @include themed() {
      background-color: t('color-gray');
    }
  }

  .Dropdown-placeholder {
    font-size: $small-text-font-size;
  }

  .icons {
    width: 10px;
    height: auto;
    object-fit: contain;
    @include themed() {
      filter: t('image-filter');
    }
  }
}

.headline {
  margin-bottom: 20px;
}

.invitatin-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 10px;

  .text-input {
    max-width: 280px;
  }

  .icon {
    margin-right: 12px;
    height: 26px;
    padding-top: 15px;
    font-weight: 600;
    object-fit: contain;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.button-success {
  margin-top: 20px;
}
