@import 'styles/scss-variable.scss';

.feedback-action-questions-container-addoptions {
  position: absolute;
  top: 14px;
  right: 10px;
  transform: translateX(100%);
  z-index: 99;
  border-radius: $border-radius;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  padding: 10px;
  @include themed() {
    background-color: t('color-card-bg');
    border: 1px solid t('border-color');
  }

  &-item {
    cursor: pointer;
    display: flex;
    margin-bottom: 2px;
    border-radius: $input-border-radius;
    padding: 9px 11px;
    padding-right: 30px;

    &:hover {
      @include themed() {
        background-color: t('color-light-gray');
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }

    &-icon {
      width: 30px;
      height: 30px;
      margin: 0px;
      margin-right: 10px;
      border-radius: 8px;

      svg {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: $medium-text-font-size;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}
