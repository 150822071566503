.helpcenter-collection-modal {
  width: 100%;
  display: flex;
  flex-direction: column;

  .options-group {
    padding: 50px;
  }

  .helpcenter-form-group {
    width: 100%;
    margin-bottom: 0px !important;

    .options-group-header {
      margin-top: -50px;
      margin-left: -50px;
      margin-right: -50px;
      margin-bottom: 50px;
    }
  }
}

.helpcenter-collection-icon {
  width: 100px !important;
  height: 100px !important;
  border-radius: 12px !important;

  .image {
    padding: 0px;
    border-radius: 12px !important;

    img {
      object-fit: cover;
      border-radius: 12px !important;
    }
  }
}

.buttons-side-by-side {
  display: flex;
}

.helpcenter-form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.helpcenter-form-group {
  margin-bottom: 0px;
}
