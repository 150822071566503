@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';
@import url('https://use.typekit.net/xsk2vnz.css');

.shared-news-details {
  max-width: 700px;
  margin: auto;
  margin-bottom: 0px;
  margin-top: 20px;

  @include for-phone-only {
    margin-top: 0px;
  }

  .news-detail-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 30px;

    @include for-phone-only {
      display: none;
    }

    i {
      color: var(--shared-text-color);
      font-size: 13px;
      font-weight: 500;
      padding-left: 8px;
      padding-right: 8px;
    }

    &-breadcrumbs {
      color: var(--shared-text-color);
      font-size: 15px;
      font-weight: 500;
      line-height: 1.5;

      &--active {
        cursor: pointer;
        color: var(--shared-title-color);

        &:hover {
          color: var(--shared-color);
        }
      }
    }
  }

  .news-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: var(--shared-background-color);

    .news-fake-image-loading > span {
      display: flex;

      .react-loading-skeleton {
        @include for-phone-only {
          height: 200px !important;
          border-radius: 0px !important;
        }
      }
    }

    &--image {
      background-color: var(--shared-light-gray-color);
      height: 360px;
      object-fit: cover;
      width: 100%;
      border-radius: 12px;

      @include for-phone-only {
        height: 200px;
        border-radius: 0px;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      display: flex;
      padding: 30px;
      padding-bottom: 0px;

      &--body {
        margin-top: 35px;
        margin-bottom: 0px;

        > :first-child {
          margin-top: 0px !important;
        }

        a,
        p,
        span {
          color: var(--shared-title-color);
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-word-break: break-all;
          word-break: break-all;
          word-break: break-word;
          font-family: proxima-nova, 'Helvetica Neue', system-ui, 'Segoe UI',
            Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
            'Segoe UI Emoji', 'Segoe UI Symbol';
        }

        a {
          color: var(--primary-color);
          text-decoration: underline;
        }

        ul {
          padding-left: 15px;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .linktype-button {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 500px;
          object-fit: contain;
        }

        h1 {
          margin-top: 30px;
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 19px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--shared-title-color);

          span {
            font-size: 19px;
            line-height: 1.4;
            font-weight: 500;
            color: var(--shared-title-color);
          }
        }

        h2 {
          margin-top: 25px;
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 17px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--shared-title-color);

          span {
            font-size: 17px;
            line-height: 1.4;
            font-weight: 500;
            color: var(--shared-title-color);
          }
        }

        iframe {
          width: 100%;
          border: 0px !important;
          height: 300px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .iframe-wrapper {
          position: relative;
          padding-bottom: 62.5%;
          height: 0;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.4;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        pre {
          background: #0d0d0d;
          border-radius: 0.5rem;
          color: #fff;
          font-family: 'JetBrainsMono', monospace;
          padding: 0.75rem 1rem;
          font-size: 14px;
          line-height: 18px;
          max-width: 100%;
          overflow: auto;
          margin-top: 20px;
          margin-bottom: 20px;

          code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
            font-family: 'JetBrainsMono', monospace;
          }
        }
      }
    }

    &--title {
      color: var(--shared-title-color);
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 1.4;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &--meta {
      display: flex;
      align-items: center;
      color: var(--shared-text-color);
      font-size: 16px;
      font-weight: 400;

      span {
        color: var(--shared-text-color);
        font-size: 16px;
        font-weight: 400;
      }

      img {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 8px;
      }
    }
  }
}

.shared-news-details-container {
  max-height: calc(100vh - 76px);
  overflow: auto;

  @include for-phone-only {
    max-height: calc(100vh - 97px);
  }
}
