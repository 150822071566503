@import 'styles/scss-variable.scss';

.plan-info {
  width: 100%;
}

.plan-type {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include themed() {
    color: t('color-font-title');
  }

  .best-value-container {
    margin-left: 5px;
    color: #ffffff;
    font-weight: bold;
    border-radius: 14px;
    font-size: 10px;
    padding-left: 6px;
    padding-right: 6px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themed() {
      background-color: t('color-green');
    }
  }
}

.plan-price {
  font-size: $small-text-font-size;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include themed() {
    color: t('color-font-title');
  }

  &-monthly {
    font-size: $small-text-font-size;
    margin-left: 0px;

    @include themed() {
      color: t('color-font-text');
    }
  }
}

.plan-info-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .checkmark-icon {
    @include themed() {
      color: t('color-green');
    }
  }

  .plan-info-text {
    margin-left: 10px;
    font-size: $text-font-size;

    @include themed() {
      color: t('color-font-text');
    }
  }
}

.price-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .price-to-pay {
    font-size: $small-text-font-size;

    @include themed() {
      color: t('color-font-text');
    }
  }

  .price-to-pay-today {
    font-size: 17px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include themed() {
      color: t('color-font-title');
    }

    .trial-length-text {
      font-weight: bold;
      font-size: 16px;
      margin-left: 3px;

      @include themed() {
        color: t('color-green');
      }
    }
  }
}

.checkout-loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
