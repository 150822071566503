@import 'styles/scss-variable.scss';

.widget-app-settings-modal {
  .widget-app-card {
    border: none !important;
    box-shadow: none !important;
    padding: 0px;
  }

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}