@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.bugboard {
  padding: 0px;
  min-height: 100%;
  height: calc(var(--vh, 1vh) * 100 - 64px - 12px);
  

  @include for-mobile-tablet-only {
    height: calc(var(--vh, 1vh) * 100 - $mobile-nav-height - 64px - 4px);
    width: 100vw;
  }

  * {
    outline: none;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .react-trello-board {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px;
    overflow: auto;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);

    &::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }
    &::-webkit-scrollbar-button:end:increment,
    &::-webkit-scrollbar-button:start:decrement {
      background: #0000;
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      @include themed() {
        background-color: t('color-light-gray');
      }
    }
    &::-webkit-scrollbar-track-piece:horizontal:start {
      border-radius: 4px 0 0 4px;
      margin-left: 15px;
    }
    &::-webkit-scrollbar-track-piece:horizontal:end {
      border-radius: 0 4px 4px 0;
      margin-right: 15px;
    }
    &::-webkit-scrollbar-thumb:horizontal {
      border-radius: 4px;
      display: block;
      padding-bottom: 10px;
      @include themed() {
        background-color: t('color-dark-gray');
      }
    }

    .react-trello-lane {
      height: calc(100% - 20px);
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 8px;
      padding-top: 4px;
      border-radius: 8px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      @include themed() {
        background-color: t('color-light-gray');
      }

      .ReactVirtualized__List {
        -webkit-transform: translateZ(0);
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }
        &::-webkit-scrollbar-button:end:increment,
        &::-webkit-scrollbar-button:start:decrement {
          background: #0000;
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          @include themed() {
            background-color: t('color-gray');
          }
        }
        &::-webkit-scrollbar-track-piece:vertical:start {
          border-radius: 4px 4px 0 0;
        }
        &::-webkit-scrollbar-track-piece:vertical:end {
          border-radius: 0 0 4px 4px;
        }
        &::-webkit-scrollbar-track-piece:horizontal:start {
          border-radius: 4px 0 0 4px;
        }
        &::-webkit-scrollbar-track-piece:horizontal:end {
          border-radius: 0 4px 4px 0;
        }
        &::-webkit-scrollbar-thumb:horizontal,
        &::-webkit-scrollbar-thumb:vertical {
          border-radius: 4px;
          display: block;
          height: 48px;
          @include themed() {
            background-color: t('color-dark-gray');
          }
        }
      }
    }
  }
}

.drop-indicator {
  position: absolute;
  top: 0px;
  left: 4px;
  width: 255px;
  min-height: 107px;
  z-index: 99;
  border-radius: 6px;
  transition: border 1s;
  @include themed() {
    border: 1px dashed t('color-dark-gray');
  }
}
