@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.field-container {
  width: 100%;

  .input-required {
    @include themed() {
      color: t('color-red');
    }
  }

  .input-icon {
    width: 18px;
    font-size: 16px;
    position: absolute;
    top: 13px;
    left: 12px;
    z-index: 999;

    &,
    path {
      @include themed() {
        fill: t('color-font-text');
      }
    }
  }

  &:focus-within {
    .input-icon {
      &,
      path {
        @include themed() {
          fill: t('color-font-title');
        }
      }
    }
  }

  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .textinput-gray {
      height: 40px;
      box-sizing: border-box;
      letter-spacing: -0.02em;
      padding: 12px 12px;
      flex: 1;
      border: 0 solid;
      font-size: $small-text-font-size;
      border-radius: $input-border-radius;
      @include themed() {
        border: 1px solid t('border-color');
        color: t('color-font-title');
        background-color: t('color-bg');
      }

      @include for-mobile-tablet-only {
        font-size: $text-font-size;
      }
    }

    .textinput-gray:read-only {
      @include themed() {
        color: t('color-font-text');
        background-color: t('color-bg');
      }
      cursor: not-allowed;
    }

    .textinput-error {
      @include themed() {
        border: 1px solid t('color-red');
      }
    }

    .textinput-gray:focus {
      outline-width: 0;

      @include themed() {
        border: 1px solid t('color-primary-black');
      }
    }

    .edit {
      position: absolute;
      right: 0;
      right: 12px;
      top: 14px;
      @include themed() {
        color: t('color-primary');
      }
      font-size: $small-text-font-size;
      cursor: pointer;
    }
  }

  .label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .language-tag {
      padding: 3px 8px;
      border-top-left-radius: $input-border-radius;
      border-top-right-radius: $input-border-radius;
      font-size: 13px;
      line-height: 19px;
      font-weight: 600;
      cursor: pointer;

      @include themed() {
        background-color: t('border-color');
        color: t('color-font-title');
      }

      &:hover {
        @include themed() {
          background-color: t('color-primary');
          color: t('button-color');
        }
      }
    }

    .input-label {
      @include themed() {
        color: t('color-font-title');
      }
      font-size: 15px;
      font-weight: $headline-semi-bold;
      margin-bottom: 5px;

      .input-required {
        @include themed() {
          color: t('color-red');
        }
      }
    }
  }

  .input-description {
    margin-top: 3px;
    margin-bottom: 10px;
    font-size: $small-text-font-size;
    @include themed() {
      color: t('color-font-text');
    }

    span,
    span a {
      font-size: $small-text-font-size;
    }
  }

  &--icon {
    .textinput-gray {
      padding-left: 35px !important;
    }
  }

  &--pretext {
    .label-container {
      position: relative;
    }

    .textinput-gray {
      padding-left: 60px !important;
    }

    .input-pre-text {
      position: absolute;
      z-index: 11;
      font-size: 12px;
      font-weight: 600;
      padding: 5px;
      top: 31px;
      left: 9px;
      border-radius: 6px;

      @include themed() {
        background-color: t('color-primary-black');
        color: t('color-primary-inverted');
      }
    }
  }

  &--small {
    .textinput-gray {
      padding: 0px 8px !important;
      padding-left: 32px !important;
      min-height: auto !important;
      height: 35px !important;
    }

    .input-icon {
      width: 14px;
      font-size: 14px;
      top: 11px;
      left: 12px;
      z-index: 999;
    }
  }

  &--translateable {
    .textinput-gray {
      border-top-right-radius: 0px !important;
    }
  }
}

.pac-item {
  padding: 14px;
  font-size: 14px;
}

.error-message {
  @include themed() {
    color: t('color-red');
  }
  font-size: $small-text-font-size;
  min-height: 16px;
  margin-top: 2px;
}
