@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feedback-action-summary-item-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px;
  margin-bottom: 40px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  @include themed() {
    border: 1px solid t('border-color');
  }

  @include for-mobile-tablet-only {
    padding: 25px;
    margin-bottom: 20px;
  }

  & .header {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    font-size: $text-font-size;
    font-weight: $headline-semi-bold;
    text-align: start;
    @include themed() {
      color: t('color-font-title');
      border-bottom: 1px solid t('border-color');
    }

    .title {
      text-align: left;
      @include themed() {
        color: t('color-font-title') !important;
      }

      @include for-mobile-tablet-only {
        text-align: center;
        line-height: 23px;
        margin-bottom: 5px;
      }
    }

    .type {
      @include themed() {
        color: t('color-font-text') !important;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .chart-wrapper {
    width: 100%;
  }

  .nps-wrapper {
    margin: 0px;

    .apexcharts-pie-label,
    .apexcharts-datalabels,
    .apexcharts-datalabel,
    .apexcharts-datalabel-label,
    .apexcharts-datalabel-value {
      font-weight: bold;
      @include themed() {
        fill: t('color-font-title') !important;
      }
    }

    .nps-overview {
      margin-bottom: 20px;
      position: relative;

      @media screen and (max-width: 600px) {
        .apexcharts-canvas {
          width: 100% !important;
          height: auto;

          svg {
            width: 100% !important;
            height: auto;
          }
        }
      }

      .nps-score {
        width: 194px;
        height: 194px;
        position: absolute;
        top: 10px;
        left: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 600px) {
          width: 100%;
          height: 100%;
          left: 0px;
        }

        &-value {
          font-weight: 600;
          font-size: 36px;
          @include themed() {
            color: t('color-font-title');
          }
        }

        &-label {
          margin-top: 0px;
          font-weight: $headline-semi-bold;
          font-size: $small-text-font-size;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }

    .nps-info {
      font-weight: 600;
      font-size: 13px;
      margin-top: 20px;
      margin-bottom: 20px;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .nps-bubble-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }

      .nps-bubble {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 12px;

        &--promoters {
          .nps-bubble-circle {
            background-color: #19b09711;
            border-color: #19b097;
            color: #19b097;
          }
        }

        &--passives {
          .nps-bubble-circle {
            background-color: #f4ba5611;
            border-color: #f4ba56;
            color: #f4ba56;
          }
        }

        &--detractors {
          .nps-bubble-circle {
            background-color: #ea555a11;
            border-color: #ea555a;
            color: #ea555a;
          }
        }

        &-circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          font-weight: $headline-semi-bold;
          font-size: 13px;
          color: #fff;
          border-style: solid;
          border-width: 2px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 600px) {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-top: 20px;
          }
        }

        &-label {
          margin-top: 5px;
          font-weight: $headline-semi-bold;
          font-size: 12px;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .apexcharts-legend-marker,
  .apexcharts-legend-text,
  .apexcharts-datalabels {
    display: none !important;
  }
}

.apexcharts-legend-series {
  cursor: pointer;
  line-height: normal;
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.apexcharts-legend-marker {
  margin-right: 5px !important;
}

.apexcharts-text.apexcharts-pie-label {
  font-size: 12px;
}

.apexcharts-series path {
  @include themed() {
    stroke: t('color-bg');
  }
}

.apexcharts-legend-text {
  @include themed() {
    color: t('color-font-text') !important;
  }
}
