@import 'styles/scss-variable.scss';

.board-sort-filter-dropdown {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.board-sort-filter {
  position: relative;
  margin-right: 10px;
  
  &-selection {
    position: absolute;
    top: 41px;
    right: 0px;
    min-width: 200px;
    z-index: 9999;
    padding: 8px;
    border-radius: $border-radius;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    @include themed() {
      background-color: t('color-card-bg');
      border: 1px solid t('border-color');
    }
  }

  &-button {
    display: flex;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    height: auto;
    min-height: auto;
    border-radius: $input-border-radius;
    padding: 7px 14px;
    font-style: normal;
    text-align: center;

    cursor: pointer;
    @include themed() {
      color: t('color-font-title');
      background-color: t('color-light-gray');

      span {
        color: t('color-font-title');
        font-weight: $headline-semi-bold;
        font-size: $medium-text-font-size;
        padding-left: 6px;
        white-space: nowrap;
      }

      i {
        color: t('color-font-title');
        font-size: $medium-text-font-size;
      }
    }

    &:hover {
      @include themed() {
        background-color: t('color-primary-light');
        color: t('color-primary');

        i, span {
          color: t('color-primary');
        }
      }
    }
  }
}

.board-sort-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: $small-text-font-size;
  max-width: 100%;

  span {
    display: block;
    margin-left: 4px;
    font-size: $small-text-font-size;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include themed() {
      color: t('color-font-title');
    }
  }
}
