@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.inbox-ticket-status-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: $small-text-font-size;

  i {
    font-size: 16px;
    margin-left: 4px;
    margin-right: 10px;
  }

  .status-title {
    flex-grow: 1;
    font-size: 14px;
    line-height: 14px;
  }

  .status-count {
    font-size: 13px;
    line-height: 14px;
    margin-left: 4px;

    @include themed() {
      color: t('color-font-text');
    }
  }
}

.inbox-ticket-status-dropdown {
  margin-bottom: 0px !important;
  margin-right: 10px;

  .dropdown-selection__menu {
    min-width: 114px !important;
  }

  .dropdown-selection__control {
    min-width: auto !important;
  }

  .dropdown-selection__control {
    min-height: 34px;
  }
}
