@import 'styles/scss-variable.scss';

.macro-container {
  .macro-list {
    position: absolute;
    bottom: 56px;
    left: 30px;
    right: 30px;
    padding: 0px;
    width: auto;
    border-radius: $border-radius;
    box-shadow: 0 4px 40px 0 rgba(15, 22, 69, 0.3);

    @include themed() {
      color: t('color-font-title');
      background-color: t('color-bg');
      border: 1px solid t('border-color');
      font-size: $text-font-size;
    }

    &-list {
      padding: 10px 0px;
      max-height: 45vh;
      overflow-y: auto;
    }

    &-footer {
      padding: 8px 15px;
      display: flex;
      align-items: center;

      @include themed() {
        color: t('color-font-text');
        border-top: 1px solid t('border-color');
      }

      .hotkeys-list {
        display: flex;
        align-items: center;
        opacity: 0.7;

        .hotkeys-list-label {
          font-size: 14px;
          padding-left: 6px;
          padding-right: 20px;

          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 18px 15px;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
        border-bottom: 1px solid t('border-color');
      }
    }

    &-item {
      padding: 5px;
      cursor: pointer;
      border-radius: $input-border-radius;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      margin: 0px 10px;

      &.focused {
        @include themed() {
          background-color: t('color-gray');
        }
      }

      &-shortcut {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        text-align: center;
        @include themed() {
          color: t('color-primary-inverted');
          background-color: t('color-primary-black');
          border: 1px solid t('color-primary-black');
        }
      }

      &-shortcut-content {
        padding: 0px 10px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }
  }

  .macro-container-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    min-height: 20px;
    padding: 3px 7px;
    margin-right: 3px;
    @include themed() {
      color: t('color-font-title');
    }

    i {
      cursor: pointer;
      font-size: 18px;
      margin-left: 4px;
      margin-right: 2px;
      @include themed() {
        color: t('color-font-title');
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
        }
      }
    }

    &--active {
      i {
        @include themed() {
          color: t('color-primary');
        }
      }
    }
  }
}
