.react-flow__attribution a {
  font-size: 10px !important;
}

path.react-flow__edge-path {
  stroke-width: 2;
}

.react-flow__edges .react-flow__arrowhead polyline {
  stroke-width: 2;
}

.flow-chart-bot {
  .left-align-wrapper {
    width: auto !important;
    margin-right: 15px;
  }

  .select-container {
    min-width: 180px;
  }

  .page-content--inner {
    height: 100%;
    padding: 0px;
  }
}

.max-width-300 {
  max-width: 240px;
}
