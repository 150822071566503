@import 'styles/scss-variable.scss';

.primary-button {
  height: 40px;
  line-height: 40px;
  width: auto;
  padding: 0px 20px;
  border-radius: $input-border-radius;
  border: unset;
  font-style: normal;
  font-weight: $headline-semi-bold;
  font-size: $text-font-size;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  white-space: nowrap;
  @include themed() {
    background-color: t('color-primary-black');
    color: t('color-primary-inverted');
  }

  .icon {
    font-size: 14px;
    margin-bottom: 0px;
    margin-right: 10px;
  }

  .loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    @include themed() {
      background: t('color-primary-black-light');
      color: t('color-primary-inverted-light');
    }
  }

  &.dark {
    @include themed() {
      border: 1px solid #ffffff33;
      color: t('button-highlight-color');
    }

    &:hover {
      @include themed() {
        border: 1px solid t('color-primary-highlight');
        background: t('color-primary-highlight');
      }
    }
  }

  &.secondary {
    @include themed() {
      border: 1px solid t('color-secondary');
      background-color: t('color-secondary');
      color: #060d25;
    }

    &:hover {
      @include themed() {
        border: 1px solid t('color-secondary-highlight');
        background-color: t('color-secondary-highlight');
        color: #060d25;
      }
    }
  }

  &.danger {
    @include themed() {
      color: t('color-red');
      background-color: t('color-red-light');

      i {
        color: t('color-red');
      }
    }
  }

  &.live {
    @include themed() {
      border: 1px solid t('color-green-light');
      background-color: t('color-green-light');
      color: t('color-green');
    }

    &:hover {
      @include themed() {
        border: 1px solid t('color-green');
        background-color: t('color-green');
        color: t('color-green-light');
      }
    }
  }

  &--full-width {
    width: 100%;
  }

  &--icon-right {
    .icon {
      margin-left: 10px;
      margin-right: 0px;
    }
  }

  &--loading,
  &--loading:hover {
    cursor: progress;
    @include themed() {
      background-color: t('color-primary-black');
      color: t('color-primary-inverted');
    }

    .spring-spinner-small .spring-spinner-small-rotator {
      @include themed() {
        border-right-color: t('color-primary-inverted');
        border-top-color: t('color-primary-inverted');
      }
    }
  }

  &--disabled {
    @include themed() {
      background-color: t('color-gray') !important;
      color: t('color-font-text') !important;
      box-shadow: none !important;
    }
    cursor: not-allowed;
  }

  &--small {
    padding: 3px 14px;
    line-height: 20px;
    height: auto;
    min-height: auto;
    font-size: 14px;
    min-height: 36px;
  }
}

.primary-button:focus {
  outline: none;
  border: none;
}
