@import 'styles/scss-variable.scss';

.footer {
  @include themed() {
    border-bottom: 1px solid t('border-color');
  }
}

.profile-dropup-menu {
  cursor: pointer;
  padding: 10px;
  padding-top: 10px;

  .dropdown {
    width: 100%;
  }

  .line-container {
    display: flex;
    padding: 0 5px;
    @include themed() {
      background-color: t('color-bg');
    }

    .line {
      @include themed() {
        background-color: t('color-bg');
      }
    }
  }

  .trigger-container-main {
    display: flex;
    justify-content: space-between;
    width: auto;
    padding: 0px;
    border-radius: 100%;

    &.active,
    &:hover {
      @include themed() {
        background-color: t('color-gray');
      }
    }

    .trigger {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: $headline-semi-bold;
      overflow: hidden;
      @include themed() {
        color: t('color-font-text');
      }

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }

  .menu {
    width: 240px;
    border-radius: $border-radius;
    padding: 0px;
    left: auto;
    margin-bottom: 5px;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    @include themed() {
      background-color: t('dropdown-bg');
      border: 1px solid t('border-color-dark');
    }
  }

  .heading {
    margin-bottom: 10px;
    max-width: 100%;
    padding: 0px 10px !important;
    padding-top: 15px !important;
    cursor: default;

    .menu-title {
      font-weight: $headline-semi-bold;
      padding-left: 5px;
      padding-right: 5px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .menu-item-container {
    margin: 0px;
    padding: 10px 15px;
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 0px;

    &:last-of-type {
      border-bottom: none;
    }

    img {
      width: 13px;
      opacity: 0.4;
    }

    > div {
      border: 0;
      outline: 0;
    }
  }

  .menu-item-container:hover {
    .menu-item {
      @include themed() {
        color: t('color-primary');
      }
    }

    svg {
      opacity: 1;
      @include themed() {
        fill: t('color-primary');
      }

      path,
      g {
        @include themed() {
          fill: t('color-primary');
        }
      }
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    font-weight: $headline-semi-bold;
    cursor: pointer;
    @include themed() {
      color: t('color-font-title');
    }

    svg {
      width: 17px;
      height: 24px;
      margin-right: 15px;
      opacity: 1;
      @include themed() {
        fill: t('color-font-title');
      }

      path,
      g {
        @include themed() {
          fill: t('color-font-title');
        }
      }
    }
  }

  .menu-item:active {
    border: 0;
    outline: 0;

    svg {
      opacity: 1;
      @include themed() {
        fill: t('color-primary');
      }

      path,
      g {
        @include themed() {
          fill: t('color-primary');
        }
      }
    }
  }
}
