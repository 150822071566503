@import 'styles/scss-variable.scss';

.items {
  border-radius: $border-radius;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  font-size: $medium-text-font-size;
  overflow: hidden;
  padding: 5px;
  position: relative;

  @include themed() {
    color: t('color-font-text');
    background: t('color-bg');
  }

  :not(:last-child) {
    margin-bottom: 3px !important;
  }
}

.item {
  background: transparent;
  border: 1px solid transparent;
  border-radius: $input-border-radius;
  display: block;
  margin: 0;
  padding: 6px 12px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  @include themed() {
    color: t('color-font-title');
  }

  .user-avatar {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 100%;
    font-size: 12px;
    margin-right: 5px;
  }

  &.is-selected {
    @include themed() {
      background-color: t('color-light-gray') !important;
    }
  }

  &:hover {
    @include themed() {
      background-color: t('color-light-gray') !important;
    }
  }
}
