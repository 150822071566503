@import 'styles/scss-variable.scss';

.suggest-subscription-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    height: 40px;
  }

  .description {
    text-align: center;
    line-height: 1.3;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
