@import '../../styles/scss-variable.scss';

.spring-spinner-medium,
.spring-spinner-medium * {
  box-sizing: border-box;
}

.spring-spinner-medium {
  height: 34px;
  width: 34px;
}

.spring-spinner-medium .spring-spinner-medium-part {
  overflow: hidden;
  height: calc(34px / 2);
  width: 34px;
}

.spring-spinner-medium .spring-spinner-medium-part.bottom {
  transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner-medium .spring-spinner-medium-rotator {
  @include themed() {
    border-right-color: t('color-primary-black');
    border-top-color: t('color-primary-black');
  }
  width: 34px;
  height: 34px;
  border: calc(34px / 7) solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spring-spinner-medium-animation 3s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes spring-spinner-medium-animation {
  0% {
    border-width: calc(34px / 7);
  }
  25% {
    border-width: calc(34px / 23.33);
  }
  50% {
    transform: rotate(115deg);
    border-width: calc(34px / 7);
  }
  75% {
    border-width: calc(34px / 23.33);
  }
  100% {
    border-width: calc(34px / 7);
  }
}
