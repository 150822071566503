@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.feature-request-container-comments {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;

  @include for-phone-only {
    padding-bottom: 0px;
  }

  .comment-composer {
    width: 100%;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
    border-radius: $border-radius;
    @include themed() {
      color: t('color-font-text');
      box-shadow: none;
    }

    .textinput-gray {
      height: auto;
      padding: 12px 12px;
      border-radius: 10px;
      border: 1px solid #dee1ea;
      font-size: $medium-text-font-size;
      @include themed() {
        background-color: t('color-bg') !important;
      }

      @include for-mobile-tablet-only {
        font-size: $text-font-size;
      }
    }

    .shared-email-input:focus {
      border-color: #dee1ea !important;
    }

    .commenting-as,
    .commenting-as a {
      font-size: $small-text-font-size;
      padding-bottom: 12px;
    }

    .send-input-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      padding: 12px 12px;
      border-radius: 10px;
      border: 1px solid #dee1ea;
      font-size: $medium-text-font-size;
      @include themed() {
        color: t('color-font-text');
        background-color: t('color-bg') !important;
      }

      .spring-spinner {
        transform: scale(0.7);
      }

      .send-input {
        resize: none;
        min-height: 80px;
        width: 100%;
        border: none;
        outline: none;
        padding: 0px;
        font-size: $medium-text-font-size;
        background-color: transparent;
        max-height: 200px;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .send-button {
        padding: 8px 14px;
        border-radius: 33px;
        border: none;
        font-size: $small-text-font-size;
        font-weight: $headline-semi-bold;
        cursor: pointer;
        @include themed() {
          background-color: t('color-primary');
          color: t('button-color');
        }

        &:focus {
          outline: none;
        }

        &:active {
          box-shadow: $inner-shadow;
        }

        &:hover {
          @include themed() {
            color: t('button-highlight-color');
            background: t('color-primary-highlight');
          }
        }

        &--disabled {
          cursor: not-allowed;
          @include themed() {
            background-color: t('color-gray') !important;
            color: t('color-font-text') !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .comments-container {
    width: 100%;
    height: auto !important;
    overflow: auto;
    padding: 0px 70px;
    padding-top: 60px;
    padding-bottom: 120px;

    @include for-tablet-only {
      padding: 50px;
      padding-top: 60px;
      padding-bottom: 70px;
    }

    @include for-phone-only {
      padding: 30px;
      padding-bottom: 90px;
    }

    .comment {
      .comment-body {
        flex-direction: row;

        .body {
          max-width: calc(100% - 38px);
          margin-right: 0px;
          @include themed() {
            background-color: t('color-ultra-light-gray') !important;
          }

          .comment-text {
            @include themed() {
              color: t('color-font-title') !important;
            }

            .newlinetext {
              @include themed() {
                color: t('color-font-title') !important;
              }
            }

            a,
            .mention {
              @include themed() {
                color: t('color-font-title') !important;
              }
            }

            svg {
              @include themed() {
                fill: t('color-font-title') !important;
              }
            }
          }
        }
      }

      .comment-footer {
        justify-content: flex-start;
      }
    }

    .no-comments {
      padding: $scroll-padding;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include for-phone-only {
        padding: 20px;
      }

      .empty-image {
        margin: auto;
        opacity: 0.7;
        width: 100%;
        max-width: 340px;
        margin-bottom: 10px;
        @include for-phone-only {
          display: none;
        }
      }
    }

    .comments-title {
      font-weight: 600;
      font-size: 15px;
      margin-top: 35px;
      margin-bottom: 10px;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .loading-comments-container {
      .comment {
        .comment-footer {
          justify-content: flex-end;
          padding: 0px !important;
          padding-top: 10px !important;
        }
      }
    }

    .feature-request-comments-container {
      padding: 10px 0px;
    }

    .static-comments {
      padding: 0px;
      border-radius: $border-radius;
      margin-bottom: 20px;
      background-color: transparent !important;

      .ProseMirror {
        margin-top: 20px;
        max-height: none !important;
      }

      &-description,
      &-description span {
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 450;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .comments-created-at {
        font-size: $medium-text-font-size;
        font-weight: 450;
        opacity: 0.5;
        @include themed() {
          color: t('color-font-text');
        }
      }

      &-status {
        font-size: 12px;
        font-weight: 400;
        width: auto;
        padding: 4px 8px;
        margin-right: 10px;
        border-radius: 6px;
        border-width: 1px;
        border-style: solid;
      }

      &-tag {
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
        width: auto;
        @include themed() {
          color: t('color-primary');
        }
      }

      &-statustags {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 4px;
      }

      &-eta {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 600;
        
        span {
          font-weight: 400;
        }
      }
    }
  }
}
