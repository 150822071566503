@import 'styles/scss-variable.scss';

.integrations-wrap-grid-container-forward {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.no-integrations-container {
  border-radius: $border-radius;
  padding-bottom: $scroll-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .no-surveys-image {
    max-width: 460px;
    max-height: 100%;
    width: auto;
    height: auto;
    padding-top: 20px;
    padding-bottom: 40px;
    object-fit: contain;
  }
}
