@import 'styles/scss-variable.scss';

.email-settings {
  .title {
    text-align: left;
  }
}

.domain-verified-badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  i {
    margin-right: 8px;
    font-size: 22px;
    @include themed() {
      color: t('color-green');
    }
  }

  font-size: 16px;
  @include themed() {
    color: t('color-font-title');
  }
}
