@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.organisation-onboarding {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: min(590px, 80vw);
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.08);
  min-height: 0px;
  padding: 50px;
  border-radius: $border-radius;
  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 30px;
    border-radius: 0px;
  }

  .textinput-gray {
    border-width: 2px !important;
  }
}
