@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feedback-text-answer {
  padding-bottom: 20px;
  display: flex;

  &-nps {
    margin-bottom: 5px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    font-weight: $headline-semi-bold;
    font-size: 13px;
    color: #fff;
    border-style: solid;
    border-width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 20px;

    &--promoters {
      background-color: #19b09711;
      border-color: #19b097;
      color: #19b097;
    }

    &--passives {
      background-color: #f4ba5611;
      border-color: #f4ba56;
      color: #f4ba56;
    }

    &--detractors {
      background-color: #ea555a11;
      border-color: #ea555a;
      color: #ea555a;
    }
  }

  &-maincontent {
    flex-grow: 1;
  }

  &-content {
    font-size: 15px;
    line-height: 22px;
    @include themed() {
      color: t('color-font-title');
    }
  }

  &-date {
    margin-top: 5px;
    font-size: 12px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  &-open-details {
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  &--has-nps {
    padding-bottom: 30px;

    .feedback-text-answer-maincontent {
      padding-top: 5px;
    }
  }
}
