@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.mainnavbar {
  width: $mnl-nav;
  min-width: $mnl-nav;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  @include themed() {
    background-color: t('color-gray');
  }

  @include for-mobile-tablet-only {
    display: none;
  }

  &--header {
    margin-top: 19px;
    margin-bottom: 14px;
  }

  .project-option {
    padding: 0px !important;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    .image-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      @include themed() {
        background: t('color-dark-gray');
        color: t('color-font-text');
      }
    }

    img {
      width: 100%;
      height: 100%;
      @include themed() {
        background: t('color-bg');
      }
    }
  }

  .logo {
    border-radius: 8px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;

    svg {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }

  .sidenav-element {
    .sidenav-tooltip {
      display: none;
      position: absolute;
      top: 6px;
      left: 46px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      font-weight: bold;
      padding: 9px 14px;
      font-size: 14px;
      white-space: nowrap;
      border-radius: 8px;
      z-index: 99999;
      @include themed() {
        background-color: t('color-bg');
        color: t('color-font-title');
        border: 1px solid t('border-color');
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 9px;
        height: 9px;
        left: -6px;
        top: 12px;
        transform: rotate(45deg) !important;
        @include themed() {
          background-color: t('color-bg');
          color: t('color-font-title');
          border-left: 1px solid t('border-color');
          border-bottom: 1px solid t('border-color');
        }
      }
    }

    &:hover {
      .sidenav-tooltip {
        display: block !important;
      }
    }
  }

  .nav-links {
    display: flex;
    margin-top: 0px;
    margin-left: 0px;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    max-height: 100%;
    height: 100%;

    .nav-bar-seperator {
      width: 36px;
      margin-top: 10px;
      margin-bottom: 10px;
      @include themed() {
        border-top: 1px solid t('border-color-dark');
      }
    }

    .sidenav-element {
      display: flex;
      font-weight: $headline-semi-bold;
      font-size: 15px;
      padding: 10px 0px;
      padding-right: 3px;
      margin-bottom: 0px;
      display: flex;
      border-radius: 0px;
      width: 100%;
      height: 46px;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      border-left: 3px solid transparent;
      @include themed() {
        color: t('color-font-nav');
      }

      svg {
        width: 15px;
        height: 16px;
        margin-right: 0px;
        opacity: 1;
        @include themed() {
          fill: t('color-font-nav');
          stroke: t('color-font-nav');
        }

        path,
        rect,
        g {
          @include themed() {
            fill: t('color-font-nav');
            stroke: t('color-font-nav');
          }
        }
      }

      .notifications-badge {
        position: absolute;
        top: 2px;
        right: 2px;
        margin-left: auto;
        color: #fff;
        font-size: 9px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 20px;
        min-width: 20px;
        border-radius: 12px;
        padding: 0px 4px;
        @include themed() {
          background-color: t('color-red');
          border: 2px solid t('color-gray');
        }
      }

      &:hover {
        position: relative;

        @include themed() {
          background-color: transparent !important;
          color: t('color-primary') !important;
        }

        svg {
          opacity: 1;
          @include themed() {
            fill: t('color-primary');
            stroke: t('color-primary');
          }

          path,
          rect,
          g {
            @include themed() {
              fill: t('color-primary');
              stroke: t('color-primary');
            }
          }
        }
      }

      &.active {
        @include themed() {
          background-color: t('color-light-gray') !important;
          color: t('color-primary') !important;
          border-left: 2px solid t('color-primary') !important;
        }

        svg {
          opacity: 1;
          @include themed() {
            fill: t('color-primary');
            stroke: t('color-primary');
          }

          path,
          rect,
          g {
            @include themed() {
              fill: t('color-primary');
              stroke: t('color-primary');
            }
          }
        }

        .notifications-badge {
          @include themed() {
            border: 2px solid t('color-light-gray');
          }
        }
      }
    }
  }
}

.sidenav-element--main--bot {
  svg {
    width: 22px !important;
    height: 17px !important;
  }
}
