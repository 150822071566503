@import 'styles/scss-variable.scss';

.text-href {
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  @include themed() {
    color: t('color-primary');
  }

  &.text {
    @include themed() {
      color: t('color-font-text');
    }
    font-weight: 600;
  }

  &.important-link {
    font-weight: 600;
    text-decoration: underline;
  }

  &:hover {
    @include themed() {
      color: t('color-primary-highlight');
    }
    text-decoration: underline;
  }
}

a:active {
  @include themed() {
    color: t('color-primary');
  }
}
