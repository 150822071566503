@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.page-content {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-y: auto;

  &--inner {
    width: 100%;
    min-height: 100%;
  }

  &--title {
    height: calc(var(--vh, 1vh) * 100 - 64px - 4px);

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100 - $mobile-nav-height - 64px - 4px);
    }
  }

  &--tabbar {
    height: calc(var(--vh, 1vh) * 100 - 64px - 56px);

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100 - $mobile-nav-height - 64px - 56px);
    }
  }

  &--padding {
    .page-content--inner {
      padding: $scroll-padding;

      @include for-mobile-tablet-only {
        padding: 10px;
      }
    }
  }

  &--boxed {
    .page-content--inner {
      margin: auto;
      max-width: 1200px;
    }
  }

  &--mediumboxed {
    .page-content--inner {
      margin: auto;
      max-width: 850px;
    }
  }

  &--largeboxed {
    .page-content--inner {
      margin: auto;
      max-width: 1050px;
    }
  }

  &--smallboxed {
    .page-content--inner {
      margin-right: auto;
      max-width: 600px;
    }
  }

  &--centered {
    .page-content--inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;
    }
  }

  &--fulltabs {
    .page-content--inner {
      padding: 0px !important;
      height: 100%;

      .form-options-editor {
        height: calc(100vh - 68px);
        overflow-y: auto;
      }

      .fulltabs-page {
        padding: 50px;
        height: calc(100vh - 68px);
        overflow-y: auto;

        .fulltabs-content-page {
          margin: auto;
          max-width: 650px;
        }
      }
    }
  }
}

.page-centered-main-tabs {
  .page-headline {
    .left-align-wrapper,
    .header-content-right {
      width: 370px;
      flex-shrink: 0;
    }

    .header-content-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.centered-main-tabs {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .main-tabs {
    margin-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 0px !important;
    display: flex;
    justify-content: center;
  }

  .main-tabs .filter-selection .priority-button:last-of-type {
    margin-right: 0px !important;
  }
}
