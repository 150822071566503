@import 'styles/scss-variable.scss';

.forget-password-container {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include themed() {
    background-color: t('color-light-gray');
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
