@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.no-surveys-container {
  border-radius: $border-radius;
  padding-bottom: $scroll-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .no-surveys-image {
    height: auto;
    object-fit: contain;
    max-width: 100%;
    max-height: 360px;
    width: auto;
    height: 100%;
    padding-top: 0px;
    padding-bottom: 10px;

    @include for-mobile-tablet-only {
      width: 100%;
    }
  }

  .subtitle {
    max-width: 420px;
  }
}
