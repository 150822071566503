@import 'styles/scss-variable.scss';

.virtual-list-table {
  height: 100%;
  width: 100%;
  position: relative;

  &--scrolled::before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 10px;
    height: 50px;
    z-index: 10;
    pointer-events: none;

    @include themed() {
      background: linear-gradient(
        180deg,
        t('color-bg') 0%,
        rgba($color: t('color-bg'), $alpha: 0) 100%
      );
    }
  }

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 10px;
    height: 50px;
    z-index: 10;
    pointer-events: none;

    @include themed() {
      background: linear-gradient(
        180deg,
        rgba($color: t('color-bg'), $alpha: 0) 0%,
        t('color-bg') 100%
      );
    }
  }

  .List {
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-button:end:increment,
    &::-webkit-scrollbar-button:start:decrement {
      background: #0000;
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      @include themed() {
        background-color: t('color-gray');
      }
    }
    &::-webkit-scrollbar-track-piece:vertical:start {
      border-radius: 4px 4px 0 0;
      margin-top: 5px;
    }
    &::-webkit-scrollbar-track-piece:vertical:end {
      border-radius: 0 0 4px 4px;
      margin-bottom: 5px;
    }
    &::-webkit-scrollbar-track-piece:horizontal:start {
      border-radius: 4px 0 0 4px;
    }
    &::-webkit-scrollbar-track-piece:horizontal:end {
      border-radius: 0 4px 4px 0;
    }
    &::-webkit-scrollbar-thumb:horizontal,
    &::-webkit-scrollbar-thumb:vertical {
      border-radius: 4px;
      display: block;
      height: 48px;
      @include themed() {
        background-color: t('color-dark-gray');
      }
    }
  }
}
