@import 'styles/scss-variable.scss';

.survey-template-modal {
  height: auto;
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
  display: block;

  &-inner {
    padding: $scroll-padding-med;
    display: flex;
    flex-direction: column;
  }

  .survey-create-buttons {
    display: flex;
    justify-content: space-between;

    .link-button {
      min-height: 52px;
      height: 52px;
      line-height: 52px;
      width: calc(25% - 14px);
  
      i {
        font-size: 22px;
      }
    }
  }

  .outbound-wrap-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;

    .template-card {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      border-radius: $border-radius;
      @include themed() {
        background-color: t('color-card-bg');
        border: 1px solid t('border-color');
      }

      img {
        width: 100%;
        height: auto;
      }

      .card-body {
        padding: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;

        &-title {
          font-size: $text-font-size;
          font-weight: $headline-semi-bold;
          margin-bottom: 5px;
          text-align: start;
          @include themed() {
            color: t('color-font-title');
          }
        }

        &-description {
          font-size: $medium-text-font-size;
          font-weight: $headline-semi-bold;
          text-align: start;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }

      &:hover {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
