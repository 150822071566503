@import 'styles/scss-variable.scss';

.edit-qa-modal {
  >div {
    width: 100%;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .default-textarea {
    min-height: 220px;
  }
}