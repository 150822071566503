@import '../../styles/scss-variable.scss';

.board-wrapper-component {
  border-radius: $border-radius;
  padding: 15px;

  @include themed() {
    border: 1px solid t('border-color');
    border-radius: $border-radius;
  }

  svg text {
    @include themed() {
      fill: t('color-font-text');
    }
  }

  svg .apexcharts-xaxis-tick {
    @include themed() {
      stroke: t('color-font-text');
    }
  }

  svg .apexcharts-xaxis {
    line {
      @include themed() {
        stroke: t('color-font-text');
      }
    }
  }

  &-title {
    font-family: $text-font-family;
    font-size: $text-font-size;
    margin-bottom: 15px;
    font-weight: 600;

    @include themed() {
      color: t('color-font-title');
    }
  }
}
