@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.not-installed {
  display: flex;
  padding: 25px;
  border-radius: $border-radius;
  overflow: hidden;
  @include themed() {
    border: 0px solid t('border-color');
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .widget-left {
    padding: 0px;
    max-width: 340px;
    padding-left: 25px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include for-mobile-tablet-only {
      justify-content: center;
      align-items: center;
    }

    .input-label {
      font-size: 20px !important;
    }

    .center-text {
      line-height: 1.4;

      @include for-mobile-tablet-only {
        text-align: center;
      }
    }
  }

  .widget-image {
    width: 280px;
    min-height: 190px;
    height: 100%;
    object-fit: contain;
  }

  &--full-width {
    .widget-left {
      width: 100%;
      max-width: 100%;
      padding-right: 0px;
      padding-left: 0px;
    }

    .widget-image {
      display: none;
    }
  }
}

.title-tag {
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 10px;
  font-size: 14px;
  @include themed() {
    color: t('color-primary');
  }
}