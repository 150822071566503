@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.notes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-repeat: repeat;
  background-size: contain;

  @include for-mobile-tablet-only {
    height: calc((var(--vh, 1vh) * 100) - 49.5px);

    @include themed() {
      border-bottom: 1px solid t('border-color');
    }
  }

  .not-logged-in {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: calc(100% - 30px);
    margin: 15px;
    padding: 15px;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 112px;
    @include themed() {
      border: 1px solid t('border-color');
      color: t('color-font-text');
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: t('color-bg');
    }

    button {
      width: auto;
    }

    span {
      margin-bottom: 10px;
    }
  }

  .comment-composer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: calc(100% - 30px);
    margin: 15px;
    padding: 15px;
    border-radius: $border-radius;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @include themed() {
      color: t('color-font-text');
      background-color: t('color-bg');
      border: 1px solid t('color-bg');
    }

    &--shared {
      text-align: center;
    }

    &:active,
    &:focus-within {
      @include themed() {
        border: 1px solid t('color-primary');
        box-shadow: transparentize(t('color-primary'), 0.9) 0px 0px 0px 4px;
      }
    }

    .tab-buttons {
      display: flex;

      button:first-child {
        margin-left: 0px;
      }
    }

    &-shared {
      .textinput-gray {
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 0px;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
    }

    .send-input-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      @include themed() {
        color: t('color-font-text');
      }

      .send-input {
        resize: none;
        min-height: 80px;
        width: 100%;
        border: none;
        outline: none;
        padding: 10px;
        padding-left: 0px;
        font-size: $small-text-font-size;
        background-color: transparent;
        max-height: 250px;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .send-button {
        padding: 8px 14px;
        border-radius: $input-border-radius;
        border: none;
        font-size: $small-text-font-size;
        font-weight: $headline-semi-bold;
        cursor: pointer;
        @include themed() {
          background-color: t('color-primary');
          color: t('button-color');
        }

        &:focus {
          outline: none;
        }

        &:active {
          box-shadow: $inner-shadow;
        }

        &:hover {
          @include themed() {
            color: t('button-highlight-color');
            background: t('color-primary-highlight');
          }
        }

        &--disabled {
          cursor: not-allowed;
          @include themed() {
            background-color: t('color-gray') !important;
            color: t('color-font-text') !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .feedback-header {
    padding: 15px 20px;
    min-height: 69px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 9.41177px rgba(0, 0, 0, 0.05);
    @include themed() {
      border-bottom: 1px solid t('border-color');
      background-color: t('color-bg');
    }

    span {
      flex-shrink: 0;
      font-size: 16px;
      font-weight: 600;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .header-ticket-title {
      padding-left: 15px;
      padding-right: 20px;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .feedback-header-action-items {
      flex-shrink: 0;

      .show-history-button {
        @include for-mobile-tablet-only {
          display: none;
        }
      }
    }

    .archived-tag {
      font-weight: 600;
      padding-left: 12px;
      margin-right: 8px;
      font-size: $log-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }

      i {
        margin-right: 4px;
        font-size: $small-text-font-size;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }

    &-action-items {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      button {
        width: auto !important;
        font-size: 14px;
      }
    }
  }

  .comments-container {
    width: calc(100% - 5px);
    height: calc(100% - 161px);
    overflow: auto;
    padding: 20px;
    margin-right: 5px;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);

    @include for-mobile-tablet-only {
      padding: 15px;
    }

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-button:end:increment,
    &::-webkit-scrollbar-button:start:decrement {
      background: #0000;
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      @include themed() {
        background-color: t('color-gray');
      }
    }
    &::-webkit-scrollbar-track-piece:vertical:start {
      border-radius: 4px 4px 0 0;
      margin-top: 5px;
    }
    &::-webkit-scrollbar-track-piece:vertical:end {
      border-radius: 0 0 4px 4px;
      margin-bottom: 5px;
    }
    &::-webkit-scrollbar-track-piece:horizontal:start {
      border-radius: 4px 0 0 4px;
    }
    &::-webkit-scrollbar-track-piece:horizontal:end {
      border-radius: 0 4px 4px 0;
    }
    &::-webkit-scrollbar-thumb:horizontal,
    &::-webkit-scrollbar-thumb:vertical {
      border-radius: 4px;
      display: block;
      height: 48px;
      @include themed() {
        background-color: t('color-dark-gray');
      }
    }

    @include for-mobile-tablet-only {
      height: calc(100%);
    }

    &--feature-request {
      padding: 30px !important;

      @include for-mobile-tablet-only {
        padding: 20px !important;
      }
    }

    .static-comments {
      margin-bottom: 15px;

      .main-feedback-container {
        display: flex;

        .main-feedback-avatar {
          width: 34px;
        }

        .main-feedback-content {
          flex-grow: 1;
          padding-left: 10px;
        }
      }

      .comment {
        margin-bottom: 0px;
      }

      .rating-container {
        display: flex;
        margin-left: 38px;
        margin-bottom: 5px;

        &-inner {
          width: auto;
          padding: 15px;
          padding-bottom: 10px;
          border-radius: $border-radius;
          @include themed() {
            background-color: t('color-light-gray');
          }

          &-title {
            font-size: $small-text-font-size;
            font-weight: normal;
            @include themed() {
              color: t('color-font-text');
            }
          }

          &-rating {
            font-size: 26px;
            font-weight: 900;
            @include themed() {
              color: t('color-font-title');
            }
          }
        }
      }

      .static-comment-spaminfo {
        display: flex;

        &-inner {
          width: auto;
          margin-left: 0px;
          margin-right: auto;
          margin-bottom: 8px;
          padding: 13px;
          display: flex;
          flex-direction: row;
          border-radius: $border-radius;
          @include themed() {
            background-color: #ff000022;
            color: #ff0000;
          }

          font-family: $text-font-family;
          font-size: 14px;
          font-weight: 500;

          i {
            margin-right: 5px;
          }
        }
      }
    }

    .comment:last-of-type {
      margin-bottom: 20px;
    }
  }
}

.orga-setup-info {
  width: auto;
  margin: 0px;
  padding: 10px 15px;
  align-items: center;
  margin-right: auto;
  max-width: 560px;
  background-color: transparent !important;
  @include themed() {
    border: 1px solid t('border-color');
  }

  .info-box-icon {
    display: none;
  }
}

.static-comment-spaminfo-inner {
  align-items: center;

  .unmark-spam-button {
    color: #ff0000 !important;
    font-size: $small-text-font-size;
    min-height: unset;
    height: unset;
    line-height: 28px;
    border-color: #ff0000 !important;
    background-color: transparent !important;
    padding-left: 12px;
    padding-right: 12px;
  }

  .unmark-spam-button:hover {
    color: #ffffff !important;
    border-color: #ffffff !important;
    background-color: #ff0000 !important;
  }
}

.loading-comments {
  .loading-comment {
    display: flex;
  }
}

.verify-domain-info {
  align-items: center !important;
  padding: 15px !important;
  width: auto !important;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  border-radius: 0px;

  @include themed() {
    background-color: #ffd20022 !important;
  }

  .info-box-content {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .info-box-icon {
    margin-left: 5px !important;
    margin-right: 10px !important;

    i {
      color: #ffd200 !important;
    }
  }
}

.ticket-headline-input-save {
  margin-right: 30px;

  i {
    margin-right: 0px !important;
  }
}

.ticket-headline-input {
  font-size: 16px !important;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 10px;
  padding: 8.5px 6px !important;
  overflow: hidden;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include themed() {
    color: t('color-font-title');
  }

  &[contenteditable] {
    outline: 0px solid transparent;
  }

  br {
    display: none;
  }

  @include themed() {
    border: none !important;
    background-color: transparent !important;
  }

  &:hover {
    @include themed() {
      border: none !important;
      background-color: t('color-light-gray') !important;
      border-bottom: 0px solid t('color-light-gray') !important;
    }
  }

  &:focus,
  &--unsaved {
    overflow-x: auto;
    text-overflow: initial;

    @include themed() {
      border: none !important;
      background-color: t('color-light-gray') !important;
      border-bottom: 0px solid t('color-primary-black') !important;
    }
  }

  &[contenteditable='true']:empty:before {
    content: 'Untitled';
    pointer-events: none;
    display: block;
    opacity: 0.4;
    @include themed() {
      color: t('color-font-text');
    }
  }

  &.subject[contenteditable='true']:empty:before {
    content: 'Subject' !important;
  }
}
