@import 'styles/scss-variable.scss';

.custom-data-container {
  width: 100%;
  height: calc(100% - 49.5px);
  border-radius: $border-radius;
  overflow: auto;
  @include themed() {
    background-color: t('color-bg');
  }

  .react-json-view {
    padding: 30px;
  }

  &--bg {
    background-position: top;
    background-size: 100%, auto, contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;

    &--light {
      background-image: url('../../../assets/CustomDataBG.png');
    }

    &--dark {
      background-image: url('../../../assets/CustomDataDark.png');
    }
  }
}
