@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.auto-archive-header {
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 22px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themed() {
    background-color: t('color-light-gray');
  }

  i {
    animation: flickerAnimation 2s infinite;
    font-size: 13px;
    margin-right: 5px;
    @include themed() {
      color: t('color-yellow');
    }
  }

  span {
    font-family: $text-font-family;
    font-size: $small-text-font-size;
    font-weight: $headline-semi-bold;
    @include themed() {
      color: t('color-font-title');
    }
  }

  &:hover {
    @include themed() {
      background-color: t('color-gray');
    }
  }
}

.custom-lane-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 32px;
  min-width: 275px;
  margin-bottom: 0px;
  padding: 0px 4px;

  &--feature-request {
    min-width: 345px;
  }

  .left-hand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .status {
      font-family: $text-font-family;
      font-size: 16px;
      font-weight: $headline-semi-bold;
      @include themed() {
        color: t('color-font-title');
      }

      i {
        margin-right: 6px;
        font-size: 14px;
        @include themed() {
          color: t('color-primary');
        }
      }
    }

    .loading-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      margin-left: 6px;

      i {
        font-size: 15px;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .unread {
      margin-right: 6px;
      color: #fff;
      font-size: 11px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      height: 16px;
      min-width: 16px;
      border-radius: 16px;
      padding: 0px 4px;
      @include themed() {
        background-color: t('color-primary');
      }
    }

    .counter {
      margin-left: 5px;
      font-size: 14px;
      @include themed() {
        color: t('color-font-text');
      }
      font-weight: 300;
    }
  }

  .right-hand {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .extended-menu-container {
      width: 28px;
      height: 28px;

      @include for-phone-only {
        display: none;
      }

      .menu-header {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius;
        cursor: pointer;

        &:hover {
          @include themed() {
            background-color: t('color-light-gray');
          }
        }
      }
    }
  }

  .add-bug {
    border-radius: $border-radius;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      @include themed() {
        background-color: t('color-light-gray');
      }

      img {
        opacity: 1;
      }
    }

    img {
      opacity: 0.7;
      width: 16px;
      height: 16px;
      object-fit: contain;
      @include themed() {
        filter: t('image-filter');
      }
    }
  }
}
