@import 'styles/scss-variable.scss';

.feedback-actions-editor {
  .feedback-action-info {
    font-size: $text-font-size;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.action-types-form-container {
  .Collapsible {
    width: 100%;
    max-width: $max-xl;

    .Collapsible__trigger::before {
      top: 13px;
    }
  }
}