@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.login-page {
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include themed() {
    background-color: t('color-light-gray');
  }
}
