@import 'styles/scss-variable.scss';

.helpcenter-action-container {
  .article-list {
    position: absolute;
    bottom: 56px;
    left: 30px;
    right: 30px;
    padding: 0px;
    width: auto;
    max-height: 65vh;
    overflow-y: auto;
    border-radius: $border-radius;
    box-shadow: 0 4px 40px 0 rgba(15, 22, 69, 0.3);

    @include themed() {
      color: t('color-font-title');
      background-color: t('color-bg');
      border: 1px solid t('border-color');
      font-size: $text-font-size;
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 18px 15px;

      @include themed() {
        color: t('color-font-text');
        border-bottom: 1px solid t('border-color');
      }

      &-input {
        display: block;
        width: 100%;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjIuNyAyMi41IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi43IDIyLjUiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2NzY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgY3g9IjguMSIgY3k9IjguNCIgcj0iNi44Ii8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2NzY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9IjEzLjIiIHkxPSIxMi45IiB4Mj0iMjEuNSIgeTI9IjIxLjQiLz48L3N2Zz4=);
        background-position: left 17px center;
        background-repeat: no-repeat;
        background-size: 3.5%;
        padding: 0 52px;
        font-size: 1.4rem;
        appearance: none;
        border: none;
        background-color: transparent;
        outline: none;
        @include themed() {
          color: t('color-font-title');
        }
      }

      &-tag {
        background-color: #ffd200;
        color: #000;
        font-weight: 700;
        height: 20px;
        line-height: 20px;
        margin-left: 10px;
        font-size: 10px;
        padding: 0px 7px;
        border-radius: 5px;
      }

      .language-select {
        max-width: 100px;
        cursor: pointer;
      }

      .language-select > select {
        border: none;
        cursor: pointer;

        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .helpcenter-list-wrapper {
      padding: 10px 0px;
      max-height: 45vh;
      overflow-y: auto;

      .helpcenter-list-item {
        margin: 0px 10px;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: $input-border-radius;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        min-height: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &:hover {
          @include themed() {
            background-color: #ffd20033;
          }
        }

        &.focused {
          @include themed() {
            background-color: #ffd20033;
          }
        }

        &-title {
          padding: 0px 10px;
          font-size: 14px;
          font-weight: 600;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include themed() {
            color: t('color-font-title');
          }
        }
      }
    }
  }

  .helpcenter-container-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    min-height: 20px;
    padding: 3px 7px;
    margin-right: 3px;
    @include themed() {
      color: t('color-font-title');
    }

    svg {
      width: auto;
      height: 17px;
      cursor: pointer;
      margin-left: 2px;
      margin-right: 2px;

      rect {
        @include themed() {
          fill: t('color-font-title');
        }
      }

      path {
        @include themed() {
          fill: t('color-font-title');
        }
      }

      &:hover {
        rect {
          fill: #ffd200;
        }

        path {
          fill: #ffd200;
        }
      }
    }

    &--active {
      svg {
        rect {
          fill: #ffd200 !important;
        }

        path {
          fill: #000 !important;
        }
      }
    }
  }
}
