@import 'styles/scss-variable.scss';

.tab-button {
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background: transparent;
  border-radius: 0px;
  padding: 0px 0px;
  padding-bottom: 4px;
  margin: 0px 8px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  outline: 0;
  @include themed() {
    color: t('color-font-text');
    border-bottom: 2px solid transparent;
  }

  &:hover,
  &--active {
    @include themed() {
      border-bottom: 2px solid t('color-primary-black');
      color: t('color-primary-black');
    }
  }
}
