@import 'styles/scss-variable.scss';

.board-feature-request-card {
  position: relative;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 2px;
  cursor: pointer;
  height: 100%;
  outline: none;
  overflow: hidden !important;
  @include themed() {
    box-shadow: rgba(15, 15, 15, t('card-shadow-opacity')) 0px 0px 0px 1px,
      rgba(15, 15, 15, t('card-shadow-opacity')) 0px 2px 4px;
  }

  &-tags {
    display: flex;
    max-width: 146px;
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 0px;
      height: 21px;
      width: 20px;
      content: ' ';
      @include themed() {
        background: linear-gradient(
          -90deg,
          t('color-bg') 0%,
          rgba($color: t('color-bg'), $alpha: 0) 100%
        );
      }
    }
  }

  .filler {
    flex-grow: 1;
  }

  @include themed() {
    background-color: t('color-card-bg');
  }

  &:hover {
    @include themed() {
      background-color: t('color-card-bg-hover');
    }

    .board-feature-request-card-tags {
      &::after {
        @include themed() {
          background: linear-gradient(
            -90deg,
            t('color-card-bg-hover') 0%,
            rgba($color: t('color-card-bg-hover'), $alpha: 0) 100%
          );
        }
      }
    }
  }

  &-outer {
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__info {
      height: 100%;
      @include themed() {
        color: t('color-font-text');
      }
      display: flex;
      align-items: center;
      font-size: $small-text-font-size;

      &__dot {
        white-space: nowrap;
        margin-right: 5px;
        border-radius: 8px;
        padding: 3px 8px;
        font-size: 12px;
      }

      &__count {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include themed() {
          color: t('color-font-text');
        }
        display: flex;
        align-items: center;
        font-size: $small-text-font-size;

        svg {
          width: 13px;
          height: 13px;
          margin-right: 3px;
          margin-left: 3px;

          path {
            @include themed() {
              fill: t('color-font-text');
            }
          }
        }
      }

      &__further {
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;

        &__dot {
          white-space: nowrap;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          margin: 2px;
          @include themed() {
            background-color: t('color-font-text');
          }
        }
      }
    }
  }

  &__body {
    flex-grow: 1;
    border: 0;
    font-size: 15px;
    max-width: 100%;
    margin-bottom: 0px;
    @include themed() {
      color: t('color-font-title');
    }

    &-title {
      overflow: hidden;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include themed() {
        color: t('color-font-title');
      }
    }

    &-date {
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 450;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  &__foot {
    display: flex;
    justify-content: space-between;

    &__timeline {
      width: auto;
      display: flex;
      flex-direction: column;

      &__text {
        text-align: right;
        @include themed() {
          color: t('color-font-text');
        }
        font-size: $small-text-font-size;
        margin-bottom: 5px;
      }

      &__line {
        height: 2px;
        width: 250px;
        @include themed() {
          background-color: t('color-font-text');
        }

        &__filler {
          height: 2px;
          @include themed() {
            background-color: t('color-font-text');
          }
        }
      }
    }

    .user-avatar {
      height: 21px;
      width: 21px;
      max-height: 21px;
      max-width: 21px;
      min-height: 21px;
      min-width: 21px;
      border-radius: 100%;
      object-fit: cover;

      &:hover + .tooltip {
        visibility: visible;
      }
    }
  }

  &--isduetoday {
    @include themed() {
      border-right: 4px solid t('color-yellow');
    }
  }

  &--isdue {
    @include themed() {
      border-right: 4px solid t('color-red');
    }
  }

  &--unread {
    @include themed() {
      border-right: 4px solid t('color-primary') !important;
    }
  }
}
