@import 'styles/scss-variable.scss';

.widget-app-card {
  padding: 15px;
  width: 100%;
  align-items: flex-start !important;
  justify-content: space-between !important;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  @include themed() {
    border: 1px solid t('border-color');
  }

  .link-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .image {
      object-fit: contain;
      margin-right: 15px;
      width: 36px;
      height: 36px;
      padding: 10px;
      border-radius: 8px;
      @include themed() {
        background-color: t('color-primary');
      }

      path {
        @include themed() {
          color: t('button-color');
          fill: t('button-color');
        }
      }
    }
  }

  .link-body {
    flex-grow: 1;
    padding-top: 8px;
    padding-bottom: 34px;

    .title {
      font-weight: $headline-semi-bold;
      font-size: $text-font-size;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .description {
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .link-footer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}
