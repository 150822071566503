@import 'styles/scss-variable.scss';

.billing {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .payment-card-update {
    display: flex;
  }

  .loading {
    height: auto;
    margin-top: 20px;
  }

  .vat-info {
    font-size: 12px;
    margin-top: 15px;
  }

  .current-plan {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 5px;
    @include themed() {
      color: t('color-font-title');
    }

    .discount-tag {
      background-color: rgb(0, 196, 95);
      color: #fff;
      margin-left: 5px;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 5px;
    }
  }

  .current-plan-price {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-bottom: 3px;
    font-size: 18px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .current-plan-interval {
    font-weight: normal;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.current-plan-container {
  .current-plan-card {
    padding: 25px;
    align-items: flex-start;
  }
}

.manage-invoice-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .primary-button {
    width: auto;
  }
}

.billing-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
}

.invoice-history-container {
  padding-top: 10px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    margin-left: 25px;
  }

  .list-table-container {
    width: 100%;
    padding: 10px;

    thead {
      display: none;
    }
  }
}
