@import 'styles/scss-variable.scss';

.integration-info-content-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  .integration-preview-image {
    height: 34px;
    width: auto;
    object-fit: contain;
  }

  .arrow-icon {
    height: 34px;
    width: auto;
    object-fit: contain;
    margin: 0px 30px;

    @include themed() {
      fill: t('color-font-text');
    }

    g,
    path {
      @include themed() {
        fill: t('color-font-text');
      }
    }
  }
}

.integration-buttons {
  display: flex;

  button, .primary-button {
    margin-right: 10px;
  }
}

.integration-action-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .action-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;

    .action-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }
  }

  .dotted-line {
    width: 18%;
    border: none;
    border-top: 4px dotted #398cfe;
    height: 4px;
    margin-top: 86px;
  }
}

.integration-type-filter-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.choose-integation-action-container {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0px;

  .spacer-line {
    height: 1px;
    width: 100%;
    background-color: #ece8e8;
  }

  .close-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .close-icon {
      height: 20px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .add-button {
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #398cfe;
    cursor: pointer;

    .add-icon {
      height: 40px;
      object-fit: contain;
    }
  }

  .outlined-button {
    border: 1px solid #63a4fe;
    background-color: #fff;
    color: #398cfe;
  }
}

.loading-item {
  width: 100% !important;
}
