@import 'styles/scss-variable.scss';

.newlinetext {
  font-size: $small-text-font-size;
  line-height: $small-text-line-height;

  p {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: $small-text-font-size;
    line-height: $small-text-line-height;
  }

  p:first-of-type {
    margin-top: 0px;
  }

  p:last-of-type {
    margin-bottom: 0px;
  }
}
