@import 'styles/scss-variable.scss';

.title {
  font-family: $h1-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: $h2-font-size;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.06px;
  
  @include themed() {
    color: t('color-font-title');
  }

  &--small {
    font-size: 18px !important;
  }
}
