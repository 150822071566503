@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.onboarding {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @include themed() {
    background-color: t('color-light-gray');
  }

  @include for-phone-only {
    background-size: 100%;
  }

  .select-trial-type {
    margin-bottom: 0px;
    margin-top: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @include themed() {
      border: 1px solid t('color-gray');
    }
  }

  .onboarding-loading {
    padding: 40px;
    min-height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .onboarding-error {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .onboarding-container {
    padding: 0px;
    width: 100%;
    display: flex !important;
    justify-content: center;
    outline: none;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;

    .invitation-onboarding,
    .complete-profile,
    .complete-onboarding,
    .upsell-modal {
      max-width: 900px;
    }

    .create-project {
      height: auto;
    }

    .project-sdk-setup {
      min-height: 100%;

      .instruction-card,
      .show-all-integrations {
        box-shadow: none !important;
        @include themed() {
          border: 1px solid t('border-color');
        }
      }
    }
  }
}

.slick-dots {
  bottom: 25px !important;
}

.slick-dots li.slick-active button:before {
  @include themed() {
    color: t('color-primary') !important;
  }
  font-size: 12px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.onboarding-progress {
  position: fixed;
  bottom: 30px;
  left: 50%;
  display: flex;
  flex-direction: row;
  transform: translateX(-50%);

  &-step {
    width: 60px;
    height: 4px;
    border-radius: 4px;
    margin: 0px 5px;
    @include themed() {
      background-color: t('color-dark-gray');
    }

    &--completed {
      @include themed() {
        background-color: t('color-font-title');
      }
    }
  }
}