@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.shared-feature-requests-board-container {
  border-radius: 0px !important;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;

  &--widget {
    .shared-logo {
      display: none !important;
    }

    .feature-requests-headline {
      height: 58px;
    }

    .shared-feature-requests-board-main-container {
      height: calc((var(--vh,1vh)*100) - 58px) !important;
      flex-direction: column;
      overflow: auto;
    }

    .shared-feature-requests-board::before {
      display: none !important;
    }

    .tag-filters {
      display: none;
    }

    .close-modal {
      display: none !important;
    }

    .feature-request-details-inner {
      animation-name: slideInRight !important;
    }

    .shared-column {
      padding: 10px !important;
    }

    .shared-board .shared-column::before {
      top: 44px;
    }

    .shared-board .shared-column .shared-column-cards {
      padding-top: 4px !important;
    }

    .shared-feature-requests-board-filters {
      display: none !important;
    }

    .shared-feature-requests-board {
      height: auto !important;
      overflow: initial !important;
      padding-bottom: 60px;
    }

    .shared-powered-by {
      display: none !important;
    }

    .feature-request-list .feature-request-card-container {
      padding-bottom: 6px;
      margin-bottom: 6px;
    }

    .feature-request-list .feature-request-card-body-statustags {
      display: none;
    }

    .board-filter-header {
      justify-content: space-between !important;
      padding-bottom: 10px;
      flex-direction: row !important;

      .board-filter-sort,
      .board-filter-status {
        width: calc(50% - 5px);
      }

      .filter-title,
      .board-filter-header-title {
        display: none;
      }

      .dropdown-selection__control {
        min-width: 100px !important;

        @include themed() {
          border: 1px solid t('border-color') !important;
        }
      }
    }

    .filter-title {
      font-weight: 500 !important;
      @include themed() {
        color: t('color-font-text') !important;
      }
    }

    .shared-board {
      height: calc((var(--vh,1vh)*100) - 65px) !important;
    }
    
    .feature-request-card-body-title {
      font-weight: 600 !important;
    }
    
    .feature-requests-headline--inner {
      justify-content: center !important;
    }

    .feature-requests-headline--inner .links {
      padding-top: 0px !important;
      flex-grow: 0 !important;

      div {
        font-weight: 600 !important;
        height: auto !important;
      }

      .active {
        border-bottom: none !important;
        padding-bottom: 0px !important;
      }
    }
  }

  .feature-request-list-empty {
    background-color: transparent !important;
  }

  .feature-request-card-dot {
    display: none;
  }

  .checkbox-list .checkmark {
    @include themed() {
      border: 1px solid t('border-color') !important;
    }
  }

  .checkbox-list input:checked ~ .checkmark {
    @include themed() {
      background-color: t('color-gray');
    }
  }

  .checkbox-list .checkmark:after {
    top: 4px !important;
    left: 8px !important;
  }

  .feature-request-form {
    width: 100%;
    border-radius: $border-radius;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include themed() {
      background-color: t('color-light-gray');
    }

    .form-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
    }

    .field-container .input-label {
      font-size: 14px;
      font-weight: 500;
    }

    .field-container .input-wrapper .textinput-gray {
      padding: 10px;
      height: auto !important;
      background-color: #fff;
    }

    .textarea-container .textarea-gray {
      padding: 10px;
      height: 80px;
      background-color: #fff;
    }
  }

  .feature-request-poweredby {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    padding-bottom: $scroll-padding;

    a {
      font-size: 12px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .no-comments {
    padding: 20px !important;
    padding-top: 0px !important;

    .title {
      font-size: 15px;
      margin-bottom: 0px !important;
    }

    .text {
      font-size: 14px;
    }
  }
}
