@import 'styles/scss-variable.scss';

.react-date-picker {
  cursor: pointer;
  width: 100%;

  .react-date-picker__wrapper {
    padding: 5px;
    border-radius: $input-border-radius;
    border: none;
    width: 100%;

    &:hover {
      @include themed() {
        background-color: t('color-light-gray');
      }
    }

    .react-date-picker__inputGroup__input:invalid {
      border-radius: 5px;
    }

    .react-date-picker__inputGroup__input,
    span {
      font-size: $small-text-font-size;
      font-weight: 600;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .react-date-picker__main_placeholder {
      font-size: $small-text-font-size;
      line-height: 27px;
      font-weight: 500;
      display: flex;
      align-items: center;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .react-date-picker__calendar-button {
      i {
        font-size: 14px;
        margin-left: 1px;
        margin-right: 7px;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
    
    .react-date-picker__button {
      padding: 0px 5px !important;
    }

    .react-date-picker__clear-button {
      svg {
        @include themed() {
          fill: t('color-font-text');
          stroke: t('color-font-text') !important;
        }
      }
    }

    .react-date-picker__calendar-button:hover {
      i {
        @include themed() {
          color: t('color-primary');
        }
      }
    }

    .react-date-picker__calendar-button:hover {
      svg {
        @include themed() {
          fill: t('color-primary');
          stroke: t('color-primary') !important;
        }
      }
    }
  }

  &--open {
    .react-date-picker__calendar-button,
    .react-date-picker__clear-button {
      svg {
        @include themed() {
          fill: t('color-primary');
          stroke: t('color-primary') !important;
        }
      }
    }
  }
}

.react-date-picker__calendar {
  .react-calendar {
    @include themed() {
      background-color: t('color-bg');
      border: 0px solid t('color-gray');
      color: t('color-font-title');
    }
    z-index: 999;
    box-shadow: rgba(12, 12, 12, 0.05) 0px 0px 0px 1px,
      rgba(12, 12, 12, 0.1) 0px 3px 6px, rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
    border-radius: $border-radius !important;
    font-size: $medium-text-font-size !important;
    padding: 10px;
    margin: 0px;
    z-index: 10000000;

    .react-calendar__tile--now {
      background-color: transparent;
      @include themed() {
        color: t('color-primary') !important;
      }
    }

    .react-calendar__tile--active {
      @include themed() {
        background-color: t('color-primary') !important;
        color: t('button-color') !important;
      }
    }

    .react-calendar__navigation__arrow {
      @include themed() {
        color: t('color-font-text');
      }
    }

    .react-calendar__tile {
      @include themed() {
        color: t('color-font-title');
      }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      @include themed() {
        color: t('color-font-log');
      }
    }

    .react-calendar__month-view__days__day--weekend {
      @include themed() {
        color: t('color-font-text');
      }
    }

    .react-calendar__navigation__label__labelText {
      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}
