@import 'styles/scss-variable.scss';

.manually-create-bug {
  width: 100%;
  padding: 0px;

  .compose-conversation-session {
    border-bottom: 0px;
    margin-bottom: 0px !important;
  }

  .session-search-container {
    position: relative;
    width: 100%;

    .textinput-gray {
      @include themed() {
        border: 1px solid t('border-color') !important;
      }

      &:focus {
        outline-width: 0;
  
        @include themed() {
          border: 1px solid t('color-primary-black') !important;
        }
      }
    }
  }
}

.addmenuoptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .addmenuoption-card {
    width: calc(33.3% - 16px) !important;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 20px;

    .icon-container {
      width: 50px;
      height: 50px;
      padding: 10px;
      border-radius: 100%;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .info {
      .title {
        font-family: $text-font-family;
        font-size: 16px;
        font-weight: $headline-semi-bold;
        line-height: 20px;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .description {
        margin-top: 5px;
        text-align: center;
        font-family: $text-font-family;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }

    &:hover {
      cursor: pointer;
      @include themed() {
        box-shadow: t('hover-shadow');
      }
    }
  }
}
