@import 'styles/scss-variable.scss';

.mdx-title {
  @include themed() {
    color: t('color-font-title');
  }
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
}
