@import 'styles/scss-variable.scss';

.icon-button {
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 42px;
  height: 42px;
  width: 42px;
  min-width: 42px;
  line-height: 42px;
  border-radius: $input-border-radius;
  background-color: transparent;
  cursor: pointer;

  @include themed() {
    i {
      color: t('color-font-title');
      font-size: 24px;
    }
  }

  &:hover {
    @include themed() {
      i {
        color: t('color-font-title-high');
      }
    }
  }

  &.white {
    i {
      color: #ffffff99;
    }

    &:hover {
      i {
        color: #fff;
      }
    }
  }

  &.gray {
    i {
      @include themed() {
        color: t('color-dark-gray');
      }
    }

    &:hover {
      i {
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }

  &.primary {
    width: auto;
    padding: 0px 10px;
    height: 36px;
    min-height: 36px;
    border-radius: $input-border-radius;
    border: unset;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    box-shadow: none;

    @include themed() {
      color: t('color-font-title');
      background-color: t('color-light-gray');

      i {
        color: t('color-font-title');
        font-size: 16px;
      }
    }

    &:hover {
      @include themed() {
        background-color: t('color-primary-light');
        color: t('color-primary');

        i {
          color: t('color-primary');
        }
      }
    }
  }

  &.secondary {
    width: auto;
    padding: 0px 10px;
    height: 36px;
    min-height: 36px;
    border-radius: $input-border-radius;
    border: unset;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    @include themed() {
      box-shadow: 0px 1px 7px rgba(t('color-font-title'), 0.1);
    }

    @include themed() {
      background-color: t('color-bg');

      i {
        color: t('color-font-title');
        font-size: 16px;
        margin-left: 2px;
      }
    }

    &:hover {
      @include themed() {
        background-color: t('color-primary');

        i {
          color: t('button-color');
        }
      }
    }
  }
}
