@import 'styles/scss-variable.scss';

.feedback-item {
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid transparent;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-user {
      display: flex;
      align-items: center;

      .user-avatar {
        margin-right: 10px;
      }

      span {
        font-size: 16px;
        font-weight: 600;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    time {
      font-size: 15px;
      line-break: normal;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  &-body {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 15px;
    margin-top: 30px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    @include themed() {
      color: t('color-font-text');
    }
  }

  &:hover {
    @include themed() {
      box-shadow: t('hover-shadow');
    }
  }
}
