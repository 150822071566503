@import 'styles/scss-variable.scss';

.dark-mode-container {
  cursor: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 17px;
  padding-right: 5px;

  .dark-mode-title {
    font-size: $medium-text-font-size;
    font-weight: 500;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .dark-mode-toggle {
    padding: 15px 20px;
  
    .checkbox {
      opacity: 0;
      position: absolute;
    }
  
    .label {
      @include themed() {
        border: 1px solid t('border-color');
      }
      border-radius: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      position: relative;
      height: 24px;
      width: 44px;
      transform: scale(1.5);
    }
  
    .label .ball {
      @include themed() {
        background-color: t('button-color');
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 16px;
      width: 16px;
      transform: translateX(0px);
      transition: transform 0.2s linear;
    }
  
    .checkbox:checked + .label .ball {
      transform: translateX(20px);
    }
  
    .fas {
      font-size: 12px;
    }
  
    .fa-moon {
      color: #E3A400;
    }
  
    .fa-sun {
      color: #f39c12;
    }
  
    &.big {
      .label {
        height: 30px;
        width: 56px;
      }
  
      .fas {
        font-size: 18px;
      }
  
      .label .ball {
        top: 3px;
        left: 3px;
        height: 22px;
        width: 22px;
      }
  
      .checkbox:checked + .label .ball {
        transform: translateX(26px);
      }
    }
  }
  
}