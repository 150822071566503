@import 'styles/scss-variable.scss';

.organisation-detail-page {
  width: 100%;
}

.subnav-spacer-top {
  margin-top: 4px;
  padding-top: 10px;
}
