@import 'styles/scss-variable.scss';

.news-editor-container {
  display: flex;
  flex-direction: row;

  .news-editor-input-container {
    width: calc(65% - 50px);
    margin-right: 50px;
  }

  .news-preview-wrapper {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;

    .image-upload-component {
      height: 160px;
    }
  }
}

.news-input-body {
  width: 100%;
  margin-bottom: 50px;

  .news-input-container {
    overflow: hidden;
    border-radius: $input-border-radius;
    @include themed() {
      border: 1px solid t('border-color');
    }

    .editor-wrapper {
      padding: 25px;

      .ProseMirror {
        min-height: 320px;
      }
    }
  }
}

.toolbar-wrapper {
  width: 100%;
  padding: 12px;
  padding-right: 14px;
  padding-left: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @include themed() {
    border-bottom: 1px solid t('border-color');
    background-color: t('color-bg');
  }
}
