@import 'styles/scss-variable.scss';

.activity-log-container {
  width: 100%;
  height: calc(100% - 49.5px);
  overflow: hidden;
  border-radius: 0 0 $border-radius $border-radius;
  padding: 0px;

  .log-details-close-button {
    display: flex;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 99;

    img {
      width: 14px;
      height: 14px;
      opacity: 0.5;
      @include themed() {
        filter: t('image-filter');
      }
    }

    &:hover img {
      opacity: 1;
    }
  }

  &--log-bg {
    background-position: top;
    background-size: 100%, auto, contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;

    &--light {
      background-image: url('../../../assets/ConsoleLogsBG.png');
    }

    &--dark {
      background-image: url('../../../assets/ConsoleLogsDark.png');
    }
  }

  &--no-contents {
    padding: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    @include themed() {
      color: t('color-font-text');
    }
  }

  .log-details {
    position: absolute;
    top: 107px;
    right: 15px;
    max-height: calc(var(--vh, 1vh) * 100 - 122px);
    overflow-y: auto;
    background-color: #fff;
    width: 55%;
    max-width: 55%;
    border-radius: $border-radius;
    @include themed() {
      border: 1px solid t('border-color');
      color: t('color-font-text');
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: t('color-bg');
    }

    &-content-container {
      position: relative;
      padding: 20px;
    }
  }

  .log-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    padding-bottom: 0px;
    overflow-x: auto;

    .log-tools-filter {
      display: flex;
      flex-direction: row;

      > button {
        margin-right: 8px;
      }
    }

    .log-tools-search {
      display: flex;

      .log-button {
        width: auto;

        img {
          width: auto !important;
          height: 28px !important;
          margin-right: 0px !important;
          @include themed() {
            filter: t('image-filter');
          }
        }
      }

      .field-container {
        min-width: 150px;
        margin-left: 10px;
      }
    }
  }

  .log-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: calc(100% - 55px);
    margin-top: 5px;
    overflow-y: auto;

    .ReactVirtualized__Grid {
      outline: none;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: auto !important;
    }

    .timeline-item {
      min-height: 34px;
      height: 34px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      min-width: 100%;
      padding: 8px 0px;
      position: relative;

      .timeline-count {
        margin-left: 15px;
        margin-right: 30px;
        padding-top: 2px;
        font-size: 10px;
        min-width: 50px;
        @include themed() {
          color: t('color-font-text');
        }

        img {
          position: absolute;
          width: 23px;
          height: 23px;
          object-fit: contain;
          left: 65px;
          top: 5px;
          padding: 4px;
        }
      }

      .show-details {
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
        @include themed() {
          color: t('color-font-text');
        }
      }

      .timeline-data,
      .timeline-data a {
        @include themed() {
          color: t('color-font-title');
        }
        width: auto;
        white-space: wrap;
        word-break: break-all;
        margin-right: 10px;
        font-size: 14px;

        span {
          font-size: 14px;
        }
      }

      .timeline-data .event-data {
        display: flex;

        span {
          margin-right: 5px;
        }
      }

      .timeline-data .networklog-title a {
        text-decoration: underline;
      }

      &--error {
        .timeline-data,
        .timeline-data .networklog-title {
          @include themed() {
            color: t('color-red');
          }
        }
      }
    }
  }
}
