@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.modal {
  width: 100%;
  max-width: $max-xml;
  padding: 40px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: $border-radius;
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: 50%;
  transform: translate(-50%, -50%);
  @include themed() {
    background-color: t('color-bg');
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  @include for-mobile-tablet-only {
    border-radius: 0px;
  }

  .close-button {
    display: flex;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 36px;
    height: 36px;
    z-index: 99;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    i {
      font-size: 20px;

      @include themed() {
        color: t('color-font-text');
      }
    }

    &:hover i {
      @include themed() {
        color: t('color-primary');
      }
    }
  }

  &--message-compose {
    max-width: 700px !important;
    padding: 30px !important;
  }

  &--less-padding {
    padding: 0px;
  }

  &--vote {
    max-width: min(calc(100vw - 40px), 400px);
  }

  &--max-width {
    height: calc(var(--vh, 1vh) * 100);
    max-width: 100vw !important;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;

    .close-button {
      right: 4px;
      top: 4px;
    }
  }

  &--full-width-autoheight {
    height: auto;
    min-height: auto;
    max-width: 850px;
    max-height: calc(var(--vh, 1vh) * 100 - 80px);
    width: 100vw;
    padding: 0px;
    margin: 0px;
    overflow: auto;
    display: block;

    @include for-mobile-tablet-only {
      max-height: 100%;
      height: calc(var(--vh, 1vh) * 100);
    }

    .bug-detail-container {
      border-radius: $input-border-radius;
    }

    .close-button {
      right: 4px;
      top: 4px;
    }

    .details-container .right {
      border-bottom-right-radius: $input-border-radius;
    }

    .nav-container .nav-items .nav-item:first-of-type {
      border-top-left-radius: $input-border-radius;
    }
  }

  &--full-width {
    height: calc(var(--vh, 1vh) * 100 - 50px);
    max-width: 1000px;
    width: 100vw;
    padding: 0px;
    margin: 0px;

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100);
      border-radius: 0px;
    }

    .bug-detail-container {
      border-radius: $input-border-radius;
    }

    .close-button {
      right: 6.5px;
      top: 6.5px;

      @include for-mobile-tablet-only {
        border-radius: 8px;
        @include themed() {
          background-color: t('color-gray');
        }
      }
    }

    .details-container .right {
      border-bottom-right-radius: $input-border-radius;
    }

    .nav-container .nav-items .nav-item:first-of-type {
      border-top-left-radius: $input-border-radius;
    }
  }

  &--full-width-right {
    height: calc(var(--vh, 1vh) * 100);
    max-width: 1100px;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    transform: none;
    left: auto;
    right: 0px;
    top: 0px;
    position: absolute;
    border-radius: 0px;
    animation-name: slideInRight;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    box-shadow: 0px 0px 7.41177px rgba(0, 0, 0, 0.08);

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100);
      border-radius: 0px;
    }

    .bug-detail-container {
      border-radius: 0px;
    }

    .close-button {
      animation-name: blendIn;
      animation-duration: 0.6s;
      animation-timing-function: ease-in;
      right: 1110px;
      top: 6.5px;
      border-radius: 100%;
      opacity: 0.7;
      width: 32px;
      height: 32px;

      @include themed() {
        background-color: t('color-gray');
      }

      @media screen and (max-width: 1130px) {
        right: 6.5px;
        background-color: transparent !important;
      }

      i {
        font-size: 16px;
      }

      &:hover {
        opacity: 1;
      }
    }

    .details-container .right {
      border-bottom-right-radius: $input-border-radius;
    }

    .nav-container .nav-items .nav-item:first-of-type {
      border-top-left-radius: $input-border-radius;
    }
  }

  &--mid-width-right {
    height: calc(var(--vh, 1vh) * 100);
    max-width: 610px;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    transform: none;
    left: auto;
    right: 0px;
    top: 0px;
    position: absolute;
    border-radius: 0px;
    animation-name: slideInRight;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    box-shadow: 0px 0px 7.41177px rgba(0, 0, 0, 0.08);

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100);
      border-radius: 0px;
    }
    
    .close-button {
      animation-name: blendIn;
      animation-duration: 0.6s;
      animation-timing-function: ease-in;
      right: 620px;
      top: 6.5px;
      border-radius: 100%;
      opacity: 0.7;
      width: 32px;
      height: 32px;

      @include themed() {
        background-color: t('color-gray');
      }

      @media screen and (max-width: 1130px) {
        right: 6.5px;
        background-color: transparent !important;
      }

      i {
        font-size: 16px;
      }

      &:hover {
        opacity: 1;
      }
    }

    .details-container .right {
      border-bottom-right-radius: $input-border-radius;
    }

    .nav-container .nav-items .nav-item:first-of-type {
      border-top-left-radius: $input-border-radius;
    }
  }

  &--upsellmodal {
    padding: 0px !important;
    max-width: 470px !important;
  }

  &--onboarding {
    height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    max-width: 100vw;
    padding: 0px;
    margin: 0px;
    border-radius: 0px !important;
    overflow: auto;

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      border-radius: 0px !important;
      max-width: 100%;
    }
  }

  &--full {
    height: calc(var(--vh, 1vh) * 100 - 20px);
    width: calc(100vw - 20px);
    padding: 0px;
    margin: 0px;
    max-width: calc(100vw - 20px);
    border-radius: $border-radius !important;
    overflow: hidden;

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      border-radius: 0px !important;
      max-width: 100%;
    }
  }

  &--side-edit {
    height: calc(var(--vh, 1vh) * 100);
    width: calc(100vw - 20px);
    padding: 0px;
    margin: 0px;
    max-width: 1070px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    border-radius: $border-radius !important;
    overflow: hidden;
    left: auto;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin-left: auto;
    transform: none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      border-radius: 0px !important;
      max-width: 100%;
    }

    .close-button {
      display: none;
    }
  }

  &--xl-width {
    max-width: 800px;
  }

  &--feedbacktype {
    max-width: 800px;
  }

  &--tutorial {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    max-width: 100%;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;

    .close-button {
      right: 4px;
      top: 4px;
      z-index: 999;
    }
  }

  &--no-wrapper {
    padding: 0px;
    width: 100%;
    max-width: 650px;
  }
}

.modal > * {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 150;
  @include themed() {
    background-color: t('modal-background-color') !important;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
