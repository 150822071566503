@import 'styles/scss-variable.scss';

.compose-message-modal {
  display: flex;
  align-items: flex-start;

  .compose-message-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
    font-size: $small-text-font-size;
    position: relative;
    @include themed() {
      color: t('color-font-title');
      background-color: t('color-bg');
      font-size: $text-font-size;
    }

    .send-input-editor-container {
      margin: 0px !important;
      width: 100% !important;
      padding: 0px !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      @include themed() {
        border: none !important;
      }
    }

    .editor-container {
      min-height: 130px;
    }
  }
}

.session-search-container {
  position: relative;
  width: 100%;

  .textinput-gray {
    border: none !important;
  }

  .session-search {
    position: absolute;
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    box-shadow: rgba(12, 12, 12, 0.05) 0px 0px 0px 1px,
      rgba(12, 12, 12, 0.1) 0px 3px 6px, rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
    border-radius: $border-radius !important;
    font-size: $medium-text-font-size !important;
    padding: 0px;
    margin: 0px;
    z-index: 10000000;
    overflow-y: scroll;
    max-height: 200px;

    @include themed() {
      background-color: t('color-bg');
      border: 0px solid t('color-gray');
      color: t('color-font-title');
    }

    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    .session-search-session {
      padding: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      @include themed() {
        color: t('color-font-title');
      }

      .type-guest-tag {
        margin-left: auto;
      }

      &:hover {
        @include themed() {
          background-color: t('color-light-gray');
        }
      }

      &-data {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
      }

      &-name {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-weight: 600;
        font-size: $small-text-font-size;
        @include themed() {
          color: t('color-font-title');
        }
      }

      &-email {
        display: flex;
        align-items: center;
        text-transform: lowercase;
        font-size: $log-text-font-size;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }
}
