@import 'styles/scss-variable.scss';

.fa-user-robot-xmarks {
  color: #ff0000;
}

.cell-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;

  path,
  g {
    @include themed() {
      fill: t('color-font-text');
    }
  }
}
