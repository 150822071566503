@import 'styles/scss-variable.scss';

.link-button {
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: $input-border-radius;
  padding: 0px 20px;
  font-style: normal;
  font-weight: $headline-semi-bold;
  font-size: $medium-text-font-size;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  @include themed() {
    color: t('color-font-title');
    background-color: t('color-light-gray');

    i {
      color: t('color-font-title');
    }
  }

  .spring-spinner-small-rotator {
    @include themed() {
      border-top-color: t('color-font-title');
      border-right-color: t('color-font-title');
    }
  }

  &.danger {
    @include themed() {
      color: t('color-red');
      background-color: t('color-red-light');

      i {
        color: t('color-red');
      }
    }

    .spring-spinner-small-rotator {
      @include themed() {
        border-top-color: t('color-red');
        border-right-color: t('color-red');
      }
    }
  }

  .icon {
    margin-right: 8px;
  }

  &--icon-right {
    .icon {
      margin-left: 10px;
      margin-right: 0px;
    }
  }

  &--no-border {
    box-shadow: none !important;
  }

  &--small {
    padding: 3px 12px;
    line-height: 20px;
    height: auto;
    min-height: auto;
    font-size: 13px;
    min-height: 36px;
  }

  &--button-only {
    .icon {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
  }

  &:hover {
    @include themed() {
      background-color: t('color-primary-light');
      color: t('color-primary');

      i {
        color: t('color-primary');
      }
    }
  }

  &.danger:hover {
    @include themed() {
      color: t('button-color');
      background-color: t('color-red') !important;

      i {
        color: t('button-color');
      }
    }
  }

  &:focus {
    outline: 0;
  }

  &.dark {
    @include themed() {
      color: t('button-highlight-color');
    }
  }

  &--disabled {
    @include themed() {
      background-color: t('color-gray') !important;
      color: t('color-font-text') !important;
      box-shadow: none !important;
    }
    cursor: not-allowed;
  }

  &.white {
    @include themed() {
      color: #ffffff99;
    }

    &:hover {
      @include themed() {
        color: #fff;
      }
    }
  }

  &.danger-button {
    @include themed() {
      color: t('color-red');
      background-color: t('color-red-light');
    }

    i {
      @include themed() {
        color: t('color-red');
      }
    }

    &:hover {
      @include themed() {
        color: t('button-color');
        background-color: t('color-red') !important;
      }

      i {
        @include themed() {
          color: t('button-color');
        }
      }
    }
  }
}
