@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.feature-request-content {
  display: flex;
  flex-direction: row;
  outline: none;
  align-items: center;
  padding: 0px;
  border-radius: $border-radius;
  max-width: 100%;
  margin-bottom: 5px;
  margin-left: 0px;

  @include for-phone-only {
    &-upvotes-placeholder {
      .react-loading-skeleton {
        width: 48px !important;
        height: 48px !important;
      }
    }
  }

  &-upvotes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    min-width: 48px;
    min-height: 56px;
    width: 48px;
    height: 56px;
    @include themed() {
      background-color: t('color-bg');
      border: 1px solid t('border-color');
    }

    &:hover {
      @include themed() {
        box-shadow: 0px 0px 9.41177px rgba(0, 0, 0, 0.05),
          0px 0.784314px 2.35294px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-body {
    padding-left: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 60px;

    &-title {
      padding-top: 0px;
      font-size: 17px;
      line-height: 22px;
      font-weight: 600;
      word-wrap: break-word;
      @include themed() {
        color: t('color-font-title');
      }

      .textinput-gray {
        @include themed() {
          background-color: t('color-bg') !important;
        }
      }
    }

    &-date {
      margin-top: 3px;
      font-size: 14px;
      font-weight: 400;
      opacity: 1;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}
