@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.sdk-installed-successfully {
  background-color: #4fc47e20;
  color: #4fc47e;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 30px;
  font-size: 14px;
}

.project-installation {
  .page-content--inner {
    padding: 0px !important;
  }

  .apikey .key {
    max-width: 350px;
    @include themed() {
      color: t('color-font-title') !important;
    }
  }

  &-inner-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @include for-mobile-tablet-only {
      padding: 20px;
    }
  }

  .tab-buttons {
    display: flex;

    button:first-child {
      margin-left: 0px;
    }
  }

  .tutorial-container {
    max-width: 800px;
    margin: 0px auto;
    margin-top: 50px;

    .card {
      @include themed() {
        border: 1px solid t('border-color') !important;
      }
    }
  }

  .sdk-setup-left {
    .title {
      text-align: left;
    }

    .text b {
      @include themed() {
        color: t('color-font-title');
      }
    }
  }

  &-header {
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% 320px;
    margin-bottom: 50px;
    @include for-phone-only {
      background: none !important;
    }

    &--inner {
      margin: auto;
      max-width: 850px;

      > .title {
        font-size: 24px !important;
        margin-top: 140px;
        color: #060d25 !important;

        @include for-phone-only {
          margin-top: 100px;
          font-size: 20px !important;
        }
      }

      .side-by-side {
        margin-top: 40px;
      }

      .apikey {
        @include for-phone-only {
          width: calc(100vw - 80px);
        }
      }

      .side-by-side-card {
        padding: 20px;
        align-items: flex-start !important;
        justify-content: space-between !important;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
        border-radius: $border-radius;

        @include themed() {
          background-color: t('color-bg');
          border: 1px solid t('border-color');
        }
      }
    }
  }

  &-body {
    margin: auto;
    max-width: 850px;
    margin-bottom: 50px;
  }

  .platforms {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;

    @include for-mobile-tablet-only {
      grid-template-columns: 1fr;
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }

  .show-all-integrations {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    padding: 30px;
    padding-top: 70px;
    width: 100%;
    position: relative;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    min-height: 200px;
    @include themed() {
      background: t('color-bg');
    }

    .head {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        object-fit: contain;
        margin-right: 15px;
        width: 52px;
        height: 52px;
        padding: 10px;
        border-radius: $border-radius;
        @include themed() {
          background-color: t('color-light-gray');
        }
      }

      span {
        font-weight: $headline-semi-bold;
        font-size: $text-font-size;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .subtitle {
      text-align: left;
      width: 100%;
      margin-top: 0px;
      margin-bottom: 8px;
      font-size: $small-text-font-size;
      line-height: 18px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}
