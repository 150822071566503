@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.project-selection {
  margin: 0px;

  input {
    height: 1px;
    max-height: 1px;
  }

  .project-selection__control {
    min-width: auto;
    appearance: none;
    outline: none;
    padding: 0px 0px;
    cursor: pointer;
    font-size: $medium-text-font-size;
    box-shadow: none;
    background-color: transparent;
    border: none;
    @include themed() {
      color: t('color-font-title');
    }

    @include for-mobile-tablet-only {
      font-size: $text-font-size;
    }

    &:hover {
      .project-option {
        background-color: transparent !important;
      }
    }
  }

  &__placeholder {
    font-size: $medium-text-font-size;
    font-weight: 600;
    @include themed() {
      color: t('color-font-text') !important;
    }
  }

  &__placeholder:hover {
    @include themed() {
      color: t('color-primary') !important;
    }
  }

  &__single-value {
    @include themed() {
      color: t('color-font-title') !important;
    }
  }

  &__value-container {
    display: flex !important;
    padding: 0px !important;
    padding-left: 10px !important;
    height: 50px;

    .project-option {
      padding: 10px 0px !important;
    }
  }

  &__menu {
    @include themed() {
      background-color: t('color-bg');
      border: 0px solid t('color-gray');
      color: t('color-font-title');
    }
    box-shadow: rgba(12, 12, 12, 0.05) 0px 0px 0px 1px,
      rgba(12, 12, 12, 0.1) 0px 3px 6px, rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
    border-radius: $border-radius !important;
    font-size: $medium-text-font-size !important;
    padding: 0px;
    margin: 0px 0px;
    width: calc(100% - 20px) !important;
    top: 40px !important;
    z-index: 100;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__menu-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .project-option:first-of-type {
      border-top-left-radius: $border-radius !important;
      border-top-right-radius: $border-radius !important;
      padding-top: 10px;
    }

    .project-option:last-of-type {
      border-bottom-left-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
      padding-bottom: 10px;
    }
  }

  #react-select-2-input {
    top: -100px !important;
  }

  .project-option {
    cursor: pointer;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;

    .image-placeholder {
      width: 30px;
      height: 30px;
      border-radius: $border-radius;
      overflow: hidden;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $headline-semi-bold;
      @include themed() {
        background-color: t('color-gray');
        color: t('color-font-text');
      }
    }

    img {
      @include themed() {
        background: t('color-gray');
      }
      min-width: 30px;
      min-height: 30px;
      width: 30px;
      height: 30px;
      border-radius: $border-radius;
      overflow: hidden;
      object-fit: cover;
    }

    .project-name {
      max-width: 150px;
      height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include themed() {
        color: t('color-font-text');
      }
      font-weight: $headline-semi-bold;
    }

    .active {
      @include themed() {
        color: t('color-font-text');
      }
    }

    &:hover {
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-light-gray');
      }

      .project-name {
        @include themed() {
          color: t('color-font-title');
        }
      }
    }
  }
}
