@import 'styles/scss-variable.scss';

.filter-selection {
  display: flex;
  justify-content: space-evenly;
  width: auto;
  padding: 4px 2px;
  border-radius: $input-border-radius;
  @include themed() {
    border: 1px solid t('border-color');
    background-color: t('color-bg');
  }

  .priority-button {
    width: 100%;
    border: unset;
    font-style: normal;
    font-weight: $headline-semi-bold;
    font-size: $small-text-font-size;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    background: transparent;
    outline: none;
    padding: 6px 10px;
    line-height: 18px;
    margin: 0px 2px;
    min-height: 0px;
    white-space: nowrap;
    @include themed() {
      color: t('color-font-text');
    }

    span {
      font-weight: $headline-semi-bold;
      font-size: $small-text-font-size;
      text-align: center;
      position: relative;
      @include themed() {
        color: t('color-font-text');
      }
    }

    &:hover {
      border-radius: 8px;
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-gray');
      }

      span {
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    &--active,
    &--active:hover {
      border-radius: 8px;
      @include themed() {
        background-color: t('color-primary-black');
        color: t('color-primary-inverted');
      }

      span {
        @include themed() {
          color: t('color-primary-inverted');
        }
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    span {
      @include themed() {
        color: t('color-font-text') !important;
      }
    }

    .priority-button {
      &:hover {
        cursor: not-allowed;
        @include themed() {
          color: t('color-font-text') !important;
        }
      }

      &--active {
        &:hover {
          cursor: not-allowed;
          @include themed() {
            color: t('button-color') !important;
          }
        }
      }
    }
  }
}

.main-tabs {
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 40px;
  @include themed() {
    border-bottom: 1px solid t('border-color');
  }

  &-page {
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 25px !important;

    .priority-button {
      font-size: 16px !important;
      padding-top: 10px !important;
    }
  }

  .filter-selection {
    display: inline-flex;
    padding: 0px;
    border-radius: 0px;
    border: none;
    width: auto;
    box-shadow: none !important;

    .priority-button {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      padding: 0px;
      padding-bottom: 10px !important;
      border-radius: 0px;
      box-shadow: none;
      width: auto;
      padding-right: 0px !important;
      margin-right: 30px !important;
      background-color: transparent !important;
      @include themed() {
        color: t('color-font-title') !important;
        border-bottom: 1px solid transparent !important;
      }

      span {
        font-size: 16px;
        line-height: 28px;
        @include themed() {
          color: t('color-font-title') !important;
        }
      }

      .item-description {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        padding-top: 0px;
        padding-bottom: 8px;
        @include themed() {
          color: t('color-font-text') !important;
        }
      }

      .item-unread-count {
        position: absolute;
        top: 4px;
        right: -6px;
        margin-left: auto;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 20px;
        padding: 0px 6px;
        height: 20px;
        border-radius: 20px;
        transform: translate(100%, 0%);
        @include themed() {
          color: t('color-primary');
          background-color: t('color-primary-light');
        }

        &-dot {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          margin-right: 4px;
          @include themed() {
            background-color: t('color-primary');
          }
        }
      }

      &--active {
        @include themed() {
          color: t('color-primary-black') !important;
          border-bottom: 3px solid t('color-primary-black') !important;
        }

        span {
          @include themed() {
            color: t('color-primary-black') !important;
          }
        }

        .item-description {
          @include themed() {
            color: t('color-primary-black') !important;
          }
        }
      }
    }
  }
}

.small-tabs {
  width: 100%;
  @include themed() {
    border-bottom: 1px solid t('border-color');
    padding-bottom: 0px;
    margin-bottom: 20px;
  }

  .filter-selection {
    display: inline-flex;
    padding: 0px;
    border-radius: 0px;
    border: none;
    width: auto;
    box-shadow: none !important;

    .priority-button {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;
      line-height: 22px;
      font-weight: bold;
      padding: 0px;
      padding-bottom: 10px !important;
      border-radius: 0px;
      box-shadow: none;
      width: auto;
      padding-right: 0px !important;
      margin-right: 20px !important;
      background-color: transparent !important;
      @include themed() {
        color: t('color-font-title') !important;
        border-bottom: 1px solid transparent !important;
      }

      .item-description {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        padding-top: 0px;
        padding-bottom: 8px;
        @include themed() {
          color: t('color-font-text') !important;
        }
      }

      &--active {
        @include themed() {
          color: t('color-primary') !important;
          border-bottom: 3px solid t('color-primary') !important;
        }

        .item-description {
          @include themed() {
            color: t('color-primary') !important;
          }
        }
      }
    }
  }
}
