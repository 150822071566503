@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.user-type-dropdown-selection {
  margin-right: auto;
  margin-left: 0px;

  .user-type-dropdown-selection__indicator-separator {
    display: none;
  }

  .user-type-dropdown-selection__indicators {
    display: none;
  }

  .user-type-dropdown-selection__control {
    appearance: none;
    outline: none;
    padding: 0px 5px;
    cursor: pointer;
    border-radius: $border-radius;
    font-size: $text-font-size;
    border: 0px solid transparent !important;

    @include themed() {
      background-color: t('color-light-gray');
      color: t('color-font-title');
      box-shadow: none !important;
      border: 0px solid t('color-light-gray') !important;
    }

    @include for-mobile-tablet-only {
      font-size: $text-font-size;
    }

    &:hover {
      @include themed() {
        color: t('color-primary');
        background-color: t('color-primary-light');
      }
    }
  }

  &__value-container {
    display: flex !important;
    white-space: nowrap;
    flex-shrink: 0;
    flex-wrap: nowrap !important;
  }

  &__placeholder {
    font-size: $medium-text-font-size;
    font-weight: 600;
    @include themed() {
      color: t('color-font-title') !important;
    }
  }

  &__single-value {
    font-weight: 600;
    @include themed() {
      color: t('color-font-title') !important;
    }
  }

  &__menu {
    @include themed() {
      background-color: t('color-bg');
      border: 0px solid t('color-gray');
      color: t('color-font-title');
    }
    box-shadow: rgba(12, 12, 12, 0.05) 0px 0px 0px 1px,
      rgba(12, 12, 12, 0.1) 0px 3px 6px, rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
    border-radius: $border-radius !important;
    font-size: $medium-text-font-size !important;
    padding: 0px;
    margin: 0px;
  }

  &__menu-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .option-list-item:first-of-type {
      border-top-left-radius: $border-radius !important;
      border-top-right-radius: $border-radius !important;
      padding-top: 10px;
    }

    .option-list-item:last-of-type {
      border-bottom-left-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
      padding-bottom: 10px;
    }

    .option-list-item {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      font-weight: 600;
      font-size: $medium-text-font-size !important;

      &:hover {
        @include themed() {
          color: t('color-font-title');
          background-color: t('color-light-gray');
        }
      }
    }
  }
}

.user-type-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $medium-text-font-size;
  font-weight: 600;

  i {
    margin-right: 10px;
  }
}
