@import 'styles/scss-variable.scss';

.user-avatar {
  width: 34px;
  height: 34px;
  min-width: 34px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00000066;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  position: relative;

  @include themed() {
    background-color: t('color-bg');
  }

  &-user-online {
    position: absolute;
    bottom: 5px;
    left: 25px;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    @include themed() {
      border: 1px solid t('color-bg');
      background-color: t('color-green');
    }
  }

  &--small {
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 100%;
    font-size: 12px;

    .user-avatar-user-online {
      bottom: 0px;
      left: 18px;
    }
  }

  &--big {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 100%;
    font-size: 12px;
  }

  img, svg {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 100%;
    object-fit: cover;
  }
}

.editable-gravatar {
  overflow: visible !important;
  border-radius: 50% !important;

  .image {
    border-radius: 50% !important;
  }

  .file-picker {
    right: -6px;
    bottom: -6px;
  }
}
