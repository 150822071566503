@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.translation-header {
  padding: 0px !important;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  .text-href {
    min-height: 40px;
    line-height: 40px;
    margin-right: 20px;
  }
}

.translation-table {
  padding: 0px;
  margin-top: 0px;
  overflow-y: auto;
  width: 100%;
  border-radius: $input-border-radius;

  .translation-editor-row {
    width: 100%;
    display: flex;
    @include themed() {
      border-bottom: 1px solid t('border-color');
    }

    &:last-of-type {
      border-bottom: none !important;
    }
  }

  .translation-editor-cell {
    width: 50%;
    display: flex;
    padding: 12px 0px;

    .input-label {
      display: none !important;
    }

    b {
      @include themed() {
        font-weight: 700;
        margin-left: 5px;
        color: t('color-font-title');
      }
    }

    @include themed() {
      color: t('color-font-text');
    }
  }
}

.language-translation-selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  margin-left: 25px;

  &-label {
    margin-right: 10px;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .creatableselect {
    width: 100px;
    z-index: 9999;
  }
}
