@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.outbounddetails-container {
  display: flex;
  flex-direction: column;
  padding-top: 0px !important;
  padding-bottom: 50px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  max-width: 1100px;
  margin: auto;
  width: 100%;

  @include for-mobile-tablet-only {
    padding: 10px !important;
  }

  .stat-card-body {
    min-height: 150px;
  }
  
  .stat-card-header {
    margin-bottom: 0px;
  }

  &--responses {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}


.responses-tab {
  .responses-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: -20px;
  }

  .responses-list {
    .virtual-list-table {
      height: calc(100vh - 340px);
    }

    .cell-link {
      padding-left: 20px;
    }
  }

  .responses-loading {
    padding: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.survey-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 20px;

  @include for-mobile-tablet-only {
    grid-template-columns: 1fr;
  }

  .stat-card-body {
    min-height: 150px;
  }
}

.outbound-status-tag {
  display: inline-block;
  text-transform: capitalize;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  margin-left: 10px;
  padding: 5px 8px;
  border-radius: 8px;
  line-height: 14px;

  @include themed() {
    background-color: t('color-gray');
    color: t('color-font-title');
  }

  &--green {
    color: #0f8a40 !important;
    background-color: #d7efdc !important;
  }

  &--primary {
    @include themed() {
      background-color: t('color-primary');
      color: t('button-color');
    }
  }
}

.outbound-message-overview {
  display: flex;
  justify-content: space-between;

  @include for-mobile-tablet-only {
    flex-direction: column;
  }

  .message-stats {
    width: calc(50% - 20px);
    @include for-mobile-tablet-only {
      width: 100%;
    }
  }

  .message-preview {
    width: calc(50% - 20px);
    @include for-mobile-tablet-only {
      width: 100%;
      margin-bottom: 20px;
    }
    
    .outbound-message-container {
      height: 100%;
    }
  }
}

.outbound-email-overview {
  display: flex;
  justify-content: space-between;

  @include for-mobile-tablet-only {
    flex-direction: column;
  }

  .message-stats {
    width: calc(35% - 20px);

    @include for-mobile-tablet-only {
      width: 100%;
    }
  }

  .message-preview {
    width: calc(65% - 20px);
    
    .outbound-message-container {
      height: 100%;
    }
    
    @include for-mobile-tablet-only {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.outbound-response {
  display: flex;
  padding-top: 7px;
  padding-bottom: 14px;
  align-items: center;
  @include themed() {
    border-bottom: 1px solid t('border-color');
  }

  &-main {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .session-item-cell {
      background-color: transparent !important;
    }

    .cell-link {
      margin-right: 40px;
    }
  }

  &-date {
    padding-right: 20px;
    font-size: 14px;
    @include themed() {
      color: t('color-font-text');
    }
  }
}