@import 'styles/scss-variable.scss';

.action-editor-container {
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e8e8e8;
  padding: 16px;
  border-radius: 6px;
  width: 340px;

  .action-target {
    opacity: 0;
    cursor: pointer;
    transform: translate(0px, 0px);
    top: 23px;
    left: -12px;
    width: 23px;
    height: 23px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    i {
      font-size: 12px;
      pointer-events: none;
    }
  }

  &:hover {
    .action-target {
      opacity: 1;
    }
  }

  &:hover .node-delete-button {
    display: flex;
  }

  .node-delete-button {
    display: none;
    position: absolute;
    top: -25px;
    right: 0px;
    padding: 5px;
    cursor: pointer;
    background-color: transparent;

    i {
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
      border: 1px solid #e8e8e8;
      background-color: #fff;
      padding: 6px;
      font-size: 13px;
      @include themed {
        color: t('color-font-text');
      }
    }

    &:hover {
      i {
        @include themed {
          color: t('color-red');
        }
      }
    }
  }

  .action-item-container {
    position: relative;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 0px;

    @include themed {
      border: 1px solid t('border-color');
    }

    .text {
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }

    &:hover {
      @include themed {
        border: 1px solid t('color-primary');
      }
    }

    &:hover .hover-button {
      display: flex;
    }

    .hover-button {
      display: none;
      position: absolute;
      top: 13px;
      right: 13px;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;

      i {
        font-size: 13px;
        @include themed {
          color: t('color-font-text');
        }
      }

      &:hover {
        i {
          @include themed {
            color: t('color-red');
          }
        }
      }
    }

    &--buttons {
      padding: 0px 0px;
      @include themed {
        border: 0px solid transparent !important;
      }

      .hover-button {
        right: initial !important;
        left: 0px !important;
        top: 0px !important;
      }
    }

    &--condition {
      padding: 0px 0px;
      @include themed {
        border: 0px solid transparent !important;
      }

      .hover-button {
        right: initial !important;
        right: 0px !important;
        top: 0px !important;
      }
    }

    .message-editor {
      width: 100%;
      border-radius: 6px;
      padding: 4px 0px;

      @include themed {
        border: 1px solid t('border-color');
      }
    }

    .button-actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-end;

      .button-action {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;

        .button-handle {
          cursor: pointer;
          transform: translate(24px, -50%);
          width: 23px;
          height: 23px;
          background-color: #fff;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 12px;
            pointer-events: none;
          }
        }

        .button-input {
          padding: 8px 14px;
          line-height: 20px;
          box-sizing: border-box;
          border: none;
          border-radius: 6px;

          @include themed {
            color: t('color-primary');
            background-color: rgba(t('color-primary'), 0.1);
          }
        }

        .button-action-selector {
          position: absolute;
          right: -30px;
          top: 20%;
          cursor: pointer;
        }
      }

      .add-button-action {
        width: 50%;
      }
    }

    .conditions-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      .icon-container {
        width: 30px;
        height: 30px;
        border-radius: 8px;
        background-color: #ffd200;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 14px;
        }
      }

      &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: 600;

        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .condition-actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;

      border-left: solid 2px #aeaeae;

      .condition-item {
        position: relative;
        width: calc(100% - 22px);
        margin-left: 22px;
        margin-bottom: 10px;
        border-radius: 10px;
        padding: 10px;

        @include themed {
          border: 1px solid t('border-color');
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 50%;
          left: -25px;
          width: 22px;
          background: 0 0;
          border-left: solid 2px #aeaeae;
          border-bottom: solid 2px #aeaeae;
          border-bottom-left-radius: 12px;
        }

        &:hover .condition-hover-button {
          display: flex;
        }

        .condition-hover-button {
          display: none;
          position: absolute;
          justify-content: center;
          align-items: center;
          top: 0px;
          bottom: 0px;
          height: 100%;
          right: 5px;
          padding: 5px;
          border-radius: 4px;
          cursor: pointer;

          i {
            font-size: 13px;
            @include themed {
              color: t('color-font-text');
            }
          }

          &:hover {
            i {
              @include themed {
                color: t('color-red');
              }
            }
          }
        }
      }
    }

    .condition-item-else {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: calc(100% - 24px);
      margin-left: 24px;
      padding: 10px;
      border: 1px solid transparent;

      @include themed() {
        color: t('color-font-title');
      }

      .else-stroke {
        height: 2px;
        background-color: #aeaeae;
        border-radius: 1px;
        width: 82%;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -2px;
        bottom: 50%;
        left: -25px;
        width: 22px;
        background: 0 0;
        border-left: solid 2px #aeaeae;
        border-bottom: solid 2px #aeaeae;
        border-bottom-left-radius: 12px;
      }
    }

    .condition-handle {
      cursor: pointer;
      transform: translate(24px, -50%);
      width: 23px;
      height: 23px;
      background-color: #fff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 12px;
        pointer-events: none;
      }
    }
  }

  .add-action-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-outer {
      position: relative;
    }

    .line {
      height: 2px;
      width: 50%;

      @include themed {
        background-color: t('border-color');
      }

      &:first-child {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
      }

      &:last-child {
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
      }
    }

    .icon {
      @include themed {
        color: t('border-color');
      }
    }

    &:hover .line {
      @include themed {
        background-color: t('color-primary');
      }
    }

    &:hover .icon {
      @include themed {
        color: t('color-primary');
      }
    }
  }

  &-endtag {
    display: flex;
    justify-content: flex-end;

    span {
      font-size: 11px;
      font-weight: bold;
      color: #000;
      padding: 4px 6px;
      display: block;
      background-color: #eee;
      border-radius: 6px;
    }
  }

  &-starttag {
    display: flex;
    position: absolute;
    top: 24px;
    left: 0px;
    transform: translate(calc(-100% - 10px), 0px);

    span {
      font-size: 11px;
      font-weight: bold;
      color: #4fc47e;
      padding: 4px 6px;
      display: block;
      background-color: #d0f3de;
      border-radius: 6px;

      i {
        font-size: 11px;
        margin-left: 3px;
      }
    }
  }
}

.bot-action-option-item {
  cursor: pointer;
  display: flex;
  border-radius: $input-border-radius;
  padding: 10px;

  &-icon {
    width: 30px;
    height: 30px;
    margin: 0px;
    margin-right: 10px;
    border-radius: 8px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    i {
      font-size: 14px;
    }
  }

  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 600;

    @include themed() {
      color: t('color-font-title');
    }
  }
}

.action-answerbot {
  span {
    font-size: 14px;
    font-weight: 700;
  }
}

.action-ticket-tags {
  display: flex;
  overflow-x: hidden;
  flex-wrap: wrap;
  align-items: center;

  .tag {
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 8px;
    margin: 2px 2px;
    font-size: 13px;
    font-weight: 600;
    flex-wrap: nowrap;
    white-space: nowrap;

    @include themed() {
      color: t('color-font-title');
      background-color: t('color-light-gray');

      i {
        color: t('color-font-title');
      }
    }
  }
}

.action-ticket-assign {
  display: flex;
  overflow-x: hidden;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: nowrap;
  white-space: nowrap;

  @include themed() {
    color: t('color-font-title');
  }

  .user-avatar--small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
