@import 'styles/scss-variable.scss';

.apikey {
  @include themed() {
    border: 1px solid t('border-color');
    color: t('color-font-text');
  }
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $max-xl;
  border-radius: $border-radius;
  padding: 11px 15px;

  .key {
    overflow: hidden;
    text-align: center;
    padding: 2px 16px;
    @include themed() {
      color: t('color-font-title') !important;
    }
  }
}

.api-key-icon {
  opacity: 0.2;
  font-size: 20px;
  @include themed() {
    color: t('color-font-text');
  }
}

.copy-button {
  height: 22px;
  opacity: 0.7;
  @include themed() {
    filter: t('image-filter');
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
