@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.react-tag-input {
  border-radius: 10px !important;
  box-shadow: none !important;
  background: none !important;
  padding: 5px !important;
  min-height: 51px;
  @include themed() {
    border: 1px solid t('border-color');
  }

  &:focus-within {
    @include themed() {
      border: 1px solid t('color-primary-black');
    }
  }
}

.react-tag-input__tag {
  border-radius: 8px !important;
  color: #3d4150 !important;
  padding: 5px !important;
  @include themed() {
    background-color: t('border-color');
  }

  &:hover {
    @include themed() {
      background-color: t('color-primary-black');

      .react-tag-input__tag__content {
        color: t('color-primary-inverted') !important;
      }

      .react-tag-input__tag__remove::before,
      .react-tag-input__tag__remove::after {
        background-color: t('color-primary-inverted') !important;
      }
    }
  }
}

.react-tag-input__tag__remove {
  background: none !important;
}

.react-tag-input__tag__remove::before {
  background-color: #3d4150 !important;
}

.react-tag-input__tag__remove::after {
  background-color: #3d4150 !important;
}

.react-tag-input__tag__content {
  @include themed() {
    color: t('color-font-text') !important;
  }
}

.react-tag-input__input {
  @include themed() {
    color: t('color-font-text') !important;
  }

  @include for-mobile-tablet-only {
    font-size: $text-font-size !important;
  }
}

.react-tag-input__input::placeholder {
  @include themed() {
    color: t('color-font-text') !important;
  }
}
