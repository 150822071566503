@import 'styles/scss-variable.scss';

.paragraph {
  @include themed() {
    color: t('color-font-text');
  }
  margin-bottom: 15px;
  margin-top: 15px;

  b {
    font-weight: 600;
    @include themed() {
      color: t('color-font-title');
    }
  }

  img {
    width: 100%;
    border-radius: $border-radius;
    margin-top: 10px;
  }

  code {
    @include themed() {
      background-color: t('color-gray');
      color: t('color-font-text');
    }
    height: auto;
    border-radius: $border-radius;
    padding: 3px 8px;
  }
}
