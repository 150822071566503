@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.contact-details {
  display: flex;
  flex-direction: column;
}

.contact-details-container {
  width: 100%;
}

.lock {
  margin-right: 5px;
  height: 22px;
  width: auto;
  &-rev {
    @include themed() {
      filter: t('image-filter');
    }
  }
}

.no-activities-yet {
  text-align: center;
  font-size: 16px;
  @include themed() {
    color: t('color-font-title');
  }
}

.contact-details-header {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 240px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 30px;
  border-top-left-radius: 16px;
  @include themed() {
    background: t('color-light-gray');
  }

  .contact-details-header-actions {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .link-button {
      margin-right: 20px;
      height: 40px;
      line-height: 40px;
    }
  }
}

.contact-details-container-inner {
  width: 100%;
  height: 100%;
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: flex-start;
  column-gap: 0px;
  flex-grow: 1;

  @include for-mobile-tablet-only {
    grid-template-columns: 1fr;
  }

  > div {
    height: calc((var(--vh,1vh)*100) - 68px);
    overflow-y: auto;
  }
}

.feedback-item-activity {
  padding: 40px;
}

.contact-details-card {
  width: 100%;
  justify-content: flex-start;
  box-shadow: none !important;
  border-radius: 0px !important;
  padding: 20px;
  @include themed() {
    background-color: t('color-bg');
    border: none;
    border-right: 1px solid t('border-color');
  }

  &-title {
    width: 100%;
    text-align: center;
    font-family: $text-font-family;
    font-size: $text-font-size;
    font-weight: 600;
    padding: 15px 10px;
    @include themed() {
      color: t('color-font-title');
      border-bottom: 1px solid t('border-color');
    }
  }

  &-footer {
    width: 100%;
    text-align: center;
    font-family: $text-font-family;
    font-size: $small-text-font-size;
    padding: 20px;
    @include themed() {
      color: t('color-font-text');
      border-top: 1px solid t('border-color');
    }
  }

  &-rows {
    width: 100%;
  }

  &-value {
    padding: 30px 20px;
    font-family: $text-font-family;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    @include themed() {
      color: t('color-font-title');
    }

    &-row {
      width: 100%;
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 12px;
      padding: 8px 15px;

      &-key {
        width: 100%;
        word-break: break-all;
        height: 26px;
        max-height: 26px;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 26px;
        font-family: $text-font-family;
        font-size: 14px;
        @include themed() {
          color: t('color-font-text');
        }

        time {
          line-break: normal;
          margin-left: 10px;
        }

        a {
          @include themed() {
            color: t('color-primary');
          }
        }
      }

      &-value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 100px;
        width: 100%;
        font-family: $text-font-family;
        font-size: 14px;
        font-weight: 500;
        text-align: right;
        @include themed() {
          color: t('color-font-title');
        }
        overflow: auto;
        line-height: 26px;

        span,
        a,
        time {
          line-break: normal;
          font-size: 14px;
        }
      }

      &:not(:last-child) {
        @include themed() {
          border-bottom: 1px solid t('border-color');
        }
      }
    }
  }

  &-no-value {
    padding: 30px 20px;
    font-family: $text-font-family;
    font-size: $text-font-size;
    text-align: center;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.log-data-details {
  padding: 8px;
  border-radius: $border-radius;
  max-width: 100%;
  @include themed() {
    background-color: t('color-gray');
  }
}
