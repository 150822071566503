@import 'styles/scss-variable.scss';

.drag-and-drop-buttons {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .drag-and-drop-icon-container {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include themed() {
      background-color: t('color-primary');
    }

    .icon {
      width: 60%;
      color: white;
    }
  }
}

.feedback-type-selection {
  .dropdown-selection__control {
    @include themed() {
      border: 1px solid t('border-color') !important;
    }
  }
}
