@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.edit-inbox-view-modal {
  > div {
    width: 100%;
  }
}

.emoji-title {
  display: flex;

  .textinput-gray {
    border-radius: 0 $input-border-radius $input-border-radius 0 !important;
  }
}

.emoji-picker-container {
  position: relative;
  margin-right: 0px;

  .emoji-picker-inner {
    cursor: pointer;
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0px;
    flex: 1;
    border: 0 solid;
    font-size: 20px;
    text-align: center;
    border-radius: $input-border-radius 0 0 $input-border-radius;
    @include themed() {
      border: 1px solid t('border-color');
      border-right: 0px;
      color: t('color-font-title');
      background-color: t('color-bg');
    }

    &:hover {
      outline-width: 0;

      @include themed() {
        background-color: t('border-color');
      }
    }
  }

  .emoji-picker-cont {
    position: absolute;
    top: 50px;
    z-index: 99;
  }
}

:root, .EmojiPickerReact {
  --epr-highlight-color: #485bff !important;
  --epr-category-icon-active-color: #485bff !important;
}
