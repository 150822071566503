@import 'styles/scss-variable.scss';

.attachments {
  .attachment-image {
    width: 100%;
    max-width: 150px;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    border-radius: 12px;
    margin-right: 10px;
    margin-top: 5px;
    cursor: zoom-in;

    &:last-child {
      margin-right: 0px;
    }
  }

  .attachment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    max-width: min(50vw, 400px);
    overflow: hidden;

    &--standalone {
      a {
        @include themed() {
          background-color: t('color-light-gray');
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 15px;
      line-height: 16px;
      border-radius: $border-radius;
      padding: 7px 13px;
      @include themed() {
        color: t('color-primary');
      }

      svg {
        width: 19px;
        min-width: 19px;
        height: 19px;
        margin-right: 7px;
        @include themed() {
          fill: t('color-primary');
        }
      }
    }
  }
}

.comment {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &--creator-hidden {
    margin-top: -15px;

    .shared-avatar {
      opacity: 0;
    }
  }

  .comment-body {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;

    .shared-avatar {
      position: relative;

      &--team {
        border-radius: 100%;
        @include themed() {
          box-shadow: 0px 0px 0px 2px#fff, 0px 0px 0px 4px t('color-primary');
        }
      }
    }

    .user-image {
      width: 34px;
      height: 34px;
      border-radius: 34px;
      object-fit: cover;
      @include themed() {
        border: 1px solid t('color-bg');
      }
    }

    .image-placeholder {
      width: 34px;
      height: 34px;
      @include themed() {
        border: 1px solid t('border-color');
        background-color: t('color-primary-light');
        color: t('color-font-text');
      }
      border-radius: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .body {
      margin-left: 10px;
      margin-right: 10px;
      max-width: min(80%, 600px);
      border-radius: $border-radius;
      padding: 13px 17px;
      @include themed() {
        background-color: t('color-primary-light');
      }

      .comment-text {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 14px;
        line-height: 22px;
        word-break: break-word;
        @include themed() {
          color: t('color-font-title');
        }

        &-question {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
        }

        .ProseMirror {
          max-height: none !important;
          overflow-y: initial !important;

          li > p,
          > p {
            margin-top: 3px;
            margin-bottom: 3px;
            font-size: 14px;
            line-height: 1.4;
          }

          h1 {
            margin-top: 10px;
            margin-bottom: 3px;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.4;
  
            span {
              font-weight: 700;
              font-size: 18px;
              line-height: 1.4;
            }
          }
  
          h2 {
            margin-top: 10px;
            margin-bottom: 3px;
            font-weight: 700;
            font-size: 16px;
            line-height: 1.4;
  
            span {
              font-size: 16px;
              line-height: 1.4;
              font-weight: 700;
            }
          }

          pre, ul {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        a,
        .mention {
          @include themed() {
            color: t('color-font-title');
          }
          font-size: 14px;
          text-decoration: underline;
        }

        svg {
          @include themed() {
            fill: t('color-font-title');
          }
        }
      }

      .comment-time {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        font-size: 13px;
        line-height: 13px;
        font-weight: $headline-semi-bold;
        text-align: right;
        padding-right: 0px;
        padding-left: 0px;
        margin-top: 5px;
        @include themed() {
          color: rgba(t('color-font-title'), 0.7);
        }

        .shared-team-member {
          padding-right: 10px;
          font-size: 13px;
          line-height: 13px;
          font-weight: $headline-semi-bold;
          @include themed() {
            color: rgba(t('color-font-title'), 0.7);
          }
        }

        time {
          font-size: 13px;
          line-height: 13px;
          padding-left: 5px;
          padding-right: 5px;
          line-break: normal;
          @include themed() {
            color: rgba(t('color-font-title'), 0.7);
          }
        }

        .seen {
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 13px;
          padding-right: 0px;
          @include themed() {
            color: rgba(t('color-font-title'), 0.7);
          }

          i {
            font-size: 12px;
            padding-right: 5px;
          }
        }

        .icon {
          opacity: 0.7;
          height: 13px;
          width: auto;
          margin-right: 4px;

          @include themed() {
            filter: t('image-filter');
          }
        }
      }
    }

    .trash-icon-comment-container {
      display: none;
      width: 14px;
      cursor: pointer;
      opacity: 1;
      justify-content: center;
      align-items: center;
      margin-left: 0px;
      margin-right: 0px;
      flex-shrink: 0;

      i {
        font-size: 14px;
        @include themed() {
          color: t('color-font-text');
        }

        &:hover {
          @include themed() {
            color: t('color-primary');
          }
        }
      }
    }
  }

  .comment-footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    line-height: 13px;
    font-weight: $headline-semi-bold;
    text-align: right;
    padding-right: 38px;
    padding-left: 38px;
    @include themed() {
      color: t('color-font-text');
    }

    .shared-team-member {
      padding-right: 10px;
      font-size: 13px;
      line-height: 13px;
      font-weight: $headline-semi-bold;
      @include themed() {
        color: t('color-primary');
      }
    }

    time {
      font-size: 13px;
      line-height: 13px;
      padding-left: 5px;
      padding-right: 5px;
      line-break: normal;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .seen {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 13px;
      padding-right: 0px;
      @include themed() {
        color: t('color-font-text');
      }

      i {
        font-size: 12px;
        padding-right: 5px;
      }
    }

    .icon {
      opacity: 0.7;
      height: 13px;
      width: auto;
      margin-right: 4px;

      @include themed() {
        filter: t('image-filter');
      }
    }
  }

  &--is-note {
    .body {
      @include themed() {
        background-color: t('note-bg') !important;
      }

      .comment-text {
        @include themed() {
          color: t('color-font-title') !important;
        }

        a,
        .mention {
          @include themed() {
            color: t('color-font-title') !important;
          }
        }

        svg {
          @include themed() {
            fill: t('color-font-title') !important;
          }
        }
      }

      .comment-time {
        @include themed() {
          color: t('color-font-text') !important;
        }

        .shared-team-member {
          @include themed() {
            color: t('color-font-text') !important;
          }
        }

        time {
          @include themed() {
            color: t('color-font-text') !important;
          }
        }

        .seen {
          @include themed() {
            color: t('color-font-text') !important;
          }
        }
      }
    }
  }

  &--external {
    .comment-body {
      flex-direction: row;
    }

    .comment-footer {
      justify-content: flex-start;
      align-items: center;
    }

    .body {
      @include themed() {
        background-color: t('color-light-gray') !important;
      }

      .comment-time {
        @include themed() {
          color: t('color-font-text') !important;
        }

        .shared-team-member {
          @include themed() {
            color: t('color-font-text') !important;
          }
        }

        time {
          @include themed() {
            color: t('color-font-text') !important;
          }
        }

        .seen {
          @include themed() {
            color: t('color-font-text') !important;
          }
        }
      }

      .comment-text {
        @include themed() {
          color: t('color-font-title') !important;
        }

        a,
        .mention {
          @include themed() {
            color: t('color-font-title') !important;
          }
        }

        svg {
          @include themed() {
            fill: t('color-font-title') !important;
          }
        }
      }
    }
  }

  &--system {
    margin-top: 20px;
    margin-bottom: 20px;

    .comment-statusupdate {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      line-height: 22px;
      word-break: break-word;
      @include themed() {
        color: t('color-font-text');
      }

      b {
        font-size: 15px;
        line-height: 22px;
        margin-right: 5px;
        margin-left: 5px;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .user-avatar--small {
        width: 22px;
        min-width: 22px;
        height: 22px;
        border-radius: 22px;
        margin-right: 0px;
        margin-left: 5px;
      }
    }

    .comment-body {
      justify-content: center;

      .body {
        padding: 0px !important;
        @include themed() {
          background-color: transparent !important;
          border: 0px solid t('color-gray');
        }

        .comment-text {
          @include themed() {
            color: t('color-font-text') !important;
          }

          b {
            @include themed() {
              color: t('color-font-title');
            }
          }

          a,
          .mention {
            @include themed() {
              color: t('color-font-title') !important;
            }
          }

          svg {
            @include themed() {
              fill: t('color-font-title') !important;
            }
          }
        }
      }

      .user-avatar {
        @include themed() {
          color: t('button-color') !important;
        }
      }
    }

    .comment-footer {
      justify-content: center;
    }
  }

  &:hover .trash-icon-comment-container {
    display: flex;
  }
}

.user-tooltip {
  &-title {
    font-weight: 600;
    text-transform: capitalize;
    @include themed() {
      color: t('color-font-title') !important;
    }
  }
}

.comment-attachments {
  .attachment > a {
    padding: 0px;
  }
}
