@import 'styles/scss-variable.scss';

.helpcenter-conversation-article {
  padding: 16px 20px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px, rgb(0 0 0 / 6%) 0px 2px 5px;
  max-width: 500px;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid transparent;

  @include themed() {
    background-color: t('color-bg');
  }

  &--selected {
    @include themed() {
      border: 1px solid #ffd200;
    }
  }

  &:hover {
    .article-header,
    .fa-solid {
      @include themed() {
        color: t('color-primary') !important;
      }
    }
  }

  .article-header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .fa-solid {
      font-size: 13px;
    }

    .article-header {
      font-size: 14px;
      line-height: 1.25;
      font-weight: bold;
      @include themed() {
        color: t('color-font-title');
      }
    }
  }

  .article-description {
    font-size: 14px;
    line-height: 1.3;
    color: var(--secondary-color);
    margin-top: 5px;
  }
}
