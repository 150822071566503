@import 'styles/scss-variable.scss';

.compose-conversation-session {
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  min-height: 39px;
  @include themed() {
    background-color: t('color-bg');
    color: t('color-font-title');
    border-bottom: 1px solid t('border-color');
  }

  .user-avatar--small {
    width: 28px;
    min-width: 28px;
    height: 28px;
  }

  .user-avatar-user-online {
    position: absolute;
    bottom: 0px;
    left: 19px;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    @include themed() {
      border: 1px solid t('color-bg');
      background-color: t('color-green');
    }
  }

  .name {
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-transform: capitalize;
    font-weight: 600;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .type-guest-tag {
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    margin-left: 10px;
    padding: 5px 8px;
    border-radius: 8px;
    line-height: 14px;
    text-decoration: none !important;
    @include themed() {
      color: t('color-primary');
      background-color: t('color-primary-light');
    }
  }
}
