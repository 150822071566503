@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.invitation-onboarding {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  padding-top: 0px;

  @include for-mobile-tablet-only {
    padding-top: 50px;
  }

  .invitation-onboarding-body {
    width: 100%;

    &--header {
      padding-left: 30px;

      @include for-mobile-tablet-only {
        padding-left: 0px;
      }

      .title {
        font-size: 24px;
        margin-bottom: 5px;
        text-align: left;
      }

      .text {
        text-align: left;
      }
    }

    &--body {
      padding: 0px;
      position: relative;
      margin-top: 30px;
      margin-bottom: 0px;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;

      @include for-mobile-tablet-only {
        grid-template-columns: 1fr;
      }

      .role-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-height: 190px;
        background: transparent;
        border-radius: $input-border-radius;
        padding: 0px 15px;
        text-align: center;
        cursor: pointer;
        @include themed() {
          color: t('color-font-title');
          background-color: t('color-light-gray');
        }

        .role-card-icon {
          border-radius: 100%;
          width: 54px;
          height: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
          @include themed() {
            background-color: t('color-gray');
          }

          .fa-regular {
            font-size: 26px;
            @include themed() {
              color: t('color-font-title');
            }
          }
        }

        &--title {
          font-style: normal;
          font-weight: $headline-semi-bold;
          font-size: $text-font-size;
          @include themed() {
            color: t('color-font-title');
          }
        }

        &--description {
          font-style: normal;
          font-size: $small-text-font-size;
          margin-top: 2px;
          @include themed() {
            color: t('color-font-text');
          }
        }

        &:hover {
          @include themed() {
            background-color: t('color-primary-light');
          }

          .role-card--title {
            @include themed() {
              color: t('color-primary');
            }
          }

          .role-card-icon {
            @include themed() {
              background-color: t('color-primary-ultralight');
            }

            .fa-regular {
              @include themed() {
                color: t('color-primary');
              }
            }
          }
        }

        &:focus {
          outline: 0;
        }
      }
    }

    &--whoiam {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 10px;
      @include themed() {
        color: t('color-font-title');
      }
    }

    &--launch {
      font-size: 18px;
      line-height: 32px;
      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}
