@import 'styles/scss-variable.scss';

.feedback-action-question-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;

  .feedback-action-question-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    max-width: 100%;
    width: 100%;

    &--intro {
      padding-left: 38px;
    }

    &--active {
      @include themed() {
        background-color: t('color-light-gray');
      }
    }

    &-drag-icon {
      width: auto;
      height: 15px;
      flex-shrink: 0;
      margin-right: 8px;
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      @include themed() {
        fill: t('color-font-title');
      }
    }

    &-icon {
      height: 26px;
      width: auto;
      flex-shrink: 0;
      margin-right: 15px;
    }

    &-title {
      margin-right: 15px;
      flex-grow: 1;
      height: 36px;
      display: flex;
      align-items: center;

      &-inner {
        display: block;
        font-size: 14px;
        line-height: 18px;
        max-height: 36px;
        overflow: hidden;

        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    &--disabled {
      .feedback-action-question-item-title {
        opacity: 0.5;
      }
    }

    .trash-icon {
      opacity: 0;
      flex-shrink: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      @include themed() {
        color: t('color-font-text');
      }

      i {
        font-size: 14px;
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
        }
      }
    }

    &:hover {
      .trash-icon {
        opacity: 1;
      }
    }
  }
}

.feedback-action-question-item:focus {
  @include themed() {
    border: 1px solid t('color-primary');
  }
}
