@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.ai-summary {
  padding: 50px;
  border: 1px solid #ffd200;
  background-color: #ffd20011;
  box-shadow: 0px 0px 15px #ffd00055;
  position: relative;
  border-radius: $border-radius;
  margin-bottom: 40px;

  @include for-mobile-tablet-only {
    padding: 25px;
    margin-bottom: 20px;
  }

  &-inner {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  &-headline {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;
    @include themed() {
      border-bottom: solid 1px #ffd20033 !important;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.06px;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-tag {
      background-color: #ffd200;
      color: #000;
      font-weight: 700;
      height: 20px;
      line-height: 20px;
      margin-left: 10px;
      font-size: 10px;
      padding: 0px 7px;
      border-radius: 5px;
    }
  }

  &-info-head {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0.6;
    font-size: 12px;

    @include for-mobile-tablet-only {
      display: none;
    }
  }

  &-subtitle {
    font-size: 15px;
    font-weight: 500;
    box-sizing: border-box;
    opacity: 0.6;
  }

  .question-answer {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    padding: 0px;
  }

  .regenerate-info {
    width: 100%;
    margin-top: 30px;

    &-outdated {
      opacity: 0.6;
      margin-bottom: 10px;
    }

    .simple-link {
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      text-decoration: none;
      @include themed() {
        color: t('color-font-title');
      }

      i {
        margin-left: 10px;
        margin-right: 6px;
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
        }
      }
    }
  }

  .ai-loading-container {
    padding: 20px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading {
      background-color: transparent !important;
    }
  }

  .generate-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    &-label {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      text-align: center;
      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}
