@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.global-search-modal-background-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  &-blur {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    @include themed() {
      background-color: rgba($color: t('color-font-title'), $alpha: 0.4);
    }

    .global-search-container {
      display: flex;
      flex-direction: column;
      width: 628px;
      max-height: 70%;
      margin: auto;
      margin-top: 10%;
      border-radius: 12px;
      box-shadow: 0 25px 60px 10px rgba(0, 0, 0, 0.3);
      outline: none;
      @include themed() {
        background-color: rgba($color: t('color-bg'), $alpha: 0.99);
        color: t('color-font-title');
      }

      &-no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .search-results-showmore {
        cursor: pointer;
        text-decoration: none;
        font-size: 13px;
        margin-left: 20px;
        margin-top: 5px;
        @include themed() {
          color: t('color-font-text');
        }

        &:hover {
          @include themed() {
            color: t('color-font-title');
          }
        }
      }

      .filter-selection {
        border: none !important;
        border-radius: 0px !important;
        padding-left: 15px;
        justify-content: flex-start;
        @include themed() {
          border-top: 1px solid t('border-color') !important;
        }

        .priority-button {
          line-height: 12px;
          flex-grow: 0;
          width: auto;
        }
      }

      &-input-cont {
        display: flex;
        align-items: center;
        padding-right: 20px;
      }

      &-input {
        display: block;
        height: 56px;
        min-height: 56px;
        width: 100%;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjIuNyAyMi41IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi43IDIyLjUiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2NzY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgY3g9IjguMSIgY3k9IjguNCIgcj0iNi44Ii8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2NzY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9IjEzLjIiIHkxPSIxMi45IiB4Mj0iMjEuNSIgeTI9IjIxLjQiLz48L3N2Zz4=);
        background-position: left 17px center;
        background-repeat: no-repeat;
        background-size: 3.5%;
        padding: 0 52px;
        font-size: 1.6rem;
        appearance: none;
        border: none;
        background-color: transparent;
        outline: none;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .grouped-search-result-list-wrapper {
        overflow-y: auto;
        @include themed() {
          border-top: 1px solid t('border-color');
        }

        .grouped-search-result-list {
          margin: 0px;
          padding: 5px 0px;
          margin-bottom: 10px;
          padding-top: 10px;
          @include themed() {
            border-top: 1px solid t('border-color');
          }

          &:first-of-type {
            border-top: none !important;
          }

          > .text {
            margin: 0px 20px;
            padding-bottom: 2px;
            padding-top: 5px;
          }

          &-item {
            margin: 0px 10px;
            display: flex;
            flex-direction: row;
            padding: 7px 10px;
            cursor: pointer;
            border-radius: 4px;

            .type-icon > * {
              width: 17px;
              height: 17px;
              margin-right: 8px;
              @include themed() {
                fill: t('color-font-title');
              }

              fill,
              path {
                @include themed() {
                  fill: t('color-font-title');
                }
              }
            }

            .item-label {
              width: calc(100% - 20px);
              @include themed() {
                color: t('color-font-title');
              }

              .item-label-session {
                display: flex;
                width: 100%;
                @include themed() {
                  color: t('color-font-title');
                }

                &-name {
                  padding-right: 5px;
                  text-transform: capitalize;
                  @include themed() {
                    color: t('color-font-title');
                  }
                }

                &-info {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  @include themed() {
                    color: t('color-font-text');
                  }

                  .fa-shield-check {
                    @include themed() {
                      color: t('color-primary');
                    }
                  }
                }
              }

              .item-label-bug {
                display: flex;
                width: 100%;
                @include themed() {
                  color: t('color-font-title');
                }

                &-title {
                  max-width: 100%;
                  flex-grow: 1;
                  white-space: nowrap;
                  padding-right: 20px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  @include themed() {
                    color: t('color-font-title');
                  }
                }

                &-status {
                  padding: 3px 6px;
                  font-size: 12px;
                  line-height: 14px;
                  font-weight: 600;
                  flex-shrink: 0;
                  border-radius: 4px;
                  @include themed() {
                    color: t('color-font-title');
                  }
                }

                &-archieved {
                  padding: 3px 6px;
                  font-size: 12px;
                  line-height: 14px;
                  font-weight: 600;
                  flex-shrink: 0;
                  border-radius: 4px;
                  margin-left: 4px;
                  @include themed() {
                    color: t('color-font-text');
                    background-color: t('color-gray');
                  }
                }
              }
            }

            &:hover {
              @include themed() {
                background-color: t('color-gray');
              }
            }
          }
        }
      }
    }
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
