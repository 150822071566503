@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-requests-order-filter {
  margin-bottom: 0px !important;
  margin-right: 0px;

  .dropdown-selection__control {
    min-width: auto !important;
  }

  .dropdown-selection__control {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) !important;
    @include themed() {
      border: 1px solid t('border-color') !important;
    }

    @include for-mobile-tablet-only {
      font-size: $text-font-size;
    }
  }
}

.order-prio-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: $small-text-font-size;
  max-width: 100%;

  i {
    font-size: 16px;
    padding-left: 5px;
    padding-right: 10px;
  }

  span {
    font-size: $small-text-font-size;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include themed() {
      color: t('color-font-title');
    }
  }
}
