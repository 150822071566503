@import 'styles/scss-variable.scss';

.bot-condition-picker {
  display: flex;
  flex-direction: row;

  .if-preview {
    height: 100%;
    padding: 10px 20px;
    margin-top: 9px;
    margin-right: 5px;
    font-size: $text-font-size;
    font-weight: $headline-semi-bold;
    border-radius: $input-border-radius;
    @include themed() {
      color: t('color-font-text');
      border: 1px solid t('color-light-gray');
    }
  }

  .condition-picker-conditions {
    display: flex;
    flex-wrap: wrap;

    &-operator {
      margin-right: 5px;
      border-radius: $input-border-radius;
      font-size: $log-text-font-size;
      font-weight: $headline-semi-bold;
      border: 0px solid transparent !important;
      min-height: 38px;
      line-height: 38px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .dropdown-selection {
    margin-bottom: 0px !important;
  }

  .dropdown-selection__control {
    min-width: auto !important;
    min-height: 38px !important;
    background-color: transparent !important;

    &:hover {
      @include themed() {
        background-color: t('color-gray');
      }
    }

    .dropdown-selection__value-container {
      padding-top: 0px !important;
      padding: 0px !important;
      font-weight: bold;
    }

    .dropdown-selection__indicators {
      display: none;
    }
  }

  .option-list-item {
    i {
      font-size: 14px;
      margin-left: 5px;
      margin-right: 10px !important;
    }

    font-size: 14px;
    font-weight: 600;
    width: 280px;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-selection
    .dropdown-selection__control
    .dropdown-selection__placeholder {
    @include themed() {
      color: t('color-primary') !important;
    }

    &:hover {
      @include themed() {
        color: t('color-primary-highlight') !important;
      }
    }
  }

  .dropdown-selection__placeholder {
    padding-left: 0px;
    margin-left: 10px;
  }

  .dropdown-selection__menu {
    min-width: 280px;
  }
}

.default-condition-tag {
  padding: 5px 15px;
  border-radius: $input-border-radius;
  cursor: pointer;
  font-size: $text-font-size;

  @include themed() {
    background-color: t('color-light-gray');
    color: t('color-font-title');
    box-shadow: none !important;
    border: 0px solid t('color-light-gray') !important;
  }
}

.bot-root-conditions {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;

    .bot-child-predicates {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 10px;

      @include themed() {
        background-color: t('color-light-gray');
      }
    }
  }
}

.child-condition-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
