@import '~sweetalert2/src/variables';
@import 'scss-variable.scss';

.swal2-container {
  @include themed() {
    background-color: t('modal-background-color') !important;
  }

  .swal2-title {
    font-family: $h1-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: $h2-font-size;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.06px;

    @include themed() {
      color: t('color-font-title');
    }
  }

  .swal2-popup {
    width: auto;
    padding: 40px;
    max-width: 450px !important;
    border-radius: $border-radius;

    @include themed() {
      background-color: t('color-bg');
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .swal2-header {
      display: none !important;
    }

    .swal2-input {
      height: 44px;
      background-color: transparent;
      box-sizing: border-box;
      letter-spacing: -0.02em;
      appearance: none !important;
      border: 0 solid;
      font-size: $small-text-font-size;
      border-radius: $input-border-radius;
      box-shadow: none !important;
      @include themed() {
        border: 1px solid t('border-color');
        color: t('color-font-title');
      }

      &:focus {
        outline-width: 0;

        @include themed() {
          border: 1px solid t('color-primary-black');
        }
      }
    }

    .swal2-html-container {
      font-size: $text-font-size;
      font-weight: 500;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .swal2-actions {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    .swal2-actions button {
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-light-gray');
      }
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      font-style: normal;
      font-weight: 600;
      padding: 0px 20px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      height: 42px;
      line-height: 42px;
      border-radius: 10px;
      box-shadow: none;
      background-image: none !important;

      &:hover {
        @include themed() {
          background-color: t('color-primary-light');
          color: t('color-primary');
        }
      }

      &.swal2-confirm {
        @include themed() {
          background-color: t('color-primary-black');
          color: t('color-primary-inverted');
          box-shadow: none;
        }

        &:hover {
          @include themed() {
            background: t('color-primary-black-light');
            color: t('color-primary-inverted-light');
          }
        }
      }
    }
  }
}

@import '~sweetalert2/src/sweetalert2';
