@import 'styles/scss-variable.scss';

.stat-card {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  margin-bottom: 30px;
  padding: 25px;
  width: 100%;
  @include themed() {
    border: 1px solid t('border-color');
  }

  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 250px;

    &-title {
      font-family: $text-font-family;
      font-size: 38px;
      font-weight: 600;
      line-height: 50px;

      @include themed() {
        color: t('color-font-title');
      }
    }

    &-subtitle {
      font-family: $text-font-family;
      font-size: $text-font-size;
      font-weight: normal;
      margin-top: 5px;

      @include themed() {
        color: t('color-font-text');
      }
    }

    .stat-card-change {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 20px;
      padding: 0px 6px;
      height: 20px;
      border-radius: 20px;
      @include themed() {
        background-color: t('color-gray');
        color: t('color-font-text');
      }

      &-minus {
        @include themed() {
          background-color: t('color-red');
          color: #fff;
        }
      }

      &-plus {
        @include themed() {
          background-color: t('color-green');
          color: #fff;
        }
      }
    }
  }

  &-header {
    margin-top: -25px;
    margin-left: -25px;
    margin-right: -25px;
    padding: 12px;
    margin-bottom: 25px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    font-size: $text-font-size;
    font-weight: $headline-semi-bold;
    text-align: start;
    @include themed() {
      color: t('color-font-title');
    }

    span {
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }
    
    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: $text-font-family;
      font-size: $text-font-size;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        font-size: 14px;
        margin-right: 5px;
        border-radius: 5px;
        @include themed() {
          color: t('color-primary');
          background-color: t('color-primary-light');
        }
      }
    }

    &-subtitle {
      font-family: $text-font-family;
      font-size: $small-text-font-size;
      font-weight: normal;
      margin-top: 5px;

      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}
