@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.closed-navigation {
  bottom: unset !important;
  height: auto !important;
  padding: 10px 20px !important;
}

.mobile-navigation-placeholder {
  height: $mobile-nav-height;
  width: 100%;
}

.mobile-navigation-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 10px 20px 20px 20px;
  z-index: 100;
  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    display: flex;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .current-project-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .logo {
        border-radius: $input-border-radius;
        cursor: pointer;
        width: 45px;
        height: 45px;
        min-width: 45px;
        min-height: 45px;
        padding: 8px;
        margin-top: 0px;
        margin-bottom: 0px;
        @include themed() {
          background-color: t('color-bg');
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            @include themed() {
              fill: t('color-primary');
            }
          }
        }
      }

      .project-cover-container {
        height: 45px;
        width: 45px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        @include themed() {
          background-color: t('color-bg');
          color: t('color-font-title');
          border: 1px solid t('border-color');
        }

        .project-cover-placeholder {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 20px;
        }

        .project-cover-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .project-name-label {
        font-weight: 700;
        font-size: 20px;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .burger-icon {
      font-size: 25px;
      cursor: pointer;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .close-icon {
      font-size: 16px;
    }
  }

  .scroll-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: scroll;

    .body-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-top: 50px;
      margin-bottom: 20px;

      .navigation-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 42px;
        width: 100%;

        .main-navigation-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &-label {
            cursor: pointer;
            position: relative;
            font-weight: 700;
            font-size: 18px;
            @include themed() {
              color: t('color-font-title');
            }

            &-active {
              @include themed() {
                color: t('color-primary');
              }
            }

            .notifications-badge {
              position: absolute;
              top: -16px;
              right: -22px;
              margin-left: auto;
              color: #fff;
              font-size: 11px;
              font-weight: 600;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px;
              height: 18px;
              min-width: 18px;
              border-radius: 18px;
              padding: 0px 2px;
              @include themed() {
                background-color: t('color-red');
              }
            }
          }

          &-arrow {
            font-size: 18px;
          }
        }

        .sub-navigation-items-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 12px;
        }

        .sub-navigation-item {
          cursor: pointer;
          position: relative;
          font-size: 17px;
          margin-top: 18px;
          @include themed() {
            color: t('color-font-nav');
          }

          &-active {
            @include themed() {
              color: t('color-primary');
            }
          }

          .notifications-badge {
            position: absolute;
            top: -16px;
            right: -22px;
            margin-left: auto;
            color: #fff;
            font-size: 11px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px;
            height: 18px;
            min-width: 18px;
            border-radius: 18px;
            padding: 0px 2px;
            @include themed() {
              background-color: t('color-red');
            }
          }
        }

        .spacer-line {
          height: 0.5px;
          width: 100%;
          width: 100%;
          margin-top: 22px;
          margin-bottom: 8px;
          @include themed() {
            background-color: t('border-color');
          }
        }
      }
    }

    .footer-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .footer-item {
        font-size: $text-font-size;
        @include themed() {
          color: t('color-font-nav');
        }
      }
    }
  }
}
