@import 'styles/scss-variable.scss';

.input-label {
  font-size: 15px;
  font-weight: $headline-semi-bold;
  margin-bottom: 7px;
  text-align: start;
  @include themed() {
    color: t('color-font-title');
  }
}
