@import 'styles/scss-variable.scss';

.submit-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .link-button {
    height: 40px;
    line-height: 40px;
    padding: 0px 16px;
    margin-right: 10px;
  }

  .plans-button {
    width: auto;
  }

  .delete-button {
    width: auto;
    @include themed() {
      background-color: t('color-primary');
    }
  }
}
