@import 'styles/scss-variable.scss';

.feedback-types-editor {
  .Collapsible {
    width: 100%;
    max-width: $max-xml;
  }

  .feedback-type-info {
    font-size: $text-font-size;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .action-item-header svg {
    border-radius: 0px;
  }
}

.feedback-type-editor-container {
  .page-headline {
    width: 100%;
  }

  .feedback-type-content-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100% - 10px) !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    max-height: calc(90vh - 67px);
    padding: 32px;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);

    .options-group {
      width: 100%;
    }

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-button:end:increment,
    &::-webkit-scrollbar-button:start:decrement {
      background: #0000;
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      @include themed() {
        background-color: t('color-gray');
      }
    }
    &::-webkit-scrollbar-track-piece:vertical:start {
      border-radius: 4px 4px 0 0;
      margin-top: 10px;
    }
    &::-webkit-scrollbar-track-piece:vertical:end {
      border-radius: 0 0 4px 4px;
      margin-bottom: 10px;
    }
    &::-webkit-scrollbar-thumb:horizontal,
    &::-webkit-scrollbar-thumb:vertical {
      border-radius: 4px;
      display: block;
      height: 48px;
      @include themed() {
        background-color: t('color-dark-gray');
      }
    }
  }
}
