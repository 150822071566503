@import 'styles/scss-variable.scss';

.tutorial-container {
  min-height: 100%;
  width: 100%;
  max-width: $max-xxl;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .platform-headline {
    display: none;
  }

  a {
    @include themed() {
      color: t('color-primary');
    }
  }

  .card {
    margin-top: 10px;
    padding: 10px;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    border: none !important;
    border-radius: 0px !important;

    .progress-bar {
      position: absolute;
      left: 50%;
      top: 0px;
      height: 4px;
      border-radius: 4px;
      width: 300px;
      transform: translateX(-50%);
      @include themed() {
        background-color: t('color-primary');
      }
    }

    & > div:nth-child(1) {
      width: 100%;
      margin-bottom: 50px;
      padding-bottom: 30px;
      overflow-y: auto;

      &::before {
        content: ' ';
        position: absolute;
        top: 9px;
        left: 0px;
        right: 10px;
        height: 10px;
        z-index: 10;
        @include themed() {
          background: linear-gradient(
            180deg,
            rgba($color: t('color-bg'), $alpha: 0.5) 0%,
            rgba($color: t('color-bg'), $alpha: 0) 100%
          );
        }
      }

      &::after {
        content: ' ';
        position: absolute;
        bottom: 59px;
        left: 0px;
        right: 10px;
        height: 50px;
        z-index: 10;
        @include themed() {
          background: linear-gradient(
            180deg,
            rgba($color: t('color-bg'), $alpha: 0) 0%,
            t('color-bg') 100%
          );
        }
      }
    }
  }

  .title {
    text-align: left;
  }

  .navigation-buttons {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    @include themed() {
      background-color: t('color-bg');
    }
  }

  .link-button {
    margin-right: 10px;
    padding: 0px 15px;
    height: 40px;
    line-height: 40px;
  }
}
