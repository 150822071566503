@import 'styles/scss-variable.scss';

.configuration-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.configuration-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .row-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

.domain-preview {
  margin-bottom: 13px;
  margin-left: 2px;
  @include themed() {
    color: t('color-font-text');
  }
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
