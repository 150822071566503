@import 'styles/scss-variable.scss';

.feedback-action-questions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .question-preview {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include themed() {
      background-color: t('color-ultra-light-gray');
    }

    &-styleselector {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .question-editor {
    flex-shrink: 0;
    width: 370px;
    overflow-y: auto;
    @include themed() {
      border-left: 1px solid t('border-color');
    }

    .special-sidenav-editor {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 25px;
    }
  }

  .questions-list {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 370px;
    @include themed() {
      border-right: 1px solid t('border-color');
    }

    &-header {
      flex-shrink: 0;
      padding: 15px;
      padding-left: 25px;
      display: flex;
      align-items: center;
      position: relative;
      @include themed() {
        border-bottom: 1px solid t('border-color');
      }

      &-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        flex-grow: 1;

        @include themed() {
          color: t('color-font-title');
        }
      }

      &-button {
        padding: 0px 9px;
        flex-shrink: 0;
        min-height: 36px;
        height: 36px;
        line-height: 36px;

         i {
          margin-right: 5px;
          margin-left: 5px;
         }
      }
    }

    &-body {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}
