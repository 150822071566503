@import 'styles/scss-variable.scss';

.edit-feature-request-modal {
  width: 100%;
  height: 100%;

  .page-container {
    border-top-right-radius: 0px !important;
  }

  .page-content {
    height: calc(100% - 67px);
    max-height: calc(100% - 67px);
    overflow-y: auto;

    .page-content--inner {
      padding: 0px;
    }
  }

  .featurerequest-input-container {
    overflow: hidden;
    @include themed() {
      border: 0px solid t('border-color');
    }

    .toolbar-wrapper {
        padding: 18px !important;
        justify-content: flex-start;
    }

    .editor-wrapper {
      padding: 25px;
      max-height: calc(100vh - 120px);
      overflow-x: auto;
      @include themed() {
        background-color: t('color-bg');
      }

      .ProseMirror {
        min-height: calc(100vh - 53px - 50px - 67px);
      }
    }
  }
}
