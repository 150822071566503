@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-request-subscribe-user-modal-container {
  .vote-headline {
    text-align: center;
  
    .vote-headline-title {
      margin-left: 5px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  
    div {
      display: inline;
    }
  }
}
