.list-table-wrapper {
  height: 300px;
  width: 100%;
  overflow: auto;
}

.merge-dups-list tr td:last-of-type {
  text-align: end !important;
}

.dup-search-container {
  width: 100%;
  position: relative;

  &-loading {
    position: absolute;
    right: 12px;
    top: 11px;
  }
}
