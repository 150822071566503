@import 'styles/scss-variable.scss';

.inbox-item {
  cursor: pointer;
  padding: 12px;
  margin: 8px;
  margin-right: 4px;

  &--skeleton:hover {
    background-color: transparent !important;
  }

  &-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &-userinfo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 100%;

      .due-date {
        i {
          font-size: 15px;
          margin-right: 6px;
          margin-left: 0px;
        }
      }

      .user-processing {
        padding-right: 8px;
        flex-shrink: 0;

        .user-avatar {
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;
        }
      }

      .user-avatar {
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
        flex-shrink: 0;

        .user-avatar-user-online {
          bottom: 2px;
          left: 18px;
          width: 9px;
          height: 9px;
        }
      }

      .image-placeholder {
        width: 26px;
        height: 26px;
        @include themed() {
          background-color: t('color-primary-light');
          color: t('color-font-text');
        }
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      .userinfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 11px;
        margin-right: 8px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 15px;
        @include themed() {
          color: t('color-font-title');
        }

        .user-domain {
          font-size: 13px;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }
  }

  &-body {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    border: 0;
    font-size: 15px;
    height: 23px;
    padding-left: 37px;
    max-width: 100%;
    margin-top: -4px;
    @include themed() {
      color: t('color-font-text');
    }

    time {
      @include themed() {
        color: rgba(t('color-font-title'), 0.4) !important;
      }
      line-break: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    .LinesEllipsis {
      font-weight: 500;
      flex-grow: 1;
      font-size: 14px;
      line-height: 21px;
      max-height: 100%;
      overflow: hidden;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  &--unread {
    time {
      position: relative;
      line-break: normal;
      padding-right: 9px;
      @include themed() {
        color: t('color-primary') !important;
      }
    }

    time::before {
      position: absolute;
      right: 0px;
      top: 7px;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 100%;
      @include themed() {
        background-color: t('color-primary');
      }
    }

    .userinfo {
      @include themed() {
        font-weight: 700;
        color: t('color-font-title') !important;
      }
    }

    .LinesEllipsis {
      @include themed() {
        font-weight: 600;
        color: t('color-font-title');
      }
    }
  }

  &--unread-snoozed {
    .snoozed-badge {
      min-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 6px;
      border-radius: 4px;
      @include themed() {
        background-color: #fdedae !important;
      }

      .time {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        line-break: normal;
        padding-right: 5px;
        font-weight: 600;
        font-size: 12px;
        @include themed() {
          color: #423200 !important;
        }
      }

      .unread-snoozed-icon {
        font-size: 10px;
        @include themed() {
          color: #423200 !important;
        }
      }
    }

    .userinfo {
      @include themed() {
        font-weight: 700;
        color: t('color-font-title') !important;
      }
    }

    .LinesEllipsis {
      @include themed() {
        font-weight: 600;
        color: t('color-font-title');
      }
    }

    .unread-snoozed-icon {
      font-size: 12px;
      @include themed() {
        color: t('color-yellow') !important;
      }
    }
  }

  &--snoozed {
    .snoozed-badge {
      min-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 6px;
      border-radius: 4px;
      @include themed() {
        background-color: #fdedae !important;
      }

      .time {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        line-break: normal;
        padding-right: 5px;
        font-weight: 600;
        font-size: 12px;
        @include themed() {
          color: #423200 !important;
        }
      }

      .unread-snoozed-icon {
        font-size: 10px;
        @include themed() {
          color: #423200 !important;
        }
      }
    }
  }

  &:hover {
    border-radius: $input-border-radius;
    @include themed() {
      cursor: auto !important;
      background-color: t('color-light-gray');
    }
  }

  &--active {
    border-radius: $input-border-radius;
    @include themed() {
      background-color: t('color-light-gray') !important;
    }

    .due-date {
      i {
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .userinfo {
      @include themed() {
        color: t('color-font-title') !important;
      }
    }

    .inbox-item-body .LinesEllipsis {
      @include themed() {
        color: rgba(t('color-font-title'), 0.7) !important;
      }
    }

    time {
      line-break: normal;
      @include themed() {
        color: rgba(t('color-font-title'), 0.4) !important;
      }
    }
  }
}
