@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.page-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  transition: 0.45s all ease;
  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%;
  }

  &--free-plan {
    .page-content {
      height: calc(var(--vh, 1vh) * 100 - 44px);
      width: 100%;
      overflow-y: auto;
    }

    .page-content--title {
      height: calc(var(--vh, 1vh) * 100 - 64px - 49.5px);

      @include for-mobile-tablet-only {
        height: calc(
          var(--vh, 1vh) * 100 - $mobile-nav-height - 64px - 4px
        );
      }
    }

    .bugboard {
      height: calc(var(--vh, 1vh) * 100 - 64px - 4px);

      @include for-mobile-tablet-only {
        height: calc(
          var(--vh, 1vh) * 100 - $mobile-nav-height - 64px - 4px
        );
      }
    }
  }

  .free-plan-banner {
    cursor: pointer;
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: $small-text-font-size;
    @include themed() {
      background-color: t('color-primary');
      color: t('button-color');
    }

    @include for-mobile-tablet-only {
      display: none;
    }

    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}
