@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.chat-stat {
    display: flex;
    flex-direction: column;
    padding: 25px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    border-radius: $input-border-radius;

    @include themed() {
        border: 1px solid t('border-color');
    }

    .icon {
        width: 56px;
        height: 56px;
        margin: 0 auto;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        @include themed() {
            background-color: t('color-primary-ultralight');
        }

        i {
            font-size: 22px;

            @include themed() {
                color: t('color-primary');
            }
        }
    }

    .subtitle {
        margin-top: -3px;
        font-size: 14px;

        @include themed() {
            color: t('color-font-text');
        }

        a {
            font-size: 14px;

            @include themed() {
                color: t('color-font-text');
            }
        }
    }

    &--info {
        .icon {
            @include themed() {
                background-color: t('color-yellow-light');
            }

            i {
                @include themed() {
                    color: t('color-yellow');
                }
            }
        }
    }

    &--error {
        .icon {
            @include themed() {
                background-color: t('color-red-light');
            }

            i {
                @include themed() {
                    color: t('color-red');
                }
            }
        }
    }

    &--success {
        .icon {
            @include themed() {
                background-color: t('color-green-light');
            }

            i {
                @include themed() {
                    color: t('color-green');
                }
            }
        }
    }
}