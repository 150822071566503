@import 'styles/scss-variable.scss';

.select-page-url {
  position: relative;

  .corner-radius {
    width: 100%;
  }

  &--inner {
    appearance: none;
    outline: none;
    cursor: pointer;
    border-radius: $input-border-radius;
    font-size: $text-font-size;
    border: 0px solid transparent !important;
    min-height: 38px;
    line-height: 38px;
    font-size: $medium-text-font-size;
    font-weight: 600;
    border: none;
    margin-right: 8px;
    background-color: transparent;
    @include themed() {
      color: t('color-primary');
    }

    &:hover {
      @include themed() {
        color: t('color-primary-highlight');
      }
    }

    &--valueadded {
      padding: 0px 15px;
      font-weight: 500;
      padding: 10px 15px;
      min-height: 38px;
      line-height: 18px;
      margin-right: 0px;
      cursor: pointer;
      border-radius: $input-border-radius;
      font-size: $text-font-size;
      border: 0px solid transparent !important;
      font-size: $medium-text-font-size;
      @include themed() {
        background-color: t('color-light-gray');
        color: t('color-font-title');
        box-shadow: none !important;
        border: 0px solid t('color-light-gray') !important;
      }

      b {
        font-weight: 600;
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
          background-color: t('color-primary-light');
        }
      }
    }
  }

  &-overlay {
    display: none;
    position: absolute;
    left: 0px;
    width: 450px;
    height: auto;
    padding: 25px;
    z-index: 1;
    border-radius: $input-border-radius;
    top: 45px;
    z-index: 999;
    box-shadow: rgba(12, 12, 12, 0.05) 0px 0px 0px 1px,
      rgba(12, 12, 12, 0.1) 0px 3px 6px, rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
    @include themed() {
      background-color: t('color-bg');
    }

    .field-container {
      min-width: 300px;
    }

    &--open {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
