@import 'styles/scss-variable.scss';

.fixedSuggestSubscriptionContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: auto !important;
  left: $mlsm-nav;
  bottom: 0;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  z-index: 10000;
  @include themed() {
    background-color: t('modal-background-color-light') !important;
  }

  .subscription-card-wrapper {
    padding: 40px;
    border-radius: $border-radius;
    @include themed() {
      background-color: t('color-bg');
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
