@import 'styles/scss-variable.scss';
@import '../../styles/utils.scss';

.bug-detail-container {
  height: 100%;
  width: 100%;
  position: relative;

  .close-container {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include themed() {
      background: t('color-bg');
      color: t('color-font-text');
    }
  }

  &-tabs-hidden {
    .details-container {
      height: calc(100%);
    }

    @include for-mobile-tablet-only {
      .notes-container {
        height: calc(var(--vh, 1vh) * 100);
        border-bottom: 0px !important;
      }
    }
  }
}

.shared {
  width: 100% !important;
}

.nav-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @include themed() {
    border-bottom: 1px solid t('border-color');
  }

  @include for-mobile-tablet-only {
    width: calc(100% - 50px);
    margin-right: 50px;
  }

  .nav-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 0px 10px;

    @include for-mobile-tablet-only {
      overflow: auto;
    }

    .nav-item {
      font-weight: $headline-semi-bold;
      box-sizing: border-box;
      font-size: $small-text-font-size;
      border-radius: 0px;
      text-align: center;
      padding: 14px 8px;
      padding-bottom: 13px;
      margin-right: 5px;
      white-space: nowrap;
      line-height: 17px;
      cursor: pointer;
      @include themed() {
        color: t('color-font-text');
        border-bottom: 3px solid transparent;
      }

      .new-feature {
        height: 12px;
        margin-left: 5px;
      }

      &:hover {
        @include themed() {
          color: t('color-font-title');
        }
      }

      &--active,
      &--active:hover {
        @include themed() {
          color: t('color-primary-black');
          border-bottom: 3px solid t('color-primary-black');
        }
        font-weight: $headline-semi-bold;
      }

      &:last-child {
        margin-right: 25px;
      }

      .beta-tag {
        background-color: red;
        color: #fff;
        height: 12px;
        font-size: 10px;
        font-weight: 700;
        padding: 3px;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
}
