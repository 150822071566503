@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.form-action-type {
  .icon-dropdown-selection__control {
    @include themed() {
      border: 1px solid t('border-color') !important;
    }
  }

  .icon-dropdown-selection {
    margin-left: 0px !important;
  }
}
