@import '../../../styles/scss-variable.scss';

.statistics-container {
  justify-content: flex-start;
  align-items: flex-start;
}

.statistics-header-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.statistics-container-inner {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .csat-score {
    min-height: 317px;
    box-shadow: none !important;
    
    @include themed() {
      background: linear-gradient(
          134.42deg,
          t('bg-gradient-first') 0%,
          t('bg-gradient-second') 27.81%,
          t('bg-gradient-second') 57.2%
        ),
        t('bg-gradient-second');
    }

    .statistics-card-value {
      margin-bottom: 20px;
    }
  }

  .statistics-card {
    width: 100%;
    justify-content: flex-start;

    &-title {
      width: 100%;
      text-align: center;
      font-family: $text-font-family;
      font-size: $text-font-size;
      font-weight: 600;
      padding: 20px;
      @include themed() {
        color: t('color-font-title');
      }
    }

    &-footer {
      width: 100%;
      text-align: center;
      font-family: $text-font-family;
      font-size: $small-text-font-size;
      padding: 20px;
      @include themed() {
        color: t('color-font-text');
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    &-rows {
      width: 100%;
      border-radius: $border-radius;
      @include themed() {
        background-color: t('color-bg');
      }
    }

    &-no-value {
      padding: 30px 20px;
      font-family: $text-font-family;
      font-size: $text-font-size;
      text-align: center;
      @include themed() {
        color: t('color-font-text');
      }
    }

    &-value {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: $border-radius;
      margin-bottom: 10px;
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-bg');
      }

      &-score {
        font-family: $text-font-family;
        font-size: 40px;
        line-height: 40px;
        font-weight: 900;
      }

      &-row {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        align-content: space-between;
        column-gap: 15px;
        padding: 10px 20px;

        &-key {
          width: 100%;
          font-family: $text-font-family;
          font-size: $text-font-size;
          word-break: break-all;
          @include themed() {
            color: t('color-font-text');
          }
          height: 26px;
          max-height: 26px;
          overflow: hidden;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          time {
            line-break: normal;
            margin-left: 10px;
          }

          a {
            @include themed() {
              color: t('color-primary');
            }
          }
        }

        &-value {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          min-width: 100px;
          width: 100%;
          font-family: $text-font-family;
          font-size: $text-font-size;
          font-weight: 500;
          text-align: right;
          @include themed() {
            color: t('color-font-title');
          }

          &-feedbackcount {
            border-radius: 5px;
            padding: 4px;
            min-width: 28px;
            text-align: center;
            margin-left: 5px;

            @include themed() {
              background-color: t('color-light-gray');
              color: t('color-font-text');
            }
          }
        }

        &:not(:last-child) {
          @include themed() {
            border-bottom: 1px solid t('color-light-gray');
          }
        }
      }
    }
  }
}

.react-daterange-picker__wrapper {
  @include themed() {
    border: 1px solid t('border-color');
  }
  padding: 4px 10px;
  cursor: pointer;
  border-radius: $border-radius;

  svg {
    @include themed() {
      stroke: t('color-font-title');
    }
  }
}

.react-daterange-picker__range-divider {
  @include themed() {
    color: t('color-font-text');
  }
}

.react-daterange-picker__inputGroup * {
  @include themed() {
    color: t('color-font-title');
  }
}

.react-daterange-picker__calendar {
  .react-calendar {
    cursor: pointer;
    border-radius: $border-radius;
    overflow: hidden;
    @include themed() {
      color: t('color-font-text');
      border: 1px solid t('border-color');
      background-color: t('color-bg');
    }

    .react-calendar__navigation__label__labelText,
    .react-calendar__navigation__arrow,
    .react-calendar__month-view__days__day {
      @include themed() {
        color: t('color-font-title');
      }
    }

    .react-calendar__tile--active {
      @include themed() {
        background-color: t('color-primary');
        color: t('button-color');
      }
    }

    .react-calendar__tile--hover,
    .react-calendar__tile:hover {
      @include themed() {
        background-color: t('color-gray');
        color: t('color-font-title');
      }
    }
  }
}

.score-green {
  @include themed() {
    color: t('color-green') !important;
  }
}

.score-organge {
  @include themed() {
    color: t('color-yellow') !important;
  }
}

.score-red {
  @include themed() {
    color: t('color-red') !important;
  }
}
