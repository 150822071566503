@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.upsell-modal {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @include for-mobile-tablet-only {
    flex-direction: column-reverse;
  }

  .upsell-modal-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    width: 100%;

    @include for-mobile-tablet-only {
      width: 100%;
    }

    .title,
    .text {
      text-align: left;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }

    .text {
      b {
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .upsell-item {
      display: flex;
      margin-bottom: 20px;

      .icon {
        min-width: 32px;
        padding-top: 3px;

        i {
          font-size: 20px;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }
  }
}
