@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.api-action-editor {
  padding: 0px;

  .textinput-gray {
    width: 100% !important;
  }
}
