@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.screenshot-image-generator {
  width: 100%;
  height: auto;
  max-width: 250px;
  margin-bottom: 5px;

  #screenshotGenerating {
    text,
    g {
      @include themed() {
        fill: t('color-font-text');
      }
    }

    rect {
      @include themed() {
        stroke: t('color-font-text');
      }
    }
  }
}

.side-header {
  font-weight: 600;
  font-size: $small-text-font-size;
  margin-bottom: 1px;
  padding-left: 2px;
  @include themed() {
    color: t('color-font-title');
  }
}

.details-loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.userlink {
  @include themed() {
    color: t('color-primary') !important;
  }
}

.details-container {
  width: 100%;
  height: calc(100% - 49.5px);
  overflow-y: auto;
  display: flex;
  padding: 0px;
  box-sizing: border-box;

  @include for-mobile-tablet-only {
    display: block;
  }

  .left {
    flex-grow: 1;
    max-width: calc(100% - 330px);

    @include for-mobile-tablet-only {
      margin-right: 0px;
      max-width: 100%;
    }

    .bug-details-head {
      display: flex;
      justify-content: flex-start;
      padding: 15px;
      @include themed() {
        border-bottom: 1px solid t('border-color');
      }

      &--info-dot {
        margin-right: 8px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin-top: 4px;
      }

      &--info {
        flex-grow: 1;

        .reported-by {
          @include themed() {
            color: t('color-font-text');
          }
          font-size: $small-text-font-size;

          a {
            @include themed() {
              color: t('color-primary');
            }
            font-size: $small-text-font-size;
          }
        }

        .bug-name {
          font-weight: 600;
          display: flex;
          @include themed() {
            color: t('color-font-title');
          }

          span {
            @include themed() {
              color: t('color-font-text');
            }
            font-size: $small-text-font-size;
          }
        }
      }

      .trash-icon-container {
        display: flex;
        width: 14px;
        cursor: pointer;
        opacity: 0.2;
        justify-content: center;
        align-items: center;
        margin-left: 20px;

        .trash-icon {
          display: block;
          width: 100%;
          @include themed() {
            filter: t('image-filter');
          }
        }

        &:hover {
          opacity: 1;
        }
      }

      .client-spacer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .force-display {
    display: flex !important;
  }

  .right {
    min-width: 330px;
    max-width: 330px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow-y: auto;
    
    @include for-mobile-tablet-only {
      display: none;
      width: 100%;
      min-width: unset;
      max-width: unset;
    }

    @include themed() {
      color: t('color-font-text');
      border-left: 1px solid t('border-color');
    }

    .integrations {
      margin-top: 15px;
      margin-bottom: 5px;

      .integration {
        display: flex;
        margin-top: 10px;
        cursor: pointer;
        width: 100%;
        justify-content: space-between;
        flex-grow: 1;
        display: flex;
        align-items: center;

        i {
          margin-left: 0px;
          margin-right: 0px;
          font-size: 16px;
          @include themed() {
            color: t('color-font-title');
          }
        }

        &-title {
          text-transform: capitalize;
          margin-right: 5px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          @include themed() {
            color: t('color-font-text');
          }
        }

        &:hover {
          span,
          i {
            @include themed() {
              color: t('color-primary') !important;
            }
          }
        }
      }
    }

    .delete-button {
      background-color: transparent;
      border: none !important;
      box-shadow: none !important;
      margin-top: 12px;
    }

    .static-assigned-user {
      display: flex;
      align-items: center;
      line-height: 20px;
      padding: 10px;
      border-radius: $border-radius;
      @include themed() {
        background: t('color-bg');
        color: t('color-font-text');
        border: 1px solid t('border-color');
      }

      .no-picture {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        border-radius: 5px;
        margin-right: 10px;
        @include themed() {
          color: t('color-font-text');
          border: 1px solid t('border-color');
        }
      }

      .user-avatar {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        border-radius: 5px;
        margin-right: 10px;
        object-fit: cover;
      }
    }
  }
}

.feedback-selection-row {
  display: flex;

  &-label {
    font-size: 14px;
    min-width: 85px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-content {
    padding: 2px 4px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    @include themed() {
      color: t('color-font-title');
    }

    a {
      font-size: 14px;
      @include themed() {
        color: t('color-font-title');
      }

      &:hover {
        @include themed() {
          color: t('color-primary');
        }
      }
    }
  }

  &-checkbox {
    width: 100%;

    .user-selection__indicator-separator,
    .dropdown-selection__indicator-separator {
      display: none;
    }

    .user-selection,
    .dropdown-selection {
      margin-bottom: 0px;
      padding: 0px;

      .dropdown-selection__control {
        padding: 0px;
        min-height: 40px;

        @include themed() {
          background-color: transparent;
        }

        &:hover {
          @include themed() {
            color: t('color-font-title');
            background-color: t('color-light-gray') !important;
          }

          div,
          span {
            @include themed() {
              color: t('color-font-title');
            }
          }

          .dropdown-selection__indicator path {
            @include themed() {
              fill: t('color-font-title') !important;
            }
          }

          .dropdown-selection__placeholder {
            @include themed() {
              color: t('color-font-title') !important;
            }
          }

          .dropdown-selection__single-value {
            @include themed() {
              color: t('color-font-title') !important;
            }
          }
        }
      }
    }

    .user-selection-placeholder-label {
      font-weight: 600;
      font-size: 14px !important;
      padding-left: 5px;
    }

    .dropdown-selection__dropdown-indicator {
      display: none;
    }
  }
}

.feedback-actions-box {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 0px;
  position: relative;

  @include themed() {
    background: t('color-bg');
  }

  &:last-of-type {
    border-bottom: none !important;
  }
}

.bfw {
  width: 100%;
  min-height: 36px;
  height: auto;
  line-height: 36px;
  box-shadow: none !important;
  padding: 0px 16px !important;
}

.sidebar-more-button {
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  @include themed() {
    color: t('color-font-title');
  }
}

.sidebar-loading {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 0;
  position: relative;
  @include themed() {
    border-bottom: 1px solid t('border-color');
  }

  .sidebar-loading-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 38px;
  }
}

.sidebar-loading-item-foldable {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 20px;
  @include themed() {
    border-bottom: 1px solid t('border-color');
  }
}
