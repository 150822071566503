@import 'styles/scss-variable.scss';

.condition-tag-static-header {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.outbound-events {
  .fa-circle-bolt {
    @include themed() {
      color: t('color-primary');
    }
  }
}

.condition-tag-static-operator,
.condition-picker-conditions-operator {
  margin-right: 5px;
  border-radius: $input-border-radius;
  font-size: $log-text-font-size;
  font-weight: $headline-semi-bold;
  border: 0px solid transparent !important;
  min-height: 38px;
  line-height: 38px;
  @include themed() {
    color: t('color-font-text');
  }
}

.audience-selection {
  display: flex;

  .audience-dropdown {
    margin-right: 10px;
  }
}

.survey-limit-container {
  margin-left: 35px;
  margin-top: 0px;
  display: flex;
}

.tooltip-link {
  cursor: pointer;
  text-decoration: underline;
  @include themed() {
    color: t('color-font-title') !important;
  }
}

.loading-audience-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading-preview-text {
    margin-top: 20px;
    @include themed() {
      color: t('color-font-text');
    }
  }
}