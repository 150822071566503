@import 'styles/scss-variable.scss';

.condition-tag-static {
  position: relative;
  margin-right: 5px;

  &--inner {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    outline: none;
    padding: 0px 15px;
    cursor: pointer;
    border-radius: $input-border-radius;
    font-size: $text-font-size;
    border: 0px solid transparent !important;
    min-height: 38px;
    line-height: 38px;
    font-size: $medium-text-font-size;
    position: relative;
    @include themed() {
      background-color: t('color-light-gray');
      color: t('color-font-title');
      box-shadow: none !important;
      border: 0px solid t('color-light-gray') !important;
    }

    b {
      padding-left: 4px;
      font-weight: 600;
    }

    .condition-name {
      font-weight: 600;
      margin-right: 5px;
      flex-shrink: 0;

      i {
        margin-right: 10px;
      }
    }

    .condition-description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.condition-tag-static--invalid {
  .condition-tag-static--inner {
    @include themed() {
      background-color: t('color-red-light');
      color: t('color-red');
    }
    .condition-remove {
      @include themed() {
        background: linear-gradient(
          90deg,
          transparent 0%,
          t('color-red-light') 55%
        );
      }
    }
  }
}
