@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.user-filter {
  .dropdown-selection {
    &__value-container {
      font-weight: 500;
      display: flex !important;
      font-size: $small-text-font-size !important;

      .user {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 5px;
      }

      .user-avatar {
        height: 17px;
        width: 17px;
        max-height: 17px;
        max-width: 17px;
        min-height: 17px;
        min-width: 17px;
        border-radius: 17px;
        margin-right: 8px;
      }
    }
  }
}

.user-option-list-item {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: $small-text-font-size !important;

  &:hover {
    @include themed() {
      color: t('color-font-title');
      background-color: t('color-light-gray');
    }
  }

  .user-avatar {
    height: 17px;
    width: 17px;
    max-height: 17px;
    max-width: 17px;
    min-height: 17px;
    min-width: 17px;
    border-radius: 17px;
    margin-right: 10px;
  }
}
