@import 'styles/scss-variable.scss';

.referral-container {
    .title {
        text-align: left;
    }

    .share-buttons button {
        margin-right: 5px;
    }
}