@import 'styles/scss-variable.scss';

.operating-hour-selection-item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .operating-hour-options {
    flex: 2;
  }

  .time-options {
    flex: 1;
  }

  .dropdown-selection__value-container {
    flex-wrap: nowrap !important;
  }
}

.delete-opening-time {
  cursor: pointer;

  i {
    @include themed() {
      color: t('color-font-title');
    }

    &:hover {
      @include themed() {
        color: t('color-primary');
      }
    }
  }
}
