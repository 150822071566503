@import 'styles/scss-variable.scss';

.text-headline {
  @include themed() {
    color: t('color-font-title');
  }
  font-size: $text-font-size;
  margin-bottom: 10px;
  font-weight: 600;
}
