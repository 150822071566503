@import 'styles/scss-variable.scss';

.field-container {
  position: relative;
  width: 100%;

  .input-label {
    @include themed() {
      color: t('color-font-title');
    }
    font-size: $text-font-size;
    font-weight: $headline-semi-bold;
    margin-bottom: 5px;
  }

  .change-password-button {
    @include themed() {
      color: t('color-font-text');
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border: 1px solid t('border-color');
      background-color: t('color-bg');
    }
    background: transparent;
    height: 100%;
    outline: none;
    height: 40px;
    width: auto;
    border-radius: $border-radius;
    font-size: $small-text-font-size;
    border: unset;
    font-weight: 600;
    cursor: pointer;
    padding: 0px 20px;

    &:hover {
      @include themed() {
        background-color: t('color-gray');
      }
    }
  }
}
