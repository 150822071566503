@import 'styles/scss-variable.scss';

.outbound-configuration-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 850px;
  margin-top: 50px;
  margin-bottom: 50px;

  &--large {
    max-width: 1040px;
  }
}

.send-event-rule {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .dropdown-selection {
    margin-bottom: 0px;
  }

  &--triggerset {
    .dropdown-selection .dropdown-selection__control {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-right: 2px;
    }
  }
}

.time-on-page-rule {
  display: flex;
}

.filter-form {
  .filter-selection {
    display: inline-flex;

    .priority-button {
      width: auto;
      padding: 6px 16px;
    }
  }
}

.sidebyside {
  display: flex;

  .filter-form {
    margin-right: 30px;
  }
}

.form-spacer {
  @include themed() {
    border-top: 1px solid t('border-color');
    padding-top: 20px;
    margin-top: 20px;
  }
}

.outbound-frequency {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .text {
    font-weight: 600;
    @include themed() {
      color: t('color-font-title');
    }
  }

  input {
    margin: 0px 10px;
    border-radius: 18px;
    margin: 0px 8px;
    padding: 6px 10px;
    width: 64px;
    text-align: center;
    @include themed() {
      border: 1px solid t('border-color');
      background-color: t('color-bg');
    }
  }
}

.tiptap-link-button {
  color: chocolate !important;
}

.color-preview-container .color-preview {
  height: 20px;
  width: 20px;
  border-radius: 20px;
}
