@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.share-board-modal {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: $scroll-padding;
  padding-bottom: 30px;

  .share-url {
    width: 100%;
    margin-top: 20px;
  }

  .shareable-url {
    max-width: 100% !important;
  }

  .image-upload-wrapper .image-upload-component {
    max-height: 120px;
    width: 240px;
    margin-top: 20px;
  }
}

.custom-domain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-size: $text-font-size;
    font-weight: 500;
    margin-right: 5px;
    text-align: start;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .field-container {
    margin-bottom: 0px !important;
    width: auto !important;

    input {
      width: auto !important;
      min-width: 250px;
    }
  }
}