@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.project-inbox {
  @include for-mobile-tablet-only {
    width: 100%;
  }

  .page-container {
    .page-content {
      @include for-mobile-tablet-only {
        height: calc(var(--vh, 1vh) * 100 - $mobile-nav-height);
        width: 100%;
      }
    }
  }

  .page-container--free-plan {
    .page-content--inner {
      height: calc(var(--vh, 1vh) * 100 - 44px) !important;
    }

    .sidebar-items {
      height: calc(var(--vh, 1vh) * 100 - 129px) !important;
    }
  }

  .page-content--inner {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 0px !important;
    padding-bottom: 0px;
    height: calc(var(--vh, 1vh) * 100);
    margin-top: 0px;
    width: calc(100vw - $mnl-nav - $mlsm-nav);

    @include for-mobile-tablet-only {
      height: calc(var(--vh, 1vh) * 100 - 111px - $mobile-nav-height);
      width: 100%;
    }

    .sidebar-header {
      padding: 19px;
      padding-bottom: 10px;
      padding-top: 8px;
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      @include themed() {
        border-bottom: 1px solid t('border-color');
      }

      &-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
      }

      .filter {
        display: flex;
        margin-top: 16px;
        margin-left: -9px;

        .filter-selection {
          flex-grow: 1;
        }

        .filter-selection:first-of-type {
          margin-right: 8px;
        }

        .filter-selection .priority-button {
          padding: 4.5px 6px;
        }
      }
    }

    .sidebar-items {
      height: calc(var(--vh, 1vh) * 100 - 108px);
      width: calc(100% - 4px);
      overflow-y: auto;

      @include for-mobile-tablet-only {
        height: calc(var(--vh, 1vh) * 100 - 108px - $mobile-nav-height);
      }

      .loading-data {
        position: absolute;
        bottom: 20px;
        left: 20px;

        i {
          font-size: 23px;
          @include themed() {
            color: t('color-font-title');
          }
        }
      }

      .sidebar-placeholder {
        padding: 40px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        @include themed() {
          color: t('color-font-text');
        }
      }

      .ReactVirtualized__List {
        -webkit-overflow-scrolling: touch;
        -webkit-transform: translateZ(0);

        &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }
        &::-webkit-scrollbar-button:end:increment,
        &::-webkit-scrollbar-button:start:decrement {
          background: #0000;
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          margin-right: 5px;
          @include themed() {
            background-color: t('color-gray');
          }
        }
        &::-webkit-scrollbar-track-piece:vertical:start {
          border-radius: 4px 4px 0 0;
          margin-top: 8px;
        }
        &::-webkit-scrollbar-track-piece:vertical:end {
          border-radius: 0 0 4px 4px;
          margin-bottom: 8px;
        }
        &::-webkit-scrollbar-thumb:horizontal,
        &::-webkit-scrollbar-thumb:vertical {
          border-radius: 4px;
          display: block;
          height: 48px;
          @include themed() {
            background-color: t('color-dark-gray');
          }
        }
      }
    }

    .inbox-sidebox {
      width: 290px;
      min-width: 290px;
      height: 100%;
      @include themed() {
        border-right: 1px solid t('border-color');
      }

      @include for-mobile-tablet-only {
        width: 100%;
      }

      .sidebar-header {
        min-height: 108px;
        justify-content: flex-start;

        .title {
          text-transform: capitalize;
        }

        .sidebar-header-title {
          margin-top: 11px;
        }
      }
    }

    .inbox-settings-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 99;
    }

    .inbox-body-item {
      width: 100%;
      height: 100%;
      max-width: calc(100% - 290px);

      @include for-mobile-tablet-only {
        display: none;
      }

      .feedback-header {
        min-height: 60px;
        height: 60px;
        padding: 0px 20px;
      }

      .inbox-no-item-selected {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;
        font-size: 18px;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }
}

.simple-sidenav-element {
  .user-avatar {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 7px;
  }
}

.sidenavtitle {
  border-radius: $input-border-radius;
  padding: 9px 10px;
  margin: 0px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  @include themed() {
    color: t('color-font-title');
  }

  &-icon {
    opacity: 0;
    padding-left: 6px;
    cursor: pointer;

    i {
      font-size: 12px;

      @include themed() {
        color: t('color-font-title');
      }
    }

    &:hover {
      i {
        @include themed() {
          color: t('color-primary');
        }
      }
    }
  }

  &-text {
    font-weight: 700;
    font-size: 11px;
  }

  &:hover {
    .sidenavtitle-icon {
      opacity: 1;
    }
  }
}
