@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-request-list-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: auto;

  .feature-request-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 900px;
    padding: 28px;
    padding-top: 20px;

    @include for-mobile-tablet-only {
      padding: 8px;
      padding-top: 5px;
    }

    .feature-request-card-container {
      padding-bottom: 12px;
      margin-bottom: 12px;
      @include themed() {
        border-bottom: 1px solid t('color-light-gray');
      }

      &:last-of-type {
        margin-bottom: 0px !important;
        border-bottom: none;
      }
    }

    &-empty {
      border-radius: $border-radius;
      padding: $scroll-padding;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include themed() {
        background-color: t('color-light-gray');
      }

      .empty-image {
        margin: auto;
        width: 100%;
        max-width: 400px;
        margin-bottom: 30px;
      }
    }
  }
}
