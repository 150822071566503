@import 'styles/scss-variable.scss';

.integration-item-container {
  height: 180px;
  width: 180px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(151, 148, 148, 0.25);
  border-radius: 21px;
  margin: 10px;

  .integrationInfoRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .integration-icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
    }
  }

  .connect-button {
    height: 100%;
    max-height: 26px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #63a4fe;
    cursor: pointer;

    .icon {
      height: 13px;
      width: 13px;
      object-fit: contain;
    }

    .button-label {
      color: #398cfe;
    }
  }

  .integration-info-text {
    color: #c6c2c2;
  }
}

.configure-integration-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
