@import 'styles/scss-variable.scss';

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: $border-radius;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  @include themed() {
    background-color: t('color-card-bg');
    border: 1px solid t('border-color');
  }

  &--primary {
    @include themed() {
      background-color: t('color-font-title');
      border: 1px solid t('color-font-title');
      color: t('button-color');
    }
  }

  &--special {
    @include themed() {
      background-color: t('color-primary-ultralight');
      border: 1px solid t('color-primary-ultralight');
      color: t('color-font-title');
    }
  }
}