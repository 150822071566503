@import 'styles/scss-variable.scss';

.configuration-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .notion-header {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .no-database-info {
    @include themed() {
      color: t('color-font-text');
    }
  }
}
