@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.project-header-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.filter-bar {
  width: auto;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include for-mobile-tablet-only {
    max-width: initial;
    margin-left: 20px;
  }

  .fulltext-filter {
    min-width: 180px;
    margin-right: 12px;

    @include for-mobile-tablet-only {
      display: none;
    }
  }

  .user-filter-selection__indicator-separator,
  .dropdown-selection__indicator-separator {
    display: none !important;
  }
}

.project-dashboard {
  display: flex;

  .projects-overview {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .header {
    padding: 50px;
    padding-bottom: 0px;
  }

  .fit-parent {
    width: 100%;
    @include themed() {
      background: t('color-bg');
    }
  }

  .add-bug {
    max-width: 125px;
  }

  .board-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .bug-type-filter-container {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
}
