@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.developer-settings {
  .widget-developer-options {
    padding: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .form-dropdown {
    width: 200px;
  }

  .navigation-wrapper {
    padding-top: 10px;
    padding-bottom: 20px;

    .nav-container {
      background-color: transparent !important;

      .nav-item {
        border-radius: 40px;
        margin: 0px 5px;
        @include themed() {
          border: 1px solid t('border-color') !important;
        }
      }

      .nav-item:hover {
        background-color: transparent !important;
        @include themed() {
          color: t('color-primary');
          border: 1px solid t('color-primary') !important;
        }
      }

      .nav-item--active {
        @include themed() {
          color: t('color-primary');
          background-color: t('color-primary-light') !important;
          border: 1px solid transparent !important;
        }
      }
    }
  }
}

.switch-container span.importantnote {
  margin-left: 5px;
  @include themed() {
    color: t('color-red') !important;
  }
}

.bold {
  font-weight: bold;
}
