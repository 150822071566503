@import 'styles/scss-variable.scss';

.email-template {
  &-personal {
    width: 100%;
    background-color: #f6f7fb;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;

    .email-unsubscribe-link {
      margin-top: 30px;
      margin-bottom: 20px;
      color: rgb(89, 97, 125);
      font-size: 13px;
      font-weight: 500;
      text-decoration: underline;
      text-align: center;
      width: 100%;
    }

    &-content {
      background-color: #fff;
      max-width: 550px;
      padding: 0px;
      margin: auto;
    }

    &-sender {
      max-width: 550px;
      padding: 20px;
      margin: auto;
      display: flex;
      align-items: center;

      .user-avatar {
        width: 38px;
        height: 38px;
        min-width: 38px;
      }

      span {
        color: rgb(89, 97, 125);
        font-size: 15px;

        &:first-of-type {
          padding-left: 12px;
          font-weight: bold;
        }

        &:last-of-type {
          padding-left: 4px;
        }
      }
    }
  }

  &-plain {
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
    padding: 0px;
    padding-top: 25px;

    .email-unsubscribe-link {
      padding-top: 0px;
      padding-bottom: 50px;
      padding-left: 30px;
      font-size: 13px;
      font-weight: 500;
      text-decoration: underline;
      text-align: left;
      width: 100%;
    }
  }
}
