@import 'styles/scss-variable.scss';

.input-selected-file {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  min-height: 20px;
  padding: 3px 7px;
  margin-right: 3px;
  @include themed() {
    color: t('color-font-text');
    background-color: t('color-light-gray');
    border: 1px solid t('border-color');
  }

  i {
    cursor: pointer;
    font-size: 16px;
    margin-left: 4px;
    margin-right: 2px;
    @include themed() {
      color: t('color-font-title');
    }

    &:hover {
      @include themed() {
        color: t('color-primary');
      }
    }
  }

  span {
    display: block;
    font-size: 13px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.attachment-item-error {
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
  @include themed() {
    color: t('color-red');
  }
}

.attachments-container {
  display: flex;
  flex-direction: row;
  max-width: 500px;
  overflow-x: scroll;
}
