@import 'styles/scss-variable.scss';

.priority-selection {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 5px;
  border-radius: $border-radius;
  @include themed() {
    border: 1px solid t('border-color');
    background-color: t('color-bg');
  }

  .priority-button {
    min-height: 40px;
    width: 100%;
    border: unset;
    font-style: normal;
    border-radius: $input-border-radius !important;
    font-weight: $headline-semi-bold;
    font-size: $text-font-size;
    @include themed() {
      color: t('color-font-text');
    }
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    background: transparent;
    outline: none;

    .status-bubble {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      margin-right: 5px;
    }

    &:hover {
      @include themed() {
        color: t('color-primary');
      }
    }

    &--active {
      border-radius: 40px;
      @include themed() {
        background: t('color-primary-black');
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        color: t('button-color');
      }

      &:hover {
        @include themed() {
          color: t('button-highlight-color');
        }
      }
    }
  }
}
