@import 'styles/scss-variable.scss';

.duplicated-bugs {
  width: 100%;
  position: relative;
  padding-bottom: 12px;

  @include themed() {
    background-color: t('color-bg');
  }

  .inner-duplicated-bug-container {
    width: 100%;
    overflow: hidden auto;
    max-height: 130px;
  }

  .duplicated-bug-container {
    cursor: pointer;
    padding: 6px 0px;

    @include themed() {
      color: t('color-font-text');
    }

    .duplicated-bug-body {
      border: 0;
      font-size: 15px;
      max-width: 100%;
      margin-bottom: 0px;
      @include themed() {
        color: t('color-font-title');
      }

      .date {
        @include themed() {
          font-size: 14px;
          color: t('color-font-text');
        }
      }
    }

    &:hover {
      .duplicated-bug-body,
      .date {
        @include themed() {
          color: t('color-primary');
        }
      }
      
      .date {
        opacity: 0.7;
      }
    }
  }

  .duplicated-bug-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
}

.link-button-loading {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  min-height: 38px;
  line-height: 38px;
  background: transparent;
  border-radius: $input-border-radius;
  padding: 0px 20px;
  font-style: normal;
  font-weight: $headline-semi-bold;
  font-size: $text-font-size;
  text-align: center;
  cursor: pointer;
  @include themed() {
    color: t('color-font-title');
    background-color: t('color-light-gray');

    .spring-spinner-small-rotator {
      color: t('color-font-title');
      border-right-color: t('color-font-title');
      border-top-color: t('color-font-title');
    }
  }
}
