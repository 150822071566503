@import 'styles/scss-variable.scss';

.create-project {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .organisation-selection {
    min-width: 230px;
    border-radius: $border-radius;
    @include themed() {
      background-color: t('color-bg');
      color: t('color-font-title');
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) !important;
      border: 1px solid t('border-color') !important;
    }
  }

  .invitations--header {
    margin-bottom: 30px;

    .title,
    .text {
      text-align: center;
    }
  }

  .invitations-onboarding {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .invitation-card {
      margin: 12px;
      min-height: 160px;
      width: calc(25% - 24px) !important;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    width: 100%;

    .grow {
      width: 100%;
    }
  }

  .slickslider {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .slick-track {
      display: flex;
    }
    .slick-track .slick-slide {
      display: flex;
      height: auto;
    }

    .slick-slide > div {
      width: 100%;
      height: 100%;
    }
  }
}
