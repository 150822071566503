@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.authentication-form {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;

  @include for-mobile-tablet-only {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .card-container {
    position: relative;
  }

  .free-trial-arrow {
    position: absolute;
    z-index: 9999;
    top: 280px;
    right: -210px;
    width: 170px;

    @include for-phone-only {
      display: none;
    }
  }

  .title {
    font-size: 28px;
    line-height: 35px;
    font-weight: bold;
    margin-bottom: 8px !important;
  }

  .subtitle {
    font-size: 17px;
    line-height: 24px;
  }

  @media screen and (max-width: 600px) {
    .title {
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 8px;
    }
  }
}
