@import 'styles/scss-variable.scss';

.checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  @include themed() {
    color: t('color-font-text');
  }

  span {
    cursor: pointer;
    a {
      @include themed() {
        color: t('color-font-text');
      }
      text-decoration: underline;
    }
  }
}

.register-form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .register-form {
    max-width: 360px;
    margin-bottom: 30px;

    .textinput-gray {
      border-width: 2px !important;
      height: 46px !important;
      line-height: 46px !important;
    }

    .input-label {
      font-size: 15px;
      margin-bottom: 8px;
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .href {
        text-align: center;
        font-size: $small-text-font-size;
        width: 100%;
      }

      .login-button {
        background: #485bff;
      }

      .primary-button {
        margin: auto;
      }
    }

    .btn-container:first-child {
      flex-grow: 1;
    }
  }

  .social-container-button {
    width: 100%;
    margin: auto;
    margin-bottom: 35px;
    padding-bottom: 35px;
    max-width: 360px;
    @include themed() {
      border-bottom: 1px solid t('border-color');
    }
  }
}
