@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-requests-container {
  .page-content--inner {
    max-width: 840px;
  }
}

.feature-board-share-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .link-button {
    .icon {
      height: 16px;
      object-fit: contain;
    }
  }

  .fulltext-filter {
    min-width: 180px;
    margin-right: 12px;
    width: auto;
  }

  .user-filter-selection__control,
  .dropdown-selection__control {
    box-shadow: none !important;
    @include themed() {
      border: 1px solid t('border-color') !important;
    }

    @include for-mobile-tablet-only {
      font-size: $text-font-size;
    }
  }

  .user-filter-selection__indicator-separator,
  .dropdown-selection__indicator-separator {
    display: none !important;
  }
}
