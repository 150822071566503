@import 'styles/scss-variable.scss';

.bug-type-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: $small-text-font-size;

  .status-icon {
    width: 17px;
    height: 17px;
    margin: 0px;
    margin-right: 10px;
    
    path,
    g {
      @include themed() {
        fill: t('color-font-text');
      }
    }
  }
}
