.widget-bugbattle--feedback-new-button {
  width: 100%;
  max-width: 380px;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;

  &-button {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-color: #485bff;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 0 20px rgba(0, 0, 0, 0.1);
    position: relative;

    svg,
    img {
      position: absolute;
      width: 38px;
      height: 38px;
      top: 11px;
      left: 11px;
      object-fit: contain;
    }
  }
}