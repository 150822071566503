$themes: (
  default: (
    color-primary-highlight: #374af8,
    color-primary: #485bff,
    color-primary-black-light: #1a1a19,
    color-primary-black: #2c2b2a,
    color-primary-inverted: #fff,
    color-primary-inverted-light: #c1bfb9,
    color-primary-light: #e3e5ff,
    color-primary-ultralight: #485bff13,
    color-secondary: #03fff5,
    color-secondary-highlight: #0bdad3,
    color-green: #4fc47e,
    color-green-light: #e8f7f0,
    color-yellow: #ffb606,
    color-yellow-light: #fff6e1,
    color-red: #e50d00,
    color-red-light: #f9d9d9,
    color-log-green: #77dd7734,
    color-log-yellow: #fffbe5,
    color-log-yellow-primary: #5a3d00,
    color-log-yellow-border: #fff6c1,
    color-log-red: #fef1f0,
    color-log-red-border: #fed7d6,
    color-font-log: #9b9fae,
    color-font-title: #060d25,
    color-font-title-high: #2a3042,
    color-font-text: #4e4e51,
    color-font-nav: #060d25,
    color-bg: #fff,
    color-bg-inverted: #000000,
    color-dark-gray: #cacaca,
    color-light-gray: #f6f6f4,
    color-ultra-light-gray: #fbfbfa,
    color-gray: #ebebea,
    border-color: #f1f1f1,
    border-color-med: #e5e5e5,
    border-color-dark: #d4d4d4,
    dropdown-bg: #fff,
    color-card-bg: #fff,
    color-card-bg-hover: #e8e8e8,
    button-color: #fff,
    button-light-color: #ffffff99,
    button-highlight-color: #fff,
    code-field-background: #192734,
    modal-background-color: rgba(98, 107, 116, 0.7),
    modal-background-color-light: rgba(98, 107, 116, 0.4),
    modal-white-color: rgba(255, 255, 255, 0.8),
    shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1),
    hover-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1),
    card-shadow-opacity: 0.1,
    image-filter: invert(80%),
    image-filter-rev: invert(0%),
    image-filter-light: invert(50%),
    note-bg: #fff6d7,
    highlight-bg: #485bff33,
    bg-gradient-first: #ffc6f7,
    bg-gradient-second: #ffeec2,
  ),
  dark: (
    color-primary-highlight: #5e6efe,
    color-primary: #485bff,
    color-primary-light: #0b0e2b,
    color-primary-ultralight: #485bff13,
    color-primary-black-light: #3444d0,
    color-primary-black: #485bff,
    color-primary-inverted: #fff,
    color-secondary: #03fff5,
    color-secondary-highlight: #0bdad3,
    color-green: #4fc47e,
    color-green-light: #e8f7f0,
    color-yellow: #ffb606,
    color-yellow-light: #3c382e,
    color-red: #ea7878,
    color-red-light: #2a2626,
    color-log-green: #77dd7734,
    color-log-yellow: #fffbe5,
    color-log-yellow-primary: #5a3d00,
    color-log-yellow-border: #fff6c1,
    color-log-red: #fef1f0,
    color-log-red-border: #fed7d6,
    color-font-log: rgb(48, 48, 48),
    color-font-text: #929ab9,
    color-font-title: #ffffff,
    color-font-title-high: #c6c6c6,
    color-font-nav: #ffffff,
    color-bg: #0d1117,
    color-bg-inverted: #ffffff,
    color-gray: #181e2a,
    color-light-gray: #121720,
    color-ultra-light-gray: #121720,
    color-dark-gray: #363f52,
    border-color: #222d39,
    border-color-dark: #303c49,
    dropdown-bg: #121720,
    color-card-bg: #181e2a,
    color-card-bg-hover: #222937,
    code-field-background: #121720,
    note-bg: #121720,
    button-color: #fff,
    button-light-color: #ffffffaa,
    button-highlight-color: #fff,
    modal-background-color: rgba(91, 112, 131, 0.8),
    modal-background-color-light: rgba(91, 112, 131, 0.5),
    modal-white-color: rgba(0, 0, 0, 0.8),
    shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3),
    hover-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1),
    card-shadow-opacity: 0.2,
    image-filter: invert(0%),
    image-filter-rev: invert(100%),
    image-filter-light: invert(0%),
    highlight-bg: #485bff,
    bg-gradient-first: #04123f,
    bg-gradient-second: #060d25,
  ),
);

// Dark mode toggle
@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@mixin themedbody() {
  @each $theme, $map in $themes {
    .theme--#{$theme} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

//Gradients
$button-primary-background: linear-gradient(
  180deg,
  rgba(0, 61, 149, 1) 0%,
  rgba(0, 71, 175, 1) 80%
);
$button-secondary-background: linear-gradient(
  180deg,
  rgba(79, 79, 79, 1) 0%,
  rgba(79, 79, 79, 1) 80%
);

//Fonts
$main-font-family: system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$small-text-font-size: 14px;
$small-text-line-height: 20px;
$medium-text-font-size: 15px;
$log-text-font-size: 13px;
$text-font-size: 16px;
$text-font-family: $main-font-family;
$h1-font-size: 26px;
$h1-font-family: $main-font-family;
$h2-font-size: 18px;
$h2-font-family: $main-font-family;
$h3-font-size: 18px;
$h3-font-family: $main-font-family;
$huge-font-size: 24px;
$nav-links-font-size: 18px;
$headline-semi-bold: 600;
$code-font-family: 'Fira Code', monospace;

//shadow
$inner-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.2),
  inset 0 1px 1px 0 rgba(0, 0, 0, 0.14), inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);

//width
$max-xxxxl: 1150px;
$max-xxxl: 950px;
$max-xxl: 850px;
$max-xl: 600px;
$max-xml: 500px;
$max-l: 400px;
$max-m: 300px;
$max-s: 200px;

//border
$border-radius: 12px;
$input-border-radius: 10px;

//blur
$blur: 10px;

//margin
$mnl-nav: 46px;
$ml-nav: 250px;
$mlsm-nav: 220px;

$mobile-nav-height: 65px;

//button
$button-max-width: 150px;
$small-button-max-width: 75px;

//padding
$container-padding: 10px;
$scroll-padding: 50px;
$scroll-padding-med: 75px;
$scroll-padding-big: 100px;

$mobile-breakpoint: 950px;
