@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.project-card {
  padding: 15px;
  width: 100%;
  cursor: pointer;
  border-radius: $input-border-radius;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02);
  @include themed() {
    background-color: t('color-card-bg');
    border: 1px solid t('border-color');
  }

  &-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

    .notifications-badge {
      position: absolute;
      top: -10px;
      right: -10px;
      margin-left: auto;
      color: #fff;
      font-size: 11px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      height: 20px;
      min-width: 20px;
      border-radius: 20px;
      padding: 0px 4px;
      @include themed() {
        background-color: t('color-red');
      }
    }

    &-picture {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .image-placeholder {
        width: 54px;
        height: 54px;
        border-radius: $input-border-radius;
        overflow: hidden;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: $headline-semi-bold;
        @include themed() {
          background-color: t('color-gray');
          color: t('color-font-text');
        }
      }

      img {
        width: 54px;
        height: 54px;
        border-radius: $input-border-radius;
        overflow: hidden;
        object-fit: cover;
        @include themed() {
          background: t('color-gray');
          border: 1px solid t('border-color');
        }
      }
    }

    &-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 12px;
      width: calc(100% - 70px);

      .title {
        font-weight: 600;
        font-size: $text-font-size;
        line-height: 18px;
        text-align: left;
        margin-bottom: 0px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themed() {
          color: t('color-font-title');
        }
      }

      .memberscount {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        margin-top: 3px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }

  &:hover {
    @include themed() {
      background-color: t('color-light-gray');
      color: t('color-font-text');
    }

    .title {
      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}
