@import 'styles/scss-variable.scss';

.feature-request-card-subscribes {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  @include themed() {
    background-color: t('color-bg');
    border: 1px solid t('border-color');
    color: t('border-color');
  }

  i {
    font-size: 20px;
  }

  .number {
    font-size: 17px;
    font-weight: 600;
    margin-top: -2px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  &-selected {
    @include themed() {
      border: 1px solid t('color-primary');
    }

    i {
      @include themed() {
        color: t('color-primary');
      }
    }
  }

  &-selected .number {
    @include themed() {
      color: t('color-primary');
    }
  }

  &:hover {
    @include themed() {
      color: t('color-font-text');
      border: 1px solid t('color-font-text');
    }

    i {
      @include themed() {
        color: t('color-font-text');
      }
    }

    .number {
      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}
