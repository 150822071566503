@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.invite-modal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .invite-people-back {
    position: absolute;
    top: 20px;
    left: 20px;
    border: none !important;
    box-shadow: none !important;
  }

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
    padding-right: 0px;
    width: 45%;

    .title,
    .text {
      text-align: left;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
    }

    .text {
      b {
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .invite-people {
      align-items: flex-start;
    }
  }

  &-right {
    width: 55%;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .link-button {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
