@import 'styles/scss-variable.scss';

.shareable-url {
  @include themed() {
    border: 1px solid t('border-color');
    color: t('color-font-text');
  }
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: $max-xl;
  border-radius: $input-border-radius;
  padding: 11px 15px;

  .key {
    width: 100%;
    overflow: hidden;
    text-align: center;
    @include themed() {
      color: t('color-font-title') !important;
    }
  }
}
