@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.shared-feature-requests-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - 76px);
  overflow: auto;
  position: relative;
  @include themed() {
    background-color: t('color-bg');
  }

  @include for-phone-only {
    height: calc((var(--vh, 1vh) * 100) - 97px);
  }

  &::before {
    content: '';
    position: absolute;
    top: 82px;
    left: 0;
    width: 100%;
    height: 25px;
    z-index: 1;
    touch-action: none;
    @include themed() {
      background: linear-gradient(
        180deg,
        t('color-bg') 0%,
        rgba($color: t('color-bg'), $alpha: 0) 100%
      );
    }

    @include for-phone-only {
      top: 113px;
    }
  }

  .board-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;

    @include for-mobile-tablet-only {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 0px;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;

      .primary-button {
        width: 100%;
        margin-bottom: 20px;

        @include for-mobile-tablet-only {
          margin-bottom: 10px;
        }
      }
    }

    &-title {
      font-size: 13px;
      font-weight: 500;
      margin-right: 0px;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .dropdown-selection {
      margin-right: 0px !important;
      margin-bottom: 0px !important;
      width: auto !important;

      .dropdown-selection__control {
        background-color: #fff;
        border: none;
      }
    }

    .board-filter-sort--webmode {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .dropdown-selection__control {
        border: none !important;
      }

      .order-prio-item i {
        display: none;
      }
    }
  }

  &-main-container {
    display: flex;
    flex-direction: row;
  }

  &-filters {
    padding: 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-width: 350px;
    height: calc((var(--vh, 1vh) * 100) - 76px);
    overflow: auto;
    @include themed() {
      border-right: 1px solid t('border-color');
    }

    @include for-mobile-tablet-only {
      display: none;
      height: calc((var(--vh, 1vh) * 100) - 110px);
    }

    .filter-title {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 8px;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .board-filter-status {
      width: 100%;
    }

    .dropdown-selection {
      margin-right: 0px !important;
      margin-bottom: 8px !important;
      width: 100% !important;

      .dropdown-selection__control {
        background-color: #fff !important;
      }
    }

    .tag-filters {
      width: 100%;
    }

    .tags {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .tag {
      display: flex;
      padding: 10px;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 8px;

      &:hover {
        @include themed() {
          background-color: t('color-ultra-light-gray') !important;
        }
      }

      &-name {
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
        @include themed() {
          color: t('color-font-title');
        }

        span {
          padding-right: 5px;
          font-size: 14px;
          font-weight: 500;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }

      &-count {
        padding: 2px 8px;
        border-radius: 21px;
        font-weight: 500;
        font-size: 13px;
        @include themed() {
          color: t('color-font-title');
          border: 1px solid t('border-color');
        }
      }

      &-selected {
        padding: 2px 5px;
        height: 21.5px;
        @include themed() {
          color: t('color-primary');
        }
      }
    }

    @include for-phone-only {
      flex-direction: column;
      padding-bottom: 12px;
    }
  }

  .feature-request-board {
    padding: 20px;
  }

  @include for-phone-only {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;

    .feature-request-board {
      padding: 0px;
    }
  }
}
