@import 'styles/scss-variable.scss';

.extended-stat-card {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  margin-bottom: 20px;
  padding: 0px;
  width: 100%;
  @include themed() {
    border: 1px solid t('border-color');
  }

  .extended-stat-card-change {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    padding: 0px 6px;
    height: 20px;
    border-radius: 20px;
    @include themed() {
      background-color: t('color-gray');
      color: t('color-font-text');
    }

    &-minus {
      @include themed() {
        background-color: t('color-red');
        color: #fff;
      }
    }

    &-plus {
      @include themed() {
        background-color: t('color-green');
        color: #fff;
      }
    }
  }

  &-footer {
    padding: 30px;

    .stat-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &-name {
        font-size: 14px;
        @include themed() {
          color: t('color-font-text');
        }
      }

      &-value {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        @include themed() {
          color: t('color-font-title');
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &-change {
        background-color: transparent !important;
        margin-top: 0px !important;
      }
    }
  }

  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 35px;
    @include themed() {
      border-bottom: 1px solid t('border-color');
    }

    &-title {
      font-family: $text-font-family;
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;

      @include themed() {
        color: t('color-font-title');
      }
    }

    &-subtitle {
      font-family: $text-font-family;
      font-size: $text-font-size;
      font-weight: normal;
      margin-top: 5px;

      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  &-header {
    padding: 30px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    font-size: $text-font-size;
    font-weight: $headline-semi-bold;
    text-align: start;
    @include themed() {
      color: t('color-font-title');
    }

    span {
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }

    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: $text-font-family;
      font-size: $text-font-size;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        font-size: 14px;
        margin-right: 5px;
        border-radius: 5px;
        @include themed() {
          color: t('color-primary');
          background-color: t('color-primary-light');
        }
      }
    }

    &-subtitle {
      font-family: $text-font-family;
      font-size: $small-text-font-size;
      font-weight: normal;
      margin-top: 5px;

      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}
