@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-requests-headline {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 76px;
  @include themed() {
    border-bottom: 1px solid t('border-color');
    background-color: t('color-bg');
  }

  @include for-phone-only {
    height: auto;
  }

  &--inner {
    width: 100%;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    @include for-phone-only {
      padding: 0px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .shared-logo {
      height: 38px;
      width: auto;
      object-fit: contain;
      margin-right: 60px;

      @include for-phone-only {
        height: 28px;
        margin: 20px;
        margin-bottom: 15px;
      }
    }

    .links {
      display: flex;

      @include for-phone-only {
        padding-top: 0px;
        padding-left: 20px;
        max-width: 100%;
        overflow: auto;
        flex-grow: 1;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      > div {
        cursor: pointer;
        margin-right: 35px;
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
        @include themed() {
          color: t('color-font-text');
        }

        @include for-phone-only {
          margin-right: 20px;
        }

        i {
          margin-right: 6px;
          font-size: 16px;
        }

        &.active {
          color: var(--shared-color);

          @include for-phone-only {
            padding-bottom: 10px;
            border-bottom: 2px solid var(--shared-color);
          }
        }

        &:last-of-type {
          margin-right: 0px;

          @include for-phone-only {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
