@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.ai-feedback-summary {
  padding: 0px;
  margin-bottom: 50px;
  margin-top: 30px;

  @include for-mobile-tablet-only {
    padding: 25px;
    margin-bottom: 20px;
  }

  .ai-container {
    padding: 20px;
    border: 1px solid #ffd200;
    background-color: #ffd20011;
    box-shadow: 0px 0px 15px #ffd00055;
    position: relative;
    border-radius: $border-radius;
  }

  &-inner {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  &-headline {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 0px;
    position: relative;
    @include themed() {
      border-bottom: 1px solid rgba($color: t('color-bg'), $alpha: 0.15) !important;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.06px;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include themed() {
      color: t('color-font-title');
    }

    &-tag {
      background-color: #485bff;
      color: #fff;
      font-weight: 700;
      height: 20px;
      line-height: 20px;
      margin-left: 10px;
      font-size: 10px;
      padding: 0px 7px;
      border-radius: 5px;
    }
  }

  &-subtitle {
    font-size: 15px;
    font-weight: 500;
    box-sizing: border-box;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .question-answer-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 25px;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .question-answer {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    box-shadow: none;
    border-radius: $border-radius;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .regenerate-info {
    width: 100%;
    margin-top: 20px;

    &-outdated {
      opacity: 0.6;
      margin-bottom: 10px;
    }

    .simple-link {
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      text-decoration: none;
      color: #dfb600;

      i {
        margin-left: 0px;
        margin-right: 6px;
      }

      &:hover {
        color: #b89600;
      }
    }
  }

  .ai-loading-container {
    padding: 20px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .loading {
      background-color: transparent !important;
    }
  }

  .generate-summary {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    &-label {
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 5px;
      text-align: center;
      @include themed() {
        color: t('color-font-title');
      }
    }

    &-description {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 20px;
      text-align: center;
      max-width: 400px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}

.ai-tag {
  background-color: #ffd200;
  color: #000;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  padding: 0px 7px;
  border-radius: 5px;

  &-topleft {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
