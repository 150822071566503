@import 'styles/scss-variable.scss';

.team-container {
  padding-bottom: $scroll-padding;

  .user-managment {
    max-width: 50%;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
