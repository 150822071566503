@import 'styles/scss-variable.scss';

.snooze-button-container {
  position: relative;

  .snoozed-badge {
    display: flex;
    flex-direction: row;
    align-items: center;

    .unread-snoozed-icon-container {
      color: #423200;
      background-color: #fdedae;
      margin-left: 10px;
      padding: 3px 8px;
      border-radius: 6px;

      .unread-snoozed-icon {
        font-size: 12px;
        margin-right: 6px;
      }

      .unread-snoozed-icon-label {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .snooze-options-list {
    position: absolute;
    top: 42px;
    left: 0px;
    padding: 5px;
    width: 200px;
    z-index: 99999;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px 0 rgba(15, 22, 69, 0.1);

    @include themed() {
      color: t('color-font-title');
      background-color: t('color-bg');
      border: 1px solid t('border-color');
      font-size: $text-font-size;
    }

    &-item {
      padding: 5px;
      cursor: pointer;
      border-radius: $input-border-radius;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        @include themed() {
          background-color: t('color-gray');
        }
      }

      &-shortcut-content {
        padding: 7px 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }
  }
}
