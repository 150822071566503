@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.helpcenter-article-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent !important;
}

.create-helpcenter {
  padding-bottom: 0px;
  position: relative;

  .helpcenter-input-container {
    overflow: hidden;

    .editor-wrapper {
      padding: 10px;

      .ProseMirror {
        min-height: 300px;
      }
    }

    &-language {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.fields-aside {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}

.no-border-input .input-wrapper > input {
  border: none !important;
}

.no-border-input .error-message {
  margin-left: 12px;
}

.title-input .input-wrapper > input {
  font-size: $h1-font-size !important;
  font-weight: bold;
}

.subtitle-input .input-wrapper > input {
  font-size: $h2-font-size !important;
}

.fixed-editor-toolbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @include themed() {
    border: 0px solid t('border-color');
    background-color: t('color-bg');
  }
}