@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.complete-onboarding {
  display: flex;
  width: 100%;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.08);
  min-height: 0px;
  padding: 60px;
  border-radius: $border-radius;
  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    width: 100vw;
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 30px;
    border-radius: 0px;
  }

  .complete-onboarding-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    width: 46%;

    @include for-phone-only {
      width: 100%;
    }

    @include for-mobile-tablet-only {
      width: 100vw;
      min-height: calc(var(--vh, 1vh) * 100);
      padding: 30px;
      border-radius: 0px;
    }

    .title,
    .text {
      text-align: left;
      font-weight: 450;
      line-height: 1.4;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }

    .text {
      b {
        @include themed() {
          color: t('color-font-title');
        }
      }
    }

    .upsell-item {
      display: flex;
      margin-bottom: 20px;

      .icon {
        min-width: 32px;
        padding-top: 3px;

        i {
          font-size: 20px;
          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }
  }

  .complete-onboarding-right {
    width: 54%;
    position: relative;

    @include for-phone-only {
      display: none;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .icon-button {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: transparent;
    }
  }
}
