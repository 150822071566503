@import 'styles/scss-variable.scss';

.sidenav-title {
  font-weight: $headline-semi-bold;
  font-size: 15px;
  padding-left: 5px;
  padding-bottom: 8px;
  padding-top: 8px;
  @include themed() {
    color: t('color-font-title');
  }
}

.sidenav-element-dropdown {
  display: flex;
  border-radius: $input-border-radius;
  padding: 10px 0px;
  margin-bottom: 2px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  @include themed() {
    color: t('button-light-color');
  }

  .toggle-icon {
    transition-duration: 0.3s;
    transition-property: transform;
    transform-origin: center center;
    font-size: 20px;
  }

  span {
    margin-left: 15px;
    font-weight: $headline-semi-bold;
    font-size: 15px;
  }

  .notifications-badge {
    position: absolute;
    top: -6px;
    right: -3px;
    margin-left: auto;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    padding: 0px 6px;
    height: 20px;
    border-radius: 20px;
    @include themed() {
      background-color: t('color-red');
    }
  }

  &.collapsed {
    .toggle-icon {
      transform: rotate(90deg);
    }
  }

  &:hover {
    position: relative;
    background-color: transparent;

    @include themed() {
      color: t('button-color');
    }

    svg {
      opacity: 1;
      @include themed() {
        fill: t('button-color');
      }

      path,
      g {
        @include themed() {
          fill: t('button-color');
        }
      }
    }
  }
}

.sidenav-elements-container {
  padding: 0px 12px;
  padding-right: 0px;
  margin-bottom: 10px;
  margin-top: -5px;

  .sub-sidenav-item {
    margin-bottom: 0px !important;
  }

  .sub-sidenav-item:last-of-type {
    margin-bottom: 0px;
  }
}

.sidenav-elements {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sidenav-element {
    display: flex;
    align-items: center;
    border-radius: $input-border-radius;
    font-weight: $headline-semi-bold;
    font-size: 15px;
    padding: 12.5px 10px;
    margin: 0px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include themed() {
      color: t('color-font-text');
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;
      opacity: 1;
      @include themed() {
        fill: t('color-font-text');
      }

      path,
      g {
        @include themed() {
          fill: t('color-font-text');
        }
      }
    }

    .notifications-badge {
      margin-left: auto;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 5px;
      height: 20px;
      min-width: 20px;
      border-radius: 20px;
      @include themed() {
        background-color: t('color-primary');
      }
    }
  }

  .sidenav--spacer-top {
    position: relative;
    margin-top: 30px;
  }

  .sidenav--spacer-top::before {
    content: ' ';
    top: -15px;
    left: 0px;
    right: 0px;
    height: 1px;
    position: absolute;
    @include themed() {
      background-color: t('color-gray');
    }
  }

  .sidenav-element:hover {
    @include themed() {
      color: t('color-primary');
    }

    svg {
      opacity: 1;
      @include themed() {
        fill: t('color-primary');
      }

      path,
      g {
        @include themed() {
          fill: t('color-primary');
        }
      }
    }
  }

  .active {
    @include themed() {
      color: t('color-primary');
      background-color: t('color-primary-light');
    }

    svg {
      opacity: 1;
      @include themed() {
        fill: t('color-primary');
      }

      path,
      g {
        @include themed() {
          fill: t('color-primary');
        }
      }
    }
  }
}
