@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.main-page {
  display: flex;
  height: calc(var(--vh, 1vh) * 100);

  @include for-mobile-tablet-only {
    flex-direction: column;
  }

  .main-content-page {
    flex-grow: 1;
    padding-left: $mnl-nav;
    transition: 0.45s all ease;

    @include for-mobile-tablet-only {
      padding-left: 0px;
    }
  }

  .mainnavbar {
    transition: 0.45s all ease;
  }

  &--no-animation {
    .mainnavbar {
      transition: none !important;
    }

    .main-content-page {
      transition: none !important;
    }
  }

  &--nav-bar-hidden {
    .mainnavbar {
      left: -$mnl-nav;
    }

    .main-content-page {
      padding-left: 0px !important;
    }

    .page-container {
      width: 100vw !important;
    }
  }
}

.main-loaader {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.websocket-connection-state {
  position: fixed;
  z-index: 999999;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 15px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;

  @include themed() {
    background-color: t('color-red-light');
    color: t('color-red');
  }

  i {
    margin-right: 8px;
  }

  a {
    margin-left: 10px;
    font-size: 14px;
    text-transform: none;
    
    @include themed() {
      color: t('color-red');
    }
  }
}

.shared-loading {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .spring-spinner-small .spring-spinner-small-rotator {
    border-right-color: #333;
    border-top-color: #333;
  }
}

.loading-indicator {
  padding: 5px;
  border-radius: 18px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  @include themed() {
    box-shadow: 0px 0px 0px 200px t('color-bg');
  }

  &--inner {
    border-radius: 14px;
    padding: 12px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);

    @include themed() {
      background-color: t('color-primary-black');
    }

    svg {
      width: 40px;
      height: 40px;
      vertical-align: top;
      animation: rotateLogo 3s linear infinite;
    }
  }
}

.spring-spinner-small-rotator {
  @include themed() {
    border-right-color: t('button-color');
    border-top-color: t('button-color');
  }
}

.loading-indicator-bg {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  opacity: 0.7;
  background: linear-gradient(#485bff, #efeff8, #efeff8);
  animation: animate 1.2s linear infinite;
}

.loadung-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 144px;
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  @include themed() {
    color: t('color-font-title');
  }
}

@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotateLogo {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(120deg);
  }
  20% {
    transform: rotate(360deg);
  }
  24% {
    transform: rotate(380deg);
  }
  28% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
