@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.vote-modal-container {
  .vote-headline {
    text-align: center;

    .vote-headline-title {
      margin-left: 5px;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .title {
      font-size: 18px !important;
    }

    div {
      display: inline;
    }
  }
}
