@import '../../styles/scss-variable.scss';

.spring-spinner-small,
.spring-spinner-small * {
  box-sizing: border-box;
}

.spring-spinner-small {
  height: 22px;
  width: 22px;
}

.spring-spinner-small .spring-spinner-small-part {
  overflow: hidden;
  height: calc(22px / 2);
  width: 22px;
}

.spring-spinner-small .spring-spinner-small-part.bottom {
  transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner-small .spring-spinner-small-rotator {
  @include themed() {
    border-right-color: t('color-primary-black');
    border-top-color: t('color-primary-black');
  }
  width: 22px;
  height: 22px;
  border: calc(22px / 7) solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spring-spinner-small-animation 3s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes spring-spinner-small-animation {
  0% {
    border-width: calc(22px / 7);
  }
  25% {
    border-width: calc(22px / 23.33);
  }
  50% {
    transform: rotate(115deg);
    border-width: calc(22px / 7);
  }
  75% {
    border-width: calc(22px / 23.33);
  }
  100% {
    border-width: calc(22px / 7);
  }
}
