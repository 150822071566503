@import 'styles/scss-variable.scss';

.session-details-short {
  margin-top: 0px;
  margin-bottom: 0px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0px !important;

  .contact-details-card-value-row {
    padding: 4px 0px;
    border-bottom: none !important;
    @include themed() {
      background-color: t('color-bg');
    }

    .contact-details-card-value-row-key {
      font-size: 14px;
    }

    .contact-details-card-value-row-value {
      font-size: 14px;
    }

    &:last-of-type {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .contact-short-name {
    display: flex;
    padding: 0px;

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }

    .type-tag {
      margin-left: 5px;
    }
  }

  &-online {
    position: absolute;
    bottom: 8px;
    left: 32px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    @include themed() {
      border: 1px solid t('color-bg');
      background-color: t('color-green');
    }
  }

  .name {
    padding-left: 10px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
  }
}
