@import 'styles/scss-variable.scss';

.ai-container {
  .ai-list {
    position: absolute;
    bottom: 56px;
    left: 30px;
    right: 30px;
    padding: 0px;
    width: auto;
    max-height: 65vh;
    overflow-y: auto;
    border-radius: $border-radius;
    box-shadow: 0 4px 40px 0 rgba(15, 22, 69, 0.3);

    @include themed() {
      color: t('color-font-title');
      background-color: t('color-bg');
      border: 1px solid t('border-color');
      font-size: $text-font-size;
    }

    &-list {
      padding: 10px 0px;
      max-height: 45vh;
      overflow-y: auto;
    }

    .ai-list-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 228px;
      @include themed() {
        color: t('color-font-text');
      }

      .spring-spinner-small .spring-spinner-small-rotator {
        @include themed() {
          border-right-color: t('color-primary-black');
          border-top-color: t('color-primary-black');
        }
      }
    }

    &-footer {
      padding: 8px 15px;
      display: flex;
      align-items: center;

      @include themed() {
        color: t('color-font-text');
        border-top: 1px solid t('border-color');
      }

      .hotkeys-list {
        display: flex;
        align-items: center;
        opacity: 0.7;

        .hotkeys-list-label {
          font-size: 14px;
          padding-left: 6px;
          padding-right: 20px;

          @include themed() {
            color: t('color-font-text');
          }
        }
      }
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 18px 15px;
      font-weight: 600;

      @include themed() {
        color: t('color-font-title');
        border-bottom: 1px solid t('border-color');
      }

      &-tag {
        background-color: #ffd200;
        color: #000;
        font-weight: 700;
        height: 20px;
        line-height: 20px;
        margin-left: 10px;
        font-size: 10px;
        padding: 0px 7px;
        border-radius: 5px;
      }
    }

    &-item {
      margin: 0px 10px;
      padding: 5px 10px;
      cursor: pointer;
      border-radius: $input-border-radius;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      min-height: 38px;

      &--disabled {
        cursor: not-allowed;

        .ai-list-item-title,
        i {
          opacity: 0.3;
        }

        &:hover {
          background-color: transparent !important;
        }
      }

      &.focused {
        @include themed() {
          background-color: #ffd20033;
        }
      }

      &-textneeded {
        font-size: 13px;
        margin-left: auto;
        border-radius: 8px;
        padding: 5px 8px;
        opacity: 0.4;
        @include themed() {
          background-color: t('color-gray');
          color: t('color-font-title');
        }
      }

      .ai-list-item-title {
        padding: 0px 10px;
        font-size: 14px;
        font-weight: 600;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themed() {
          color: t('color-font-title');
        }
      }
    }
  }

  .ai-container-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    min-height: 20px;
    padding: 3px 7px;
    margin-right: 3px;
    @include themed() {
      color: t('color-font-title');
    }

    svg {
      width: auto;
      height: 17px;
      cursor: pointer;
      margin-left: 2px;
      margin-right: 2px;

      rect {
        @include themed() {
          fill: t('color-font-title');
        }
      }

      path {
        @include themed() {
          fill: t('color-bg');
        }
      }

      &:hover {
        rect {
          fill: #ffd200;
        }

        path {
          fill: #000;
        }
      }
    }

    &--active {
      svg {
        rect {
          fill: #ffd200 !important;
        }

        path {
          fill: #000 !important;
        }
      }
    }
  }
}

.ai-info-tooltip {
  padding: 10px;
  max-width: 300px;

  b {
    font-weight: bold;
    @include themed() {
      color: t('color-font-title');
    }
  }
}

.ai-list-planerror {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 228px;
  @include themed() {
    color: t('color-font-text');
  }

  .planerror-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .planerror-description {
    font-size: 15px;
    max-width: 400px;
    margin-bottom: 20px;
    text-align: center;
    @include themed() {
      color: t('color-font-text');
    }
  }
}
