@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.feature-requests-status-filter {
  margin-bottom: 0px !important;
  margin-right: 0px;

  .dropdown-selection__control {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) !important;
    @include themed() {
      border: 1px solid t('border-color') !important;
    }

    @include for-mobile-tablet-only {
      font-size: $text-font-size;
    }
  }
}
