@import 'styles/scss-variable.scss';

.sortable-item-container {
  width: 100%;

  .draggable-list-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .list-header {
      font-weight: 600;
      @include themed() {
        color: t('color-font-title');
      }
    }

    .add-column {
      cursor: pointer;
      @include themed() {
        color: t('color-primary');
      }
    }
  }

  .spacer {
    width: 10px;
  }

  .not-editable {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    cursor: not-allowed;
    @include themed() {
      background-color: t('color-gray');
      color: t('color-font-primary');
    }
  }
}

.draggable-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: $border-radius;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 4px;
  z-index: 10000000;
  @include themed() {
    background-color: t('color-bg');
    border: 1px solid t('border-color');
    color: t('color-font-primary');
  }

  .drop-zone {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .delete-item {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > i:hover {
      @include themed() {
        color: t('color-primary');
      }
    }
  }
}

.lanes-drag-icon {
  width: auto;
  height: 15px;
  flex-shrink: 0;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  margin-left: 5px;
  margin-right: 12px;
  @include themed() {
    fill: t('color-font-title');
  }
}
