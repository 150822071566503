@import 'styles/scss-variable.scss';

.pricing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  .discount-image {
    width: 120px;
    position: absolute;
    top: -40px;
    left: calc(50% + 120px);
  }

  > span {
    display: block;
    padding: 0px 14px;
    font-size: 18px;
    font-weight: bold;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .activetype {
    @include themed() {
      color: t('color-primary');
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @include themed() {
      background-color: t('color-primary');
    }
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    @include themed() {
      background-color: t('color-primary');
    }
  }

  input:focus + .slider {
    @include themed() {
      box-shadow: 0 0 1px t('color-primary');
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.startupinfo {
  width: 100%;
  text-align: center;
  margin-top: 0px;
  padding-bottom: 30px;

  a {
    font-weight: bold;
    @include themed() {
      color: t('color-font-title');
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.pricing-card {
  width: 100%;
  margin: 0px;
  padding: 24px;
  border-radius: 24px;
  @include themed() {
    background-color: t('color-bg');
    border: 1px solid t('border-color');
  }

  .primary-button,
  .secondary-button {
    margin: auto !important;
  }

  .pricinginfocard {
    display: flex;
    padding: 10px 16px;
    @include themed() {
      background-color: t('color-light-gray');
    }

    > div:first-of-type {
      flex-grow: 1;
      font-weight: 600;
      font-size: 14px;
      padding-right: 5px;
      @include themed() {
        color: t('color-font-title');
      }
    }

    > div:last-of-type {
      text-align: end;
      font-size: 14px;
      @include themed() {
        color: t('color-font-text');
      }
    }
  }

  .pricinginfocardmid {
    margin-bottom: 1px;
  }

  .pricinginfocardtop {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  .pricinginfocardbottom {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-bottom: 15px;
  }

  .currentplanbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 42px;
    line-height: 42px;
    background: transparent;
    border-radius: $input-border-radius;
    padding: 0px 15px;
    font-style: normal;
    font-weight: $headline-semi-bold;
    font-size: $text-font-size;
    text-align: center;

    @include themed() {
      color: t('color-font-text');
      border: 1px solid t('color-font-text');
    }
  }

  .pricingheadlinetop {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin: 0px;
    margin-top: 10px;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .planpre,
  .planpost {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 20px;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .pricingheadline {
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 20px;
    @include themed() {
      color: t('color-primary');
    }
  }

  .pricingfeatures {
    font-size: 15px;
    text-align: left;
    line-height: 28px;
    margin-top: 10px;
    @include themed() {
      color: t('color-font-text');
    }

    h5 {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-top: 0px;
      @include themed() {
        color: t('color-font-title');
      }
    }
  }

  &--colored {
    @include themed() {
      background-color: t('color-primary');
    }

    .primary-button {
      background-color: #fff;
      @include themed() {
        color: t('color-primary');
      }
    }

    .elementor-widget-text-editor p {
      @include themed() {
        color: #fff !important;
      }
    }

    .elementor-icon-list-text {
      @include themed() {
        color: #fff !important;
      }
    }

    .pricingheadlinetop {
      color: #fff !important;
    }

    .pricingheadlinebottom {
      color: #fff !important;
    }

    .pricingheadline {
      color: #fff !important;
    }
  }
}

.elementor-widget-text-editor p {
  font-weight: bold !important;
}

.elementor-heading-title {
  color: #060d31;
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
}

.elementor-widget .elementor-icon-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.elementor-widget .elementor-icon-list-items li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: inherit;
  padding-bottom: 10px;

  .elementor-icon-list-text {
    padding-left: 6px;
    line-height: 25px;
    padding-bottom: 3px;
    font-size: $small-text-font-size;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.freeplancontainer {
  @include themed() {
    background-color: t('color-bg');
  }
  width: 100%;
  margin: 0px 8px;
  padding: 24px;
  border-radius: 24px;
  margin-top: 20px;
  flex-direction: row;
  display: flex;

  .freeplaninfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.pricetable-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  max-width: 1000px;
  margin: auto;
}

.maulimit-info-tooltip {
  padding: 10px;

  b {
    font-weight: bold;
    @include themed() {
      color: t('color-font-title');
    }
  }
}
