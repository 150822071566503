@import 'styles/scss-variable.scss';

.cell-verify {
  display: flex;

  img {
    width: 36px;
    height: 36px;
    margin-right: 20px;
  }

  .cell-verify-text {
    display: flex;
    flex-direction: column;

    span:first-child {
      font-size: $text-font-size;

      @include themed() {
        color: t('color-font-title');
      }
    }

    span:last-child {
      font-size: $small-text-font-size;

      @include themed() {
        color: t('color-font-text');
      }
    }
  }
}

.user-invited-item-cell {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 16px;

  @include themed() {
    background-color: t('color-bg');
    color: t('color-font-title');
  }

  .email {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    font-weight: bold;

    @include themed() {
      color: t('color-font-title');
    }

    &:hover {
      text-decoration: underline;

      @include themed() {
        color: t('color-primary');
      }
    }
  }
}

.user-item-cell {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 16px;

  @include themed() {
    background-color: t('color-bg');
    color: t('color-font-title');
  }

  .name {
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-transform: capitalize;

    @include themed() {
      color: t('color-font-title');
    }
  }
}

.session-item-cell {
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;

  @include themed() {
    background-color: t('color-bg');
    color: t('color-font-title');
  }

  .session-deleted-icon {
    font-size: 34px;

    @include themed() {
      color: t('color-font-title');
    }
  }

  .user-avatar--small {
    width: 26px;
    min-width: 26px;
    height: 26px;
  }

  .name {
    display: block;
    padding-left: 10px;
    text-transform: capitalize;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;

    @include themed() {
      color: t('color-font-title');
    }
  }

  .type-guest-tag {
    margin-left: 10px;
    padding: 0px;

    i {
      font-size: 18px;

      @include themed() {
        color: t('color-primary');
      }
    }
  }

  &:hover {
    .name {
      text-decoration: underline;

      @include themed() {
        color: t('color-primary');
      }

      .type-guest-tag {
        text-decoration: none !important;
      }
    }
  }
}

.cell-badge-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .cell-badge {
    margin-right: auto;
    font-size: 12px;
    font-weight: 600;
    border-radius: 18px;
    padding: 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;

    @include themed() {
      color: t('color-font-title');
      background-color: t('color-gray');
    }

    &--paid {
      color: #4fc47e !important;
      background-color: #4fc47e22 !important;
    }
  }
}

.cell-tag {
  display: inline-block;
  text-transform: capitalize;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  margin-left: 0px;
  padding: 5px 8px;
  border-radius: 8px;
  line-height: 14px;

  @include themed() {
    background-color: t('color-gray');
    color: t('color-font-title');
  }

  &--green {
    color: #0f8a40 !important;
    background-color: #d7efdc !important;
  }

  &--primary {
    @include themed() {
      background-color: t('color-primary');
      color: t('button-color');
    }
  }
}

.cell-icon-action {
  text-align: left;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;

    @include themed() {
      fill: t('color-font-title');
    }

    &:hover {
      @include themed() {
        fill: t('color-primary');
      }
    }
  }
}

.cell-text-copy {
  cursor: pointer;
  font-size: $small-text-font-size;

  @include themed() {
    color: t('color-font-text');
  }

  span {
    word-break: break-all;
    font-family: iA Writer Mono, monospace, serif;
    font-size: $small-text-font-size;

    @include themed() {
      color: t('color-font-text');
    }
  }

  .copy-link {
    margin-left: 5px;
    font-family: $text-font-family;
    font-size: 13px;
    font-weight: bold;
    word-break: keep-all;

    @include themed() {
      color: t('color-primary');
    }
  }
}

.cell-text {
  font-size: $text-font-size;
  word-break: break-all;

  @include themed() {
    color: t('color-font-text');
  }

  &--capitalized {
    text-transform: capitalize;
  }
}

.cell-text-icon {
  padding: 8px 10px;
  border-radius: 8px;
  margin: 2px 2px;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-right: auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &-outer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  i {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  @include themed() {
    color: t('color-font-title');
    background-color: t('color-light-gray');

    i {
      color: t('color-font-title');
    }
  }

  &--capitalized {
    text-transform: capitalize;
  }
}

.cell-link {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none !important;
  word-break: break-all;

  &--icon {
    flex-direction: row;

    i {
      margin-left: 5px;
    }
  }

  span {
    font-weight: 600 !important;

    @include themed() {
      color: t('color-font-title');
    }
  }

  .sub-label {
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 18px;

    @include themed() {
      color: t('color-font-text');
    }
  }

  &:hover {
    span {
      text-decoration: underline !important;

      @include themed() {
        color: t('color-primary');
      }
    }

    i {
      @include themed() {
        color: t('color-primary');
      }
    }

    .unread-text-count {
      @include themed() {
        color: t('color-primary');
      }
    }
  }

  .unread-dot {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    transform: translate(0%, -50%);

    @include themed() {
      background-color: t('color-primary');
    }
  }

  .unread-count {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    padding: 0px 6px;
    height: 20px;
    border-radius: 20px;
    margin-top: 4px;
    text-decoration: none !important;

    @include themed() {
      color: t('color-primary');
      background-color: t('color-primary-light');
    }

    &-dot {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: 4px;

      @include themed() {
        background-color: t('color-primary');
      }
    }
  }
}

.list-table {
  display: block;
  max-width: 100%;
  width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .top-spacer {
    @include themed() {
      border-bottom: 1px solid t('border-color');
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    td {
      margin: 0;
      padding: 15px;
      padding-left: 0px;
      text-align: start;

      @include themed() {
        color: t('color-font-title');
        border-bottom: 1px solid t('border-color');
      }

      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      margin: 0;
      padding: 15px;
      padding-left: 0px;
      padding-top: 0px;
      text-align: start;
      width: 1%;

      @include themed() {
        color: t('color-font-text');
        border-bottom: 1px solid t('border-color');
      }

      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
}

.organisation-option {
  cursor: pointer;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  border-radius: $input-border-radius;

  .image-placeholder {
    width: 30px;
    height: 30px;
    border-radius: $input-border-radius;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $headline-semi-bold;

    @include themed() {
      background-color: t('color-gray');
      color: t('color-font-text');
    }
  }

  img {
    @include themed() {
      background: t('color-gray');
    }

    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: $input-border-radius;
    overflow: hidden;
    object-fit: cover;
  }

  .organisation-name {
    max-width: 150px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $headline-semi-bold;

    @include themed() {
      color: t('color-font-text');
    }
  }

  &:hover {
    @include themed() {
      color: t('color-font-title');
      background-color: t('color-light-gray');
    }

    .project-name {
      @include themed() {
        color: t('color-font-title');
      }
    }
  }
}