@import 'styles/scss-variable.scss';

.event-timeline {
  box-shadow: none !important;
  border-radius: 0px;
  height: 100%;
  justify-content: flex-start;
  @include themed() {
    background-color: t('color-bg');
    border: none;
    border-left: 1px solid t('border-color');
  }

  &-title {
    width: 100%;
    text-align: center;
    font-family: $text-font-family;
    font-size: $text-font-size;
    font-weight: 600;
    padding: 15px 10px;
    @include themed() {
      color: t('color-font-title');
      border-bottom: 1px solid t('border-color');
    }
  }

  &-loading {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
  }

  &-body {
    width: 100%;
    padding: 20px;

    .recent-events-footer {
      padding-left: 76px;
    }
  }
}
