@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';

.verify-installation-side-by-side {
  display: flex;
  justify-content: space-between;
}

.waiting-for-report {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 100px;

  .waiting-title {
    @include themed() {
      color: t('color-font-text');
    }
    line-height: 60px;
  }

  .spring-spinner {
    margin-right: 30px;
  }
}

.but-report-waiting {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;

  @include for-mobile-tablet-only {
    flex-direction: column;
  }

  .but-report-waiting-info {
    width: 100%;

    @include for-mobile-tablet-only {
      width: 100% !important;
    }
  }
}
