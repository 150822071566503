@import 'styles/scss-variable.scss';

.update-project {
  .feedback-tag-container {
    padding: 0px;
    width: 100%;
    max-width: 400px;

    .feedback-tag-row {
      display: flex;
      align-items: flex-start;

      .color-preview-container {
        margin-top: 12px;
      }

      .delete-item {
        margin-top: 13px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include themed() {
          color: t('color-font-title');
        }

        > i:hover {
          @include themed() {
            color: t('color-primary');
          }
        }
      }
    }

    .tag-select-add {
      cursor: pointer;
      border: none;
      font-weight: 600;
      @include themed() {
        color: t('color-font-text');
      }

      &:hover {
        border: none;
        @include themed() {
          color: t('color-primary');
        }
      }
    }
  }

  .project-infocard {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;

    .image-placeholder {
      min-width: 130px;
      min-height: 130px;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $headline-semi-bold;
      font-size: 70px;
      margin-bottom: 20px;
      @include themed() {
        background-color: t('color-gray');
        color: t('color-font-text');
      }
    }

    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      margin-bottom: 20px;
      @include themed() {
        background: t('color-gray');
        border: 1px solid t('border-color');
      }
    }

    .title {
      text-align: left !important;
      width: 100%;
    }
  }

  .sortable-item-container {
    width: 100%;

    .draggable-list-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .list-header {
        @include themed() {
          color: t('color-font-title');
        }
      }

      .add-column {
        cursor: pointer;
        @include themed() {
          color: t('color-primary');
        }
      }
    }

    .spacer {
      width: 10px;
    }

    .not-editable {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 9px;
      cursor: not-allowed;
      @include themed() {
        background-color: t('color-light-gray');
        color: t('color-font-text');
      }

      > div {
        font-size: $small-text-font-size;
      }
    }
  }

  .project-submit-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .danger-button {
      width: auto;
      padding: 0px 20px 0px 20px;
    }

    .save-button {
      width: auto;
      padding: 0px 30px 0px 30px;
    }
  }
}

.draggable-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: $border-radius;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 4px;
  @include themed() {
    border: 1px solid t('border-color');
    color: t('color-font-text');
  }

  .drop-zone {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .delete-item {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .trash-icon {
      height: 18px;
      width: auto;
      @include themed() {
        fill: t('color-font-text');
      }
    }

    &:hover {
      svg {
        @include themed() {
          fill: t('color-primary');
        }
      }
    }
  }
}
