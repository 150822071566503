@import 'styles/scss-variable.scss';

.integration-card {
  padding: 15px;
  width: calc(33% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 12px;
  min-height: 175px;

  &.entitled {
    opacity: 1;

    .link-header span,
    .description {
      @include themed() {
        color: t('color-font-log') !important;
      }
    }

    img {
      filter: grayscale(100%) !important;
      opacity: 0.5;
    }
  }

  .link-button {
    height: 38px;
    line-height: 38px;
  }

  .link-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .image {
      width: 28px;
      height: 28px;
      object-fit: contain;
      margin-right: 15px;
    }

    &-title {
      font-weight: $headline-semi-bold;
      font-size: $text-font-size;
      @include themed() {
        color: t('color-font-title');
      }
    }

    &-subtitle {
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }

    .connected-icon {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;

      .icon {
        height: 20px;
        width: 20px;
        object-fit: contain;

        &--invertable {
          @include themed() {
            filter: t('image-filter');
          }
        }
      }
    }
  }

  .link-body {
    flex-grow: 1;
    padding-top: 10px;

    .description {
      font-size: $small-text-font-size;
      @include themed() {
        color: t('color-font-text');
      }
    }

    a {
      font-size: $small-text-font-size;
      display: block;
      margin-top: 5px;
    }
  }

  .link-footer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
