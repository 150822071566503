@import 'styles/scss-variable.scss';

.recent-events {
  box-shadow: none !important;
  border-radius: 0px;
  height: 100%;
  justify-content: flex-start;
  border: none !important;
  @include themed() {
    background-color: t('color-bg');
  }

  &-loading {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
  }

  &-body {
    width: 100%;

    .recent-events-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &-title {
        cursor: pointer;
        line-height: 32px;
        font-family: $text-font-family;
        font-size: 14px;
        display: flex;
        max-width: calc(100% - 80px);
        @include themed() {
          color: t('color-font-title');
        }

        .title-block {
          display: inline-flex;
          min-height: 32px;
          flex-shrink: 0;
          line-height: 32px;
          font-family: $text-font-family;
          font-size: 14px;
          @include themed() {
            color: t('color-font-title');
          }
        }

        .data-link {
          padding-left: 5px;
          display: inline-flex;
          align-items: center;
          max-width: 100%;
          overflow: hidden;
          min-height: 32px;
          font-size: 14px;

          a {
            line-height: 32px;
            font-size: 14px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: $text-font-family;
            direction: rtl;
            @include themed() {
              color: t('color-font-title');
            }
          }
        }
      }

      .recent-events-item-line::after {
        position: absolute;
        left: -3px;
        top: 13px;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 6px;

        @include themed() {
          background-color: t('color-font-log');
        }
      }

      &-line {
        width: 1px;
        height: 100%;
        min-height: 32px;
        margin: 0px 15px;
        position: relative;
        @include themed() {
          background-color: t('color-font-log');
        }
      }

      time {
        min-width: 45px;
        line-height: 32px;
        font-family: $text-font-family;
        font-size: 13px;
        line-break: normal;
        text-align: right;
        @include themed() {
          color: t('color-dark-gray');
        }
      }

      &:first-of-type {
        .recent-events-item-line {
          min-height: 16px;
          margin-top: 16px;
        }

        .recent-events-item-line::after {
          top: -3px;

          animation: liveReloadingStatus 1s infinite;
        }
      }
    }
  }

  &-footer {
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }
}

@keyframes liveReloadingStatus {
  0% {
    background-color: #485bff;
  }
  50% {
    background-color: #7e89ec;
  }
  100% {
    background-color: #485bff;
  }
}
