@import 'styles/scss-variable.scss';
@import 'styles/utils.scss';
@import 'react-loading-skeleton/dist/skeleton.css';

* {
  font-family: $text-font-family;
  font-size: $medium-text-font-size;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  min-height: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

@include themedbody() {
  background-color: t('color-bg');
}

.p-40 {
  padding: 40px;
}

.p-20 {
  padding: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-10 {
  padding-left: 10px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.plr-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.ptb-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pl-30 {
  padding-left: 30px;
}

.pr-10 {
  padding-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.m-15 {
  margin: 15px;
}

.m-30 {
  margin: 30px;
}

.m-60 {
  margin: 60px;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-15-f {
  margin-top: 15px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb--10 {
  margin-bottom: -10px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pt-15 {
  padding-top: 15px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-30 {
  padding-top: 30px;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-30 {
  padding-bottom: 30px;
}

.p-60 {
  padding: 60px;
}

.no-border-top {
  border-top: 0px !important;
}

.full-height {
  min-height: calc(var(--vh, 1vh) * 100);
}

.full-width-centered {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.relativ-full-width-page-container {
  width: 100% !important;
}

.half-width {
  width: 50%;
}

.full-screen {
  width: 100vw !important;
  height: calc(var(--vh, 1vh) * 100);
}

.text-centered {
  text-align: center !important;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  @include themed() {
    color: t('color-font-title');
    font-weight: 500;
    text-decoration: underline;
  }
}

.tippy-box {
  max-width: none !important;
}

.cursor-focus {
  cursor: pointer;
}

.single-link {
  display: inline-block;
}

.text.bold {
  @include themed() {
    color: t('color-font-title') !important;
  }
}

.text {
  @include themed() {
    color: t('color-font-text');
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
    white-space: pre;
    @include themed() {
      color: t('color-font-text');
    }
  }

  b {
    font-weight: bold;
  }

  &--small {
    font-size: $log-text-font-size !important;
  }

  &--success {
    font-weight: bold;
    @include themed() {
      color: t('color-green');
    }
  }
}

.text-button {
  @include themed() {
    color: t('color-font-text');
  }
  background-color: transparent;
  border: none;
}

.react-loading-spinner {
  @include themed() {
    fill: t('color-font-text') !important;
  }
}

.checkbox {
  margin-right: 15px;
}

.content-width {
  width: 100%;
  max-width: $max-m;
}

.Toastify__toast-container--top-right {
  top: 80px !important;
}

// Toast styling
.Toastify__toast {
  border-radius: 8px !important;

  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px) !important;
  }
}

.Toastify__toast-body {
  > div {
    @include themed() {
      color: t('color-font-title');
    }
  }
}

.Toastify__toast-container--bottom-left {
  left: 74px !important;

  @include for-mobile-tablet-only {
    left: 0px !important;
    bottom: 20px !important;
  }
}

// Fix for slider text selection.
.slick-slider {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.reversed-img {
  @include themed() {
    filter: t('image-filter-rev');
  }
}

.placeholder-img .image img {
  @include themed() {
    filter: t('image-filter');
  }
}

.theme--default {
  .dark-image {
    display: none;
  }
}

.theme--dark {
  .default-image {
    display: none;
  }
}

.confetti-canvas {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.w-50 {
  width: calc(50% - 20px) !important;
  margin: 10px;

  @include for-mobile-tablet-only {
    width: 100% !important;
  }
}

.w-30 {
  width: calc(33% - 20px) !important;
  margin: 10px;

  @include for-mobile-tablet-only {
    width: 100% !important;
  }
}

.w-60 {
  width: calc(66% - 20px) !important;
  margin: 10px;

  @include for-mobile-tablet-only {
    width: 100% !important;
  }
}

.integration-modal-container {
  width: 100%;

  .dropdown-component {
    width: 100%;

    .trigger-container-mainmenu {
      @include themed() {
        box-shadow: 0px 0px 7.41177px rgba(0, 0, 0, 0.08);
        background-color: transparent;
        border: 1px solid t('border-color');
        border-radius: $border-radius;
      }
    }
  }
}

.confetti-container {
  position: absolute;
  bottom: 50%;
  left: 50%;
  z-index: 10;
}

.section-title {
  font-size: 18px;
  font-weight: $headline-semi-bold;
  text-align: start;
  @include themed() {
    color: t('color-font-title');
  }
}

.button-group {
  display: flex;
}

.input-label {
  font-size: $text-font-size;
  font-weight: $headline-semi-bold;
  margin-bottom: 7px;
  text-align: start;
  @include themed() {
    color: t('color-font-title');
  }

  &--small {
    font-size: $medium-text-font-size !important;
  }
}

.danger-zone {
  width: 100%;

  .input-label {
    margin-bottom: 10px;
    @include themed() {
      color: t('color-font-title');
    }
  }

  .danger-zone-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    .danger-zone-item {
      display: flex;
      width: 100%;
      padding: 10px 0px;
      @include themed() {
        border-bottom: 1px solid t('border-color');
      }

      .danger-zone-label {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: $text-font-size;
        @include themed() {
          color: t('color-font-text');
        }
      }

      button {
        width: auto;
      }

      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
}

.card-container {
  max-width: 600px;
  width: 100%;

  .card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    overflow: hidden;
    padding: 20px;
    padding-bottom: 70px;
    padding-top: 70px;
    width: 100%;
    position: relative;

    @include themed() {
      background: t('color-card-bg');
    }

    @include for-phone-only {
      margin-top: 0px;
      padding: 25px;
      padding-top: 0px;
      margin: 0px;
      width: 100%;
      box-shadow: none;
      border: none;
      border-radius: 0px;
      height: calc(var(--vh, 1vh) * 100);
    }

    img.logo {
      height: 50px;
      object-fit: contain;
      margin-bottom: 30px;

      @include for-phone-only {
        margin-bottom: 14px;
      }
    }
  }

  .card-without-logo {
    padding: 50px;
    padding-top: 50px;
  }
}

.card-container--no-dots {
  max-width: 450px;
}

.full-loader {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.Collapsible {
  border-radius: $border-radius;
  margin-bottom: 10px;
  @include themed() {
    border: 1px solid t('border-color');
  }

  &__trigger {
    position: relative;
    cursor: pointer;
    width: 100%;
    display: block;
    padding: 20px;
    font-weight: 600;
    @include themed() {
      color: t('color-font-title');
    }

    &::before {
      position: absolute;
      top: 12px;
      font-size: 25px;
      right: 20px;
      content: '\203A';
      transform: rotate(0deg);
      transition-duration: 0.3s;
      transition-property: transform;
      transform-origin: center center;
    }

    &::before {
      top: 20px;
    }
  }

  &__contentInner {
    padding: 30px;
    @include themed() {
      border-top: 1px solid t('border-color');
    }
  }

  &:hover {
    @include themed() {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border: 1px solid t('border-color');
    }

    .Collapsible__trigger {
      @include themed() {
        color: t('color-primary-black');
      }
    }
  }

  &--opened {
    @include themed() {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
      border: 1px solid t('border-color');
    }

    .Collapsible__trigger {
      @include themed() {
        color: t('color-primary-black');
      }
    }
  }

  &--opened {
    .Collapsible__trigger {
      &::before {
        transform: rotate(90deg) !important;
      }
    }

    .collapsible-header-sub {
      display: none !important;
    }
  }
}

.Collapsible-fake {
  margin: 40px;
  border-radius: 12px;

  @include themed() {
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06) !important;
    border: 1px solid t('border-color') !important;
  }
}

.Collapsible-big {
  @include themed() {
    box-shadow: none !important;
    border: 1px solid transparent;
  }

  .Collapsible__trigger::before {
    display: none !important;
  }

  .collapsible-header {
    display: flex;
    justify-content: flex-start;

    .collapsible-header-title {
      flex-shrink: 0;
      font-size: 17px;
      font-weight: 700;
      line-height: 32px;
      @include themed() {
        color: t('color-font-title');
      }

      i {
        font-size: 12px;
        padding-left: 4px;
      }
    }

    .collapsible-header-sub {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      padding-left: 40px;
      padding-right: 0px;
      display: flex;
      align-items: center;
      @include themed() {
        color: t('color-font-text');
      }

      i {
        font-size: 12px;
        padding-right: 3px;
      }

      > div {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        @include themed() {
          color: t('color-font-text');
        }
      }
    }
  }

  .Collapsible__contentInner {
    padding: 0px !important;
  }

  &:hover {
    @include themed() {
      box-shadow: none;
      border: 1px solid t('border-color');
    }

    .Collapsible__trigger {
      @include themed() {
        color: t('color-primary-black');
      }
    }
  }

  &.Collapsible--opened {
    @include themed() {
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06) !important;
      border: 1px solid t('border-color') !important;
    }

    .Collapsible__trigger {
      @include themed() {
        color: t('color-primary-black');
      }
    }

    .collapsible-header-title i {
      transform: rotate(90deg) translate(-25%, -25%);
    }
  }
}

.collapsible-options-group {
  width: 100%;
  padding: 30px;
  @include themed() {
    border-bottom: 1px solid t('border-color');
  }

  .options-text-footer {
    display: flex;
    flex-direction: row;
  }

  &-header {
    padding: 0px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    text-align: start;
    @include themed() {
      color: t('color-font-title');
    }
  }
}

.copy-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: $small-text-font-size;
    @include themed() {
      color: t('color-font-title');
    }
  }

  i {
    cursor: pointer;
    margin-left: 10px;
    @include themed() {
      color: t('color-font-text');
    }

    &:hover {
      @include themed() {
        color: t('color-primary');
      }
    }
  }
}

.checkbox-settings-content {
  .checkbox-settings-container {
    padding: 0px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    @include themed() {
      color: t('color-font-text');
      border-bottom: 1px solid t('border-color');
    }

    .info-box {
      width: 100%;
    }

    .input-label {
      font-weight: 500;
      font-size: 16px;
      @include themed() {
        color: t('color-font-text') !important;
      }
    }
  }

  .checkbox-settings-container:first-of-type {
    @include themed() {
      border-top: 1px solid t('border-color');
    }
  }
}

.share-article-container {
  margin-top: 20px;

  .Collapsible {
    @include themed() {
      border: 1px solid t('border-color');
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.switch-container {
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
    @include themed() {
      color: t('color-font-title') !important;
    }
  }

  span a {
    text-decoration: underline;
    @include themed() {
      color: t('color-font-title');
    }
  }
}

.fullwidth {
  width: 100%;
}

.text-align-left {
  text-align: left;

  .title,
  .text {
    text-align: left;
  }
}

.big-icon {
  font-size: 44px;
  @include themed() {
    color: t('color-primary');
  }
}

.capitalize {
  text-transform: capitalize;
}

.grecaptcha-badge {
  display: none !important;
}

.emailstatus-header {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 4px;
  @include themed() {
    color: t('color-font-title');
  }
}

.emailstatus-row {
  min-width: 200px;
  font-size: 14px;
  @include themed() {
    color: t('color-font-text');
    border-top: 1px solid t('border-color');
    padding: 8px 0px;
  }

  span {
    font-size: 14px;
    @include themed() {
      color: t('color-font-text');
    }
  }
}

.switch-container {
  .react-switch-handle {
    @include themed() {
      background-color: t('color-bg') !important;
    }
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important;
  }
}

.color-picker {
  position: relative;

  .twitter-picker {
    @include themed() {
      background-color: t('color-bg');
    }

    input {
      width: 130px !important;
      padding-left: 12px !important;
      margin-left: 12px !important;
      height: 40px !important;
      border-top-right-radius: $input-border-radius !important;
      border-bottom-right-radius: $input-border-radius !important;

      @include themed() {
        background-color: t('color-bg') !important;
        box-shadow: t('border-color') 0px 0px 0px 1px inset !important;
        color: t('color-font-title') !important;
      }

      &:focus {
        @include themed() {
          box-shadow: t('color-primary-black') 0px 0px 0px 1px inset !important;
        }
      }
    }

    > div:nth-child(3) {
      div {
        border-radius: 10px !important;
        @include themed() {
          background-color: t('border-color') !important;
        }
      }
    }
  }

  .color-preview {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 42px;
    height: 42px;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
    box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.05);
  }
}

.twitter-picker {
  background-color: transparent !important;
  box-shadow: none !important;

  > div:nth-child(1),
  > div:nth-child(2) {
    display: none;
  }

  > div:nth-child(3) {
    padding: 0px !important;
  }
}

.max-form-card {
  max-width: 300px;

  @include for-mobile-tablet-only {
    max-width: 100%;
  }
}

.max-button-form-card {
  max-width: 400px;
  margin: auto;
  margin-top: 20px;

  @include for-mobile-tablet-only {
    max-width: 100%;
  }
}

#root {
  height: 100%;

  @include themed() {
    background-color: t('color-bg');
  }
}

.select {
  .dropdown-selection__control {
    appearance: none;
    outline: none;
    cursor: pointer;
    @include themed() {
      background-color: t('color-bg');
      color: t('color-font-title');
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) !important;
    }

    &:hover {
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-light-gray');
      }
    }

    &:focus-within {
      @include themed() {
        border: 1px solid t('color-font-title') !important;
      }
    }
  }
}

.dropdown-selection,
.language-selection,
.select {
  margin-bottom: 12px;

  &__value-container {
    display: flex !important;
  }

  .dropdown-selection__control {
    min-width: $max-s;
    appearance: none;
    outline: none;
    padding: 2px 2px;
    cursor: pointer;
    min-height: 40px;
    border-radius: $input-border-radius;
    font-size: $text-font-size;
    border: 0px solid transparent !important;
    @include themed() {
      background-color: transparent;
      color: t('color-font-title');
      box-shadow: none !important;
      border: 0px solid t('color-light-gray') !important;
    }

    .dropdown-selection__indicators {
      padding-right: 5px;
    }

    .dropdown-selection__indicator {
      padding: 0px !important;
    }

    .dropdown-selection__dropdown-indicator svg {
      width: 16px;
      height: 16px;
    }

    .dropdown-selection__value-container {
      font-size: $medium-text-font-size;
    }

    @include for-mobile-tablet-only {
      font-size: $text-font-size;
    }

    .dropdown-selection__indicator-separator {
      display: none;
    }

    .dropdown-selection__placeholder {
      font-size: $medium-text-font-size;
      font-weight: 600;
      @include themed() {
        color: t('color-font-title') !important;
      }
    }

    .dropdown-selection__single-value {
      font-weight: 600;
      @include themed() {
        color: t('color-font-title') !important;
      }
    }

    &:hover {
      @include themed() {
        color: t('color-font-title');
        background-color: t('color-light-gray');
      }

      div,
      span {
        @include themed() {
          color: t('color-font-title');
        }
      }

      .dropdown-selection__indicator path {
        @include themed() {
          fill: t('color-font-title') !important;
        }
      }

      .dropdown-selection__placeholder {
        @include themed() {
          color: t('color-font-title') !important;
        }
      }

      .dropdown-selection__single-value {
        @include themed() {
          color: t('color-font-title') !important;
        }
      }
    }
  }

  &--border {
    .dropdown-selection__control {
      @include themed() {
        border: 1px solid t('border-color') !important;
      }
    }
  }

  &__menu {
    @include themed() {
      background-color: t('color-bg');
      border: 0px solid t('color-gray');
      color: t('color-font-title');
      z-index: 999;
    }
    box-shadow: rgba(12, 12, 12, 0.05) 0px 0px 0px 1px,
      rgba(12, 12, 12, 0.1) 0px 3px 6px, rgba(12, 12, 12, 0.2) 0px 9px 24px !important;
    border-radius: $border-radius !important;
    font-size: $medium-text-font-size !important;
    padding: 0px;
    margin: 0px;
    z-index: 10000000;
    overflow: hidden;
  }

  &__menu-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .option-list-item:first-of-type {
      border-top-left-radius: $border-radius !important;
      border-top-right-radius: $border-radius !important;
      padding-top: 10px;
    }

    .option-list-item:last-of-type {
      border-bottom-left-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
      padding-bottom: 10px;
    }

    .option-list-item {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;
      padding-right: 10px;
      min-height: 46px;
      cursor: pointer !important;
      font-size: $small-text-font-size !important;

      &:hover {
        @include themed() {
          color: t('color-font-title');
          background-color: t('color-light-gray');
        }
      }
    }
  }
}

.select {
  margin-bottom: 0px;

  .dropdown-selection__control {
    min-width: auto;
  }
}

.header-content-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .primary-button {
    width: auto;
  }
}

.content-page {
  max-width: $max-xml;
}

.default-textarea {
  height: 40px;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: -0.02em;
  padding: 12px;
  padding-left: 10px;
  width: 100%;
  border: unset;
  font-size: $small-text-font-size;
  border-radius: $input-border-radius;
  height: 100px;
  resize: none;
  @include themed() {
    border: 1px solid t('border-color');
    color: t('color-font-title');
  }

  &:focus {
    outline-width: 0;

    @include themed() {
      border: 1px solid t('color-primary');
    }
  }
}

.flex-grow {
  flex-grow: 1;
}

.gleap-fun {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1000;
  pointer-events: none;
}

.react-editor-js-container {
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: -0.02em;
  padding: 12px;
  padding-left: 10px;
  width: 100%;
  font-size: $small-text-font-size;
  border: 0 solid;
  border-radius: $input-border-radius;
  border: 0 solid black;
  @include themed() {
    background-color: t('color-bg');
    border: 1px solid t('border-color');
    color: t('color-font-title');
  }

  &:focus {
    outline-width: 0;

    @include themed() {
      border: 1px solid t('color-primary');
    }
  }

  .codex-editor__redactor {
    padding-bottom: 0px !important;
  }
}

.growing {
  flex-grow: 1;
}

.session-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  padding-top: 100px;
}

.integration-subnav-container {
  flex-grow: 1;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 67px;

  > .sized-container {
    margin-right: auto;
  }
}

.project-not-installed {
  position: fixed;
  top: 0px;
  left: $mnl-nav;
  width: calc(100vw - $mnl-nav);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  @include themed() {
    background-color: t('color-bg');
  }

  @include for-mobile-tablet-only {
    width: 100%;
    left: 0px;
  }
}

.button-seperator {
  margin-right: 20px;
  padding-right: 10px;
  height: 36px;
  @include themed() {
    border-right: 1px solid t('border-color') !important;
  }
}

.capitalized {
  text-transform: capitalize;
}

.infoTooltip {
  z-index: 1309990 !important;
  padding: 8px 12px !important;
  margin: 0px !important;
  line-height: 20px;
  pointer-events: auto !important;
  border-radius: $input-border-radius !important;
  @include themed() {
    color: t('color-font-text');
    background-color: t('color-bg');
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
    border: 0px solid t('border-color');
  }

  &::before,
  &::after {
    display: none !important;
  }

  &.show {
    visibility: visible !important;
    opacity: 1 !important;
  }

  a {
    @include themed() {
      color: t('color-primary') !important;
    }
  }
}

.infoTooltipBold {
  z-index: 1309990 !important;
  padding: 8px 12px !important;
  margin: 0px !important;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  pointer-events: auto !important;
  border-radius: $input-border-radius !important;
  @include themed() {
    color: t('color-font-title');
    background-color: t('color-bg');
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
    border: 0px solid t('border-color');
  }

  a {
    @include themed() {
      color: t('color-primary') !important;
    }
  }
}

.infoTooltipButton {
  margin-top: -5px !important;
}

.hide-on-mobile {
  @include for-mobile-tablet-only {
    display: none !important;
  }
}

.hide-on-desktop {
  @include for-desktop-only {
    display: none !important;
  }
}

.dropdown-selection__input-container {
  @include for-mobile-tablet-only {
    font-size: $text-font-size;
  }
}

@media screen and (max-width: 600px) {
  .__react_component_tooltip {
    display: none !important;
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideUpABit {
  from {
    transform: translate3d(0, 1px, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, -1px, 0);
  }
}

@keyframes blendIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}

@keyframes animateInModal {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.code-container {
  > span {
    display: inline-flex !important;
    border-radius: 10px !important;
  }

  code {
    padding: 14px 20px !important;
  }
}

.hotkey-key {
  min-width: 28px;
  min-height: 25px;
  font-size: 11px;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 6px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
  @include themed() {
    color: t('color-font-title');
    border: 1px solid t('border-color');
    border-bottom: 2px solid t('border-color');
  }

  i {
    font-size: 11px;
  }
}

.send-key-tooltip {
  display: flex;
  align-items: center;
  min-height: 26px;

  span {
    font-size: 14px !important;
    @include themed() {
      color: t('color-font-text');
    }
  }

  .hotkey-help {
    display: flex;
    margin-left: 5px;

    > div {
      font-size: 12px;
      padding: 2px 8px;
      border-radius: 6px;
      margin-left: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
      @include themed() {
        color: t('color-font-title');
        border: 1px solid t('border-color');
        border-bottom: 2px solid t('border-color');
      }
    }
  }
}

.bb-feedback-multiplechoice-container {
  display: flex;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  padding-left: 31px;
  font-size: 15px;
  line-height: 21px;
  min-height: 21px;

  .text {
    @include themed() {
      color: t('color-font-title');
    }
  }

  &--disabled {
    .text,
    .bb-feedback-multiplechoice-checkmark {
      opacity: 0.4;
      cursor: default;
    }
  }

  .text .bold {
    font-weight: bold;
  }
}

.bb-feedback-multiplechoice-container:first-of-type {
  margin-top: 8px;
}

.bb-feedback-multiplechoice-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.bb-feedback-multiplechoice-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  box-sizing: border-box;
  @include themed() {
    border: 1px solid t('color-gray');
  }
}

.bb-feedback-multiplechoice-container:hover
  input
  ~ .bb-feedback-multiplechoice-checkmark {
  border: 1px solid #59617d;
  @include themed() {
    border: 1px solid t('color-dark-gray');
  }
}

.bb-feedback-multiplechoice-container
  input:checked
  ~ .bb-feedback-multiplechoice-checkmark {
  @include themed() {
    border: 1px solid t('color-primary-black');
  }
}

.bb-feedback-multiplechoice-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.bb-feedback-multiplechoice-container
  input:checked
  ~ .bb-feedback-multiplechoice-checkmark:after {
  display: block;
}

.bb-feedback-multiplechoice-container
  .bb-feedback-multiplechoice-checkmark:after {
  top: 3px;
  left: 3px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  @include themed() {
    background: t('color-primary-black');
  }
}