@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.shared-news-list {
  max-width: 700px;
  margin: 50px auto;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @include for-mobile-tablet-only {
    margin: 20px auto;
  }

  .news-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background-color: var(--shared-background-color);
    margin-bottom: 30px;

    @include for-mobile-tablet-only {
      flex-direction: column;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
    .news-fake-image-loading {
      width: 100%;
      max-width: 320px;
      flex-shrink: 0;

      @include for-phone-only {
        max-width: 100% !important;
      }
    }

    .news-fake-image-loading > span {
      display: flex;
      width: 100%;

      .react-loading-skeleton {
        @include for-phone-only {
          height: 180px !important;
        }
      }
    }

    &--image {
      background-color: var(--shared-light-gray-color);
      height: 180px;
      object-fit: cover;
      width: 100%;
      max-width: 320px;
      flex-shrink: 0;
      border-radius: 12px;

      @include for-phone-only {
        height: 180px;
        max-width: 100%;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      display: flex;
      padding: 20px;
      padding-left: 30px;
      flex-grow: 1;

      @include for-mobile-tablet-only {
        padding: 20px 0px;
        padding-left: 0px;
        padding-bottom: 10px;
      }
    }

    &--title {
      color: var(--shared-title-color);
      font-size: 18px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin-bottom: 8px;
    }

    &--subtitle {
      color: var(--shared-text-color);
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 1.4;
      font-weight: 400;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &--meta {
      display: flex;
      align-items: center;
      color: var(--shared-text-color);
      font-size: 14px;
      font-weight: 400;
      margin-top: 8px;

      span {
        color: var(--shared-text-color);
        font-size: 14px;
        font-weight: 400;
      }

      img {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 8px;
      }
    }

    &:hover {
      .news-item--title {
        color: var(--shared-color);
      }
    }
  }
}

.news-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 40px;

  .shared-powered-by {
    position: relative;
    bottom: auto;
    left: auto;
  }
}
