@import 'styles/scss-variable.scss';

.update-project-danger-zone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
