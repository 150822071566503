@import 'styles/scss-variable.scss';
@import '../../../styles/utils.scss';

.screenshot-container {
  width: calc(100% - 38px);
  margin-left: 0px;
  display: flex;

  .image-upload-wrapper {
    margin-bottom: -15px;

    .image-upload-component {
      height: 150px;
      max-width: 250px;
    }
  }

  .screenshot-image-container {
    margin-bottom: 5px;
    position: relative;

    .bug-preview-image {
      cursor: zoom-in;
      border-radius: $border-radius;
      height: auto;
      width: auto;
      max-width: 450px;
      max-height: 350px;
      @include themed() {
        border: 1px solid t('border-color');
      }

      @include for-mobile-tablet-only {
        max-width: 240px;
        max-height: 240px;
      }
    }
  }

  .report-screenshot {
    position: absolute;
    opacity: 0.7;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #ff0000;

    &:hover {
      opacity: 1;
    }

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }
}
