@import 'styles/scss-variable.scss';

.event-log-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 100%;
  padding: 0px;

  .log-count {
    margin-left: 0px;
    margin-right: 20px;
    padding-top: 2px;
    min-width: 50px;
    @include themed() {
      color: t('color-font-text');
    }

    img {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }

    div {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .log-data {
    @include themed() {
      color: t('color-font-text');
    }
    width: auto;
    max-width: 90%;
    white-space: nowrap;
    margin-right: 10px;
    padding-top: 1px;
    font-size: $text-font-size;

    .log-url {
      font-size: $text-font-size;
      a {
        @include themed() {
          color: t('color-font-title');
        }
        word-wrap: break-word;
        white-space: initial;
        text-decoration: underline;
        font-size: $text-font-size;
      }
    }

    .log-extended {
      font-size: $small-text-font-size;
      margin-top: 10px;
      padding: 0px 10px;
      border-left: 1px solid #eee;
      line-height: 24px;

      @include themed() {
        border-color: t('color-gray');
        color: t('color-font-text');
      }

      b {
        font-size: $small-text-font-size;
        @include themed() {
          color: t('color-font-text');
        }
      }

      .arrow {
        width: 10px;
        height: 10px;
        object-fit: cover;
        transition: transform 0.2s;
        @include themed() {
          filter: t('image-filter');
        }

        &--down {
          transform: rotate(90deg);
        }
      }

      span {
        cursor: pointer;
        @include themed() {
          font-size: $small-text-font-size;
          color: t('color-font-text');
        }
      }

      .log-data-details {
        @include themed() {
          padding: 8px;
          border-radius: $border-radius;
          background-color: t('color-gray');
        }

        .pretty-json-container {
          max-width: 700px;
          overflow: auto;
        }

        pre {
          margin: 0px;
          font-size: $small-text-font-size;
          max-width: 80%;
        }
      }
    }
  }

  &--warning {
    .log-icon {
      @include themed() {
        background-color: t('color-log-yellow');
      }
    }

    .log-url,
    .log-count .log-status {
      @include themed() {
        color: t('color-yellow');
      }
    }
  }

  &--error {
    .log-icon {
      @include themed() {
        background-color: t('color-log-red');
      }
    }

    .log-url,
    .log-count .log-status {
      @include themed() {
        color: t('color-red');
      }
    }
  }
}
