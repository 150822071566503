@import '../../styles/scss-variable.scss';

.loading {
  @include themed() {
    background: t('color-bg');
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themed() {
    color: t('color-font-text');
  }
}
