@import 'styles/scss-variable.scss';

.setup-project {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .button-container {
    width: 100%;
  }
}
